import api from "../../features";

// Register User
const register = async (userData) => {
  // Make Request, Include Response in Variable
  const response = await api.post("/auth/register", userData);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data.user));
    localStorage.setItem("accessToken", response.data.tokens.access.token);
    localStorage.setItem(
      "accessTokenExpires",
      response.data.tokens.access.expires
    );
    localStorage.setItem("refreshToken", response.data.tokens.refresh.token);
    localStorage.setItem(
      "refreshTokenExpires",
      response.data.tokens.refresh.expires
    );

    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.tokens.access.token}`;
  }

  return response.data;
};

// Login User
const login = async (userData) => {
  // Make Request, Include Response in Variable
  const response = await api.post("/auth/login", userData);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data.user));
    localStorage.setItem("accessToken", response.data.tokens.access.token);
    localStorage.setItem(
      "accessTokenExpires",
      response.data.tokens.access.expires
    );
    localStorage.setItem("refreshToken", response.data.tokens.refresh.token);
    localStorage.setItem(
      "refreshTokenExpires",
      response.data.tokens.refresh.expires
    );

    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.tokens.access.token}`;
  }

  return response.data;
};

// Get User
const getUser = async (userId) => {
  // Get Token from Header
  const response = await api.get(`/users/${userId}`);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  // Return Data
  return response.data;
};

// Logout User
const logout = async () => {
  const refreshToken = localStorage.getItem("refreshToken");

  const response = await api.post("/auth/logout", {
    refreshToken: refreshToken,
  });

  console.log(response);

  if (response.status === 204) {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("accessTokenExpires");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("refreshTokenExpires");
  }

  return response.data;
};

// Logout User
const authenticateMagicLink = async (token) => {
  const response = await api.post(
    `/stytch/auth/magic-link/authenticate/${token}`
  );

  console.log(response);

  return response;
};

const authService = {
  register,
  logout,
  login,
  getUser,
  authenticateMagicLink,
};

export default authService;
