// Slices for Initial State, Actions/Functions
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import invoiceService from "./invoiceService";
import { toast } from "react-toastify";

import { addInvoice } from "../project/projectSlice";

// Create Initial State for Invoice
const initialState = {
  invoices: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Unit Health Check
export const invoiceHealthCheck = createAsyncThunk(
  "invoices/health-check",
  async (_, thunkAPI) => {
    try {
      return await invoiceService.invoiceHealthCheck();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getInvoices = createAsyncThunk(
  "invoices/get",
  async (_, thunkAPI) => {
    try {
      return await invoiceService.getInvoices();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createInvoice = createAsyncThunk(
  "invoices/create",
  async (invoice, thunkAPI) => {
    try {
      return await invoiceService.createInvoice(invoice);
      // thunkAPI.dispatch(addInvoice(invoice.projectId, response));
      // dispatch(addInvoice(invoice.projectId, response.data));
      // return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateInvoice = createAsyncThunk(
  "invoices/update",
  async (invoice, thunkAPI) => {
    try {
      return await invoiceService.updateInvoice(invoice);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  "invoices/delete",
  async (id, thunkAPI) => {
    try {
      return await invoiceService.deleteInvoice(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Slice is collection of Reducers for Redux
export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    // Ability to dispatch Reset function after Register or Login
    reset: (state) => {
      state.invoices = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Health Check
      .addCase(invoiceHealthCheck.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(invoiceHealthCheck.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.message = "Unit Health Check Success";
      })
      .addCase(invoiceHealthCheck.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Get Invoices
      .addCase(getInvoices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoices = action.payload.data;
      })
      .addCase(getInvoices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Create Invoice
      .addCase(createInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoices.push(action.payload);
      })
      .addCase(createInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Update Invoice
      .addCase(updateInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoices[
          state.invoices.findIndex(
            (invoice) => invoice.id === action.payload.id
          )
        ] = action.payload;
        toast.success("Invoice updated successfully", {
          position: "bottom-right",
        });
      })
      .addCase(updateInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Delete Invoice
      .addCase(deleteInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(deleteInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = invoiceSlice.actions;
export default invoiceSlice.reducer;
