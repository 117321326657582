const GetAccountName = (accounts, accountId) => {
  const account = accounts.find((account) => account.id === accountId);

  const accountType = account.type === "depositAccount" ? "Checking" : "Credit";

  const accountNumber = account.attributes.accountNumber.slice(-4);

  return `${accountType}•${accountNumber}`;
};

export default GetAccountName;
