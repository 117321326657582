import React from "react";
import { Link, Navigate } from "react-router-dom";

import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import { useAuth } from "../hooks/useAuth";
import { AuthLayout } from "../components";

import { useStytchB2BClient } from "@stytch/react/b2b";

import logo from "../assets/logoBlue.png";

const Login = () => {
  const stytch = useStytchB2BClient();

  const { user, loginUser } = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();

    const { email, password } = event.target.elements;
    const request = { email: email.value, password: password.value };

    loginUser(request);
  };

  // if (user) {
  //   return <Navigate to="/" />;
  // }

  const sendEmailMagicLink = async () => {
    console.log("send email magic link");

    const request = {
      email: "eli@hellobundle.com",
    };

    try {
      await stytch.magicLinks.email.loginOrSignup({
        email: request.email,
        organization_id:
          "organization-test-07971b06-ac8b-4cdb-9c15-63b17e653931",
      });
    } catch (error) {
      console.log("error sending magic link", error);
      const errorString = JSON.stringify(error);
    }

    // stytch.magicLinks.email.loginOrSignup({
    //   email: "eli@hellobundle.com",
    //   organization_id: "organization-test-07971b06-ac8b-4cdb-9c15-63b17e653931",
    // });
  };

  // const sendEmailMagicLink = () => {
  //   stytch.magicLinks.email.loginOrSignup({
  //     email: "eli@hellobundle.com",
  //     organization_id: "organization-test-07971b06-ac8b-4cdb-9c15-63b17e653931",
  //   });
  // };

  return (
    <>
      <AuthLayout>
        <div className="flex flex-col">
          <Link to="/" aria-label="Home">
            <img src={logo} className="h-10 w-auto" alt="bundle logo" />
          </Link>
          <div className="mt-20">
            <h2 className="text-lg font-semibold text-gray-900">
              Sign in to your account
            </h2>
            <p className="mt-2 text-sm text-gray-700">
              Don’t have an account?{" "}
              <Link
                to="/register"
                className="font-medium text-blue-600 hover:underline"
              >
                Sign up
              </Link>{" "}
              for a free trial.
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="mt-10 grid grid-cols-1">
          <div className="mb-4 flex flex-col gap-y-3">
            <Label htmlFor="email">Your email</Label>
            <TextInput
              label="Email address"
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
            />
          </div>
          <div className="mb-4 flex flex-col gap-y-3">
            <Label htmlFor="password">Your password</Label>
            <TextInput
              label="Password"
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
            />
          </div>
          <div className="mb-6 flex items-center justify-between">
            <div className="flex items-center gap-x-3">
              <Checkbox id="rememberMe" name="rememberMe" />
              <Label htmlFor="rememberMe">Remember me</Label>
            </div>
            <Link
              to="#"
              className="w-1/2 text-right text-sm text-primary-600 dark:text-primary-300"
            >
              Forgot Password?
            </Link>
          </div>
          <div>
            <Button type="submit" className="w-full lg:w-auto">
              <span>
                Sign in <span aria-hidden="true">&rarr;</span>
              </span>
            </Button>
          </div>
        </form>
        <button onClick={sendEmailMagicLink}>Send email</button>
      </AuthLayout>
    </>
  );
};

export default Login;
