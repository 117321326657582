import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authenticateMagicLink } from "../features/auth/authSlice";
import Cookies from "universal-cookie";

const Authenticate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [searchParams, setSearchParams] = useSearchParams();

  const slug = searchParams.get("slug");
  const stytch_token_type = searchParams.get("stytch_token_type");
  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      // Authenticate Token
      dispatch(authenticateMagicLink(token)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          // console.log(res.payload);
          cookies.set("session_jwt", res.payload.data.session_jwt);
          cookies.set("session_token", res.payload.data.session_token);
          navigate("/", { replace: true });
        }
      });
    }
  }, [token]);

  return (
    <>
      <div>Authenticate</div>
      <div>slug: {slug}</div>
      <div>stytch_token_type: {stytch_token_type}</div>
      <div>token: {token}</div>
    </>
  );
};

export default Authenticate;
