import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomTable,
  PageHeader,
  RowActions,
  SimpleTable,
  TextLink,
} from "../components";

import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Label,
  Modal,
  TextInput,
  useTheme,
} from "flowbite-react";
import {
  ArrowPathIcon,
  BuildingLibraryIcon,
  BuildingOfficeIcon,
  CheckIcon,
  ChevronRightIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import {
  getUnitBalanceHistory,
  getUnitTransactions,
} from "../features/unit/unitSlice";
import { createColumnHelper } from "@tanstack/react-table";
import FormatCurrency from "../utils/FormatCurrency";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const FinanceDashboard = () => {
  const dispatch = useDispatch();

  const { customer, accounts } = useSelector((state) => state.unit);

  return (
    <>
      <div className="mx-auto max-w-8xl px-4 py-8 sm:px-6 lg:px-12">
        <Breadcrumbs />
        <PageHeader
          title={"Bundle Cash Accounts"}
          primaryButton={{ text: "Add Money" }}
          secondaryButton={{ text: "Send Money" }}
          // actionComponent={<TransferFunds />}
        />
        <div className="mt-4 rounded-lg bg-white p-4 shadow dark:bg-gray-800 ">
          <BalanceChart />
        </div>

        <div className="mt-8 grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
          {/* Account Balance Snapshots */}
          <div className="space-y-2 lg:col-span-3 lg:col-start-1">
            <AccountSnapshot accounts={accounts} />
          </div>
        </div>
        <div>
          <Transactions />
        </div>

        <div>
          <RecentPayments />
        </div>
      </div>
    </>
  );
};

const BalanceChart = () => {
  const dispatch = useDispatch();

  const { mode } = useTheme();
  const isDarkTheme = mode === "dark";

  const { customer } = useSelector((state) => state.unit);

  useEffect(() => {
    dispatch(getUnitBalanceHistory(customer.id));
  }, [dispatch, customer]);

  const { balanceHistory } = useSelector((state) => state.unit);

  const borderColor = isDarkTheme ? "#374151" : "#F3F4F6";
  const labelColor = isDarkTheme ? "#93ACAF" : "#6B7280";
  const opacityFrom = isDarkTheme ? 0 : 0.45;
  const opacityTo = isDarkTheme ? 0.15 : 0;

  const formatDate = (date) => {
    const d = new Date(date);

    const options = {
      month: "short",
      day: "numeric",
      // year: "numeric",
    };

    return d.toLocaleDateString("en-US", options);
  };

  const options = {
    stroke: {
      curve: "smooth",
    },
    chart: {
      type: "area",
      fontFamily: "Inter, sans-serif",
      foreColor: labelColor,
      toolbar: {
        show: false,
      },
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shadeIntensity: 1,
    //     opacityFrom: 0.7,
    //     opacityTo: 0.9,
    //     stops: [0, 90, 100],
    //   },
    // },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     opacityFrom,
    //     opacityTo,
    //     type: "vertical",
    //   },
    // },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
    },
    grid: {
      show: true,
      borderColor: borderColor,
      strokeDashArray: 1,
      // padding: {
      //   left: 35,
      //   bottom: 15,
      // },
    },
    markers: {
      // size: 5,
      strokeColors: "#ffffff",
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    xaxis: {
      // categories: [
      //   "01 Feb",
      //   "02 Feb",
      //   "03 Feb",
      //   "04 Feb",
      //   "05 Feb",
      //   "06 Feb",
      //   "07 Feb",
      // ],
      labels: {
        style: {
          colors: [labelColor],
          fontSize: "14px",
          fontWeight: 500,
        },
        formatter: function (value) {
          return formatDate(value);
        },
      },
      axisBorder: {
        color: borderColor,
      },
      axisTicks: {
        color: borderColor,
      },
      crosshairs: {
        show: true,
        position: "back",
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 10,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: [labelColor],
          fontSize: "14px",
          fontWeight: 500,
        },
        formatter: function (value) {
          return FormatCurrency(value);
        },
      },
    },
    legend: {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Inter, sans-serif",
      labels: {
        colors: [labelColor],
      },
      itemMargin: {
        horizontal: 10,
      },
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          xaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
  };

  const chartData = balanceHistory.reduce((result, item) => {
    const accountId = item.relationships.account.data.id;
    const date = item.attributes.date;
    if (!result[accountId]) {
      result[accountId] = {};
    }
    if (!result[accountId][date]) {
      result[accountId][date] = {
        // balance: item.attributes.balance,
        available: item.attributes.available / 100,
        // hold: item.attributes.hold,
      };
    } else {
      // result[accountId][date].balance += item.attributes.balance;
      result[accountId][date].available += item.attributes.available / 100;
      // result[accountId][date].hold += item.attributes.hold;
    }
    return result;
  }, {});
  // console.log(chartData);

  const seriesData = Object.entries(chartData).map(([accountId, data]) => {
    return {
      name: accountId,
      data: Object.entries(data)
        .map(([date, values]) => {
          return {
            x: date,
            y: values.available,
          };
        })
        .sort((a, b) => {
          return new Date(a.x) - new Date(b.x);
        }),
      color: "#1A56DB",
    };
  });

  const series = { series: seriesData };

  const checking = series.series.filter((item) => item.name === "1088411");

  return (
    <>
      <Chart height={280} options={options} series={checking} type="line" />
    </>
  );
};

const AccountSnapshot = ({ accounts }) => {
  const checkingAccount = accounts.find(
    (account) =>
      account.type === "depositAccount" &&
      account.attributes.tags.purpose === "checking"
  );

  const savingsAccount = accounts.find(
    (account) =>
      account.type === "depositAccount" &&
      account.attributes.tags.purpose === "savings"
  );

  const creditAccount = accounts.find(
    (account) => account.type === "creditAccount"
  );
  return (
    <>
      {/* <h3 className="text-lg font-normal leading-6 text-gray-900">
        Your Accounts
      </h3> */}
      <div className="pb-6 sm:pb-8">
        <dl className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {checkingAccount && (
            <AccountTile
              id={checkingAccount.id}
              name={"Checking Account"}
              balance={checkingAccount.attributes.available}
              Icon={BuildingLibraryIcon}
              description={"Available Balance"}
            />
          )}

          {savingsAccount && (
            <AccountTile
              id={savingsAccount.id}
              name={"Savings Account"}
              balance={savingsAccount.attributes.available}
              Icon={BuildingOfficeIcon}
              description={"Available Balance"}
            />
          )}

          {creditAccount && (
            <AccountTile
              id={creditAccount.id}
              name={"Charge Cards"}
              balance={creditAccount.attributes.balance}
              Icon={ArrowPathIcon}
              description={"Outstanding Balance"}
            />
          )}
        </dl>
      </div>
    </>
  );
};

const AccountTile = ({ id, Icon, name, balance, description }) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
  });

  return (
    <>
      <div
        key={id}
        className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-5 shadow sm:px-6 sm:pt-6"
      >
        <div className="absolute rounded-md bg-blue-50 p-3 mt-4">
          <Icon className="h-6 w-6 text-neutral-900" aria-hidden="true" />
        </div>
        <dt className="ml-16 flex items-baseline">
          <p className="truncate text-base font-normal text-gray-500">{name}</p>
        </dt>
        <dd className="ml-16 flex items-baseline">
          <p className="text-3xl font-medium text-gray-900">
            ${formatter.format(balance / 100)}
          </p>
        </dd>
        <dt className="ml-16 flex items-baseline">
          <p className="flex items-baseline text-base font-normal text-gray-500">
            {description}
          </p>
        </dt>
      </div>
    </>
  );
};

const Transactions = () => {
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();

  const organization = useSelector((state) => state.organization.organization);
  const { customer } = useSelector((state) => state.unit);

  const formatDate = (date) => {
    const d = new Date(date);

    const options = {
      month: "short",
      day: "numeric",
      // year: "numeric",
    };

    return d.toLocaleDateString("en-US", options);
  };

  const getUserName = (userId) => {
    const user = organization.platformUsers.find((user) => user.id === userId);
    return user ? user.name : "";
  };

  useEffect(() => {
    dispatch(
      getUnitTransactions({
        "filter[customerId]": customer.id,
      })
    );
  }, []);

  const { transactions } = useSelector((state) => state.unit);

  const recentTransactions = transactions
    .filter((item) => {
      const date = new Date(item.attributes.createdAt);
      const now = new Date();
      const diff = now - date;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      return (
        days < 45 &&
        (item.type === "cardTransaction" || item.type === "purchaseTransaction")
      );
    })
    .sort(
      (a, b) =>
        new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
    )
    .slice(0, 5);

  const recentPayments = transactions
    .filter((item) => {
      const date = new Date(item.attributes.createdAt);
      const now = new Date();
      const diff = now - date;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      return (
        days < 45 &&
        (item.type === "originatedAchTransaction" ||
          item.type === "receivedAchTransaction")
      );
    })
    .sort(
      (a, b) =>
        new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
    )
    .slice(0, 5);

  function currencyFormatter(value) {
    return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("attributes.createdAt", {
        header: "Date",
        cell: (props) => <span>{formatDate(props.getValue())}</span>,
        maxSize: 28,
      }),
      columnHelper.accessor(
        (row) =>
          `${
            row.attributes.merchant?.name || row.attributes.counterparty?.name
          }`,
        {
          id: "attributes.merchant.name",
          header: () => "Merchant",
          cell: (info) => info.getValue(),
        }
      ),
      columnHelper.accessor((row) => `${row.attributes.tags?.userId || null}`, {
        id: "attributes.tags",
        header: () => "User",
        cell: (props) => <span>{getUserName(props.getValue())}</span>,
      }),
      columnHelper.accessor(
        (row) => `${row.attributes.merchant?.category || " "}`,
        {
          id: "attributes.merchant.category",
          header: () => "Category",
          cell: (props) => (
            // <div className="text-ellipsis overflow-hidden whitespace-nowrap">
            //   <span>{props.getValue()}</span>
            // </div>
            <>
              {/* {console.log(props.row.original)} */}
              <div className="whitespace-normal">
                <span>{props.getValue()}</span>
              </div>
            </>
          ),
        }
      ),
      columnHelper.accessor("attributes.amount", {
        header: "Amount",
        cell: (props) => (
          <span>{currencyFormatter(props.getValue() / 100)}</span>
        ),
        maxSize: 72,
      }),
    ],
    []
  );

  return (
    <>
      <div className="pb-4 sm:pb-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Recent Transactions
            </h3>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            <TextLink to={`../transactions`} text={"View all"} />
          </div>
        </div>

        {/* Transactions List */}
        <SimpleTable
          columns={columns}
          data={recentTransactions}
          showSearch={false}
          showPagination={false}
        />
      </div>
    </>
  );
};

const RecentPayments = () => {
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();

  const organization = useSelector((state) => state.organization.organization);
  const { customer } = useSelector((state) => state.unit);

  const formatDate = (date) => {
    const d = new Date(date);

    const options = {
      month: "short",
      day: "numeric",
      // year: "numeric",
    };

    return d.toLocaleDateString("en-US", options);
  };

  const getUserName = (userId) => {
    const user = organization.platformUsers.find((user) => user.id === userId);
    return user ? user.name : "";
  };

  useEffect(() => {
    dispatch(
      getUnitTransactions({
        "filter[customerId]": customer.id,
      })
    );
  }, []);

  const { transactions } = useSelector((state) => state.unit);

  const recentTransactions = transactions
    .filter((item) => {
      const date = new Date(item.attributes.createdAt);
      const now = new Date();
      const diff = now - date;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      return (
        days < 45 &&
        (item.type === "cardTransaction" || item.type === "purchaseTransaction")
      );
    })
    .sort(
      (a, b) =>
        new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
    )
    .slice(0, 5);

  const recentPayments = transactions
    .filter((item) => {
      const date = new Date(item.attributes.createdAt);
      const now = new Date();
      const diff = now - date;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      return (
        days < 45 &&
        (item.type === "originatedAchTransaction" ||
          item.type === "receivedAchTransaction")
      );
    })
    .sort(
      (a, b) =>
        new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
    )
    .slice(0, 5);

  function currencyFormatter(value) {
    return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("attributes.createdAt", {
        header: "Date",
        cell: (props) => <span>{formatDate(props.getValue())}</span>,
        maxSize: 28,
      }),
      columnHelper.accessor(
        (row) =>
          `${row.attributes.companyName || row.attributes.counterparty?.name}`,
        {
          id: "attributes.merchant.name",
          header: () => "Counterparty",
          cell: (info) => info.getValue(),
        }
      ),
      columnHelper.accessor((row) => `${row.attributes.tags?.userId || null}`, {
        id: "attributes.tags",
        header: () => "User",
        cell: (props) => <span>{getUserName(props.getValue())}</span>,
      }),
      columnHelper.accessor((row) => `${row.attributes.description || " "}`, {
        id: "attributes.description",
        header: () => "Description",
        cell: (props) => (
          // <div className="text-ellipsis overflow-hidden whitespace-nowrap">
          //   <span>{props.getValue()}</span>
          // </div>
          <>
            {/* {console.log(props.row.original)} */}
            <div className="whitespace-normal">
              <span>{props.getValue()}</span>
            </div>
          </>
        ),
      }),
      columnHelper.accessor("attributes.amount", {
        header: "Amount",
        cell: (props) => (
          <span>{currencyFormatter(props.getValue() / 100)}</span>
        ),
        maxSize: 72,
      }),
    ],
    []
  );

  return (
    <>
      <div className="pb-4 sm:pb-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Recent Payments
            </h3>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            <TextLink to={`../payments`} text={"View all"} />
          </div>
        </div>

        {/* Transactions List */}
        <SimpleTable
          columns={columns}
          data={recentPayments}
          showSearch={false}
          showPagination={false}
        />
      </div>
    </>
  );
};

const TransferFunds = () => {
  const [isOpen, setIsOpen] = useState(false);

  const steps = [
    { name: "Step 1", href: "#", status: "complete" },
    { name: "Step 2", href: "#", status: "complete" },
    { name: "Step 3", href: "#", status: "current" },
  ];

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Transfer</Button>
      <Modal
        size="lg"
        popup={true}
        onClose={() => setIsOpen(false)}
        show={isOpen}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <nav aria-label="Progress">
              <ol role="list" className="flex items-center">
                {steps.map((step, stepIdx) => (
                  <li
                    key={step.name}
                    className={classNames(
                      stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : "",
                      "relative"
                    )}
                  >
                    {step.status === "complete" ? (
                      <>
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="h-0.5 w-full bg-indigo-600" />
                        </div>
                        <a
                          href="#"
                          className="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900"
                        >
                          <CheckIcon
                            className="h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                          <span className="sr-only">{step.name}</span>
                        </a>
                      </>
                    ) : step.status === "current" ? (
                      <>
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="h-0.5 w-full bg-gray-200" />
                        </div>
                        <a
                          href="#"
                          className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
                          aria-current="step"
                        >
                          <span
                            className="h-2.5 w-2.5 rounded-full bg-indigo-600"
                            aria-hidden="true"
                          />
                          <span className="sr-only">{step.name}</span>
                        </a>
                      </>
                    ) : (
                      <>
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="h-0.5 w-full bg-gray-200" />
                        </div>
                        <a
                          href="#"
                          className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                        >
                          <span
                            className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                            aria-hidden="true"
                          />
                          <span className="sr-only">{step.name}</span>
                        </a>
                      </>
                    )}
                  </li>
                ))}
              </ol>
            </nav>
          </div>

          <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              Transfer From
            </h3>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email" value="Your email" />
              </div>
              <TextInput
                id="email"
                placeholder="name@company.com"
                required={true}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="password" value="Your password" />
              </div>
              <TextInput id="password" type="password" required={true} />
            </div>
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <Checkbox id="remember" />
                <Label htmlFor="remember">Remember me</Label>
              </div>
              <a
                href="/modal"
                className="text-sm text-blue-700 hover:underline dark:text-blue-500"
              >
                Lost Password?
              </a>
            </div>
            <div className="w-full">
              <Button>Log in to your account</Button>
            </div>
            <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
              Not registered?{" "}
              <a
                href="/modal"
                className="text-blue-700 hover:underline dark:text-blue-500"
              >
                Create account
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const balanceData = [
  {
    type: "accountEndOfDay",
    id: "275513251",
    attributes: {
      date: "2023-01-22",
      balance: 1497977,
      available: 1387977,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "275430352",
    attributes: {
      date: "2023-01-22",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "274387352",
    attributes: {
      date: "2023-01-21",
      balance: 1497977,
      available: 1287977,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "274304522",
    attributes: {
      date: "2023-01-21",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "273179314",
    attributes: {
      date: "2023-01-20",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "273262126",
    attributes: {
      date: "2023-01-20",
      balance: 1497977,
      available: 1097977,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "272137761",
    attributes: {
      date: "2023-01-19",
      balance: 1497977,
      available: 1497977,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "272055158",
    attributes: {
      date: "2023-01-19",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "270934073",
    attributes: {
      date: "2023-01-18",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "271016422",
    attributes: {
      date: "2023-01-18",
      balance: 1497977,
      available: 977977,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "269898398",
    attributes: {
      date: "2023-01-17",
      balance: 1497977,
      available: 1497977,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "269816372",
    attributes: {
      date: "2023-01-17",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "268784402",
    attributes: {
      date: "2023-01-16",
      balance: 1497977,
      available: 1988400,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "268702617",
    attributes: {
      date: "2023-01-16",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "267591845",
    attributes: {
      date: "2023-01-15",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "267673489",
    attributes: {
      date: "2023-01-15",
      balance: 1497977,
      available: 2228400,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "266564384",
    attributes: {
      date: "2023-01-14",
      balance: 1497977,
      available: 2218400,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "266482847",
    attributes: {
      date: "2023-01-14",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "265456491",
    attributes: {
      date: "2023-01-13",
      balance: 1497977,
      available: 2348400,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "265374984",
    attributes: {
      date: "2023-01-13",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "264349921",
    attributes: {
      date: "2023-01-12",
      balance: 1497977,
      available: 2918400,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "264268729",
    attributes: {
      date: "2023-01-12",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "263247831",
    attributes: {
      date: "2023-01-11",
      balance: 1497977,
      available: 2918400,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "263166964",
    attributes: {
      date: "2023-01-11",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "262149956",
    attributes: {
      date: "2023-01-10",
      balance: 1497977,
      available: 3288400,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "262069327",
    attributes: {
      date: "2023-01-10",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "260975752",
    attributes: {
      date: "2023-01-09",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "261055967",
    attributes: {
      date: "2023-01-09",
      balance: 1497977,
      available: 3866800,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "259967680",
    attributes: {
      date: "2023-01-08",
      balance: 1497977,
      available: 250000,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "259887812",
    attributes: {
      date: "2023-01-08",
      balance: 0,
      available: 0,
      hold: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088412",
        },
      },
    },
  },
  {
    type: "accountEndOfDay",
    id: "258883516",
    attributes: {
      date: "2023-01-07",
      balance: 1497977,
      available: 250000,
      hold: 0,
      overdraftLimit: 0,
    },
    relationships: {
      customer: {
        data: {
          type: "customer",
          id: "784809",
        },
      },
      account: {
        data: {
          type: "account",
          id: "1088411",
        },
      },
    },
  },
];

const Breadcrumbs = () => {
  return (
    <>
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item href="/">
          <div className="flex items-center gap-x-3">
            <HomeIcon className="h-5 w-5" />
            <span className="dark:text-white">Home</span>
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Cash Accounts</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default FinanceDashboard;
