import React, {
  useState,
  createContext,
  useContext,
  useMemo,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { login, logout } from "../features/auth/authSlice";
import { getOrganization } from "../features/organization/organizationSlice";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [user, setUser] = useState(cookies.get("user") || null);
  // const [user, setUser] = useState(
  //   JSON.parse(localStorage.getItem("user")) || null
  // );

  useEffect(() => {
    const cookieUser = cookies.get("user");
    const localUser = JSON.parse(localStorage.getItem("user"));

    if (cookieUser && !localUser) {
      setUser(cookieUser);
      localStorage.setItem("user", JSON.stringify(cookieUser));
    }

    if (localUser && !cookieUser) {
      setUser(localUser);
      cookies.set("user", localUser);
    }

    const cookieAccessToken = cookies.get("accessToken");
    const cookieRefreshToken = cookies.get("refreshToken");

    const localAccessToken = localStorage.getItem("accessToken");
    const localRefreshToken = localStorage.getItem("refreshToken");

    if (cookieAccessToken && !localAccessToken) {
      localStorage.setItem("accessToken", cookieAccessToken);
    }

    if (cookieRefreshToken && !localRefreshToken) {
      localStorage.setItem("refreshToken", cookieRefreshToken);
    }

    if (localAccessToken && !cookieAccessToken) {
      cookies.set("accessToken", localAccessToken);
    }

    if (localRefreshToken && !cookieRefreshToken) {
      cookies.set("refreshToken", localRefreshToken);
    }

    // const localStorageUser = JSON.parse(localStorage.getItem("user"));
    // setUser(localStorageUser);
  }, [setUser]);

  const loginUser = async (data) => {
    dispatch(login(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        console.log(res);
        setUser(res.payload.user);
        dispatch(getOrganization(res.payload.user.organization)).then(() => {
          navigate("/", { replace: true });
        });
      }
    });
  };

  const logoutUser = () => {
    dispatch(logout()).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        <Navigate to="/login" replace={true} />;
      }
    });
  };

  const value = useMemo(
    () => ({
      user,
      loginUser,
      logoutUser,
    }),
    [user]
  );

  return (
    <>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const RequireAuth = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    return (
      <Navigate
        to={{ pathname: "/login", state: { from: location } }}
        replace
      />
    );
  }

  return children;
};

export function AuthStatus() {
  let auth = useAuth();

  if (!auth.user) {
    toast.error("You are not logged in.");
    return;
  }

  toast.success("You are logged in.");

  return;
}
