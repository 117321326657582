import React from "react";
import clsx from "clsx";
import { Button } from "flowbite-react";

export const PageHeader = ({
  title,
  component,
  primaryButton,
  secondaryButton,
  linkButton,
  actionComponent,
  bottomBorder = true,
}) => {
  return (
    <>
      <div
        className={clsx(
          "md:flex md:items-center md:justify-between pb-3",
          bottomBorder ? "border-b border-neutral-200" : ""
        )}
      >
        <div className="min-w-0 flex-1">
          {title && (
            <h2 className="text-2xl font-semibold leading-7 text-neutral-800 sm:truncate sm:text-3xl sm:tracking-tight">
              {title}
            </h2>
          )}
          {component && component}
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          {linkButton && (
            <button
              type="button"
              className="rounded-md px-4 py-2 font-medium text-indigo-600 hover:text-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {linkButton.text}
            </button>
          )}

          {secondaryButton && (
            <Button
              color={"light"}
              disabled={secondaryButton.disabled}
              onClick={secondaryButton.onClick}
              className="mr-2"
            >
              {secondaryButton.text}
            </Button>
          )}

          {primaryButton && (
            <Button
              disabled={primaryButton.disabled}
              onClick={primaryButton.onClick}
            >
              {primaryButton.text}
            </Button>
          )}
          {actionComponent && actionComponent}
        </div>
      </div>
    </>
  );
};
