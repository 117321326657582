import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganization } from "../features/organization/organizationSlice";
import {
  CustomAvatar,
  FileUpload,
  FullScreenModal,
  ImageGrid,
  PageHeader,
  PlaidLink,
  TransferMoney,
} from "../components";
import {
  getUnitAccounts,
  getUnitBalanceHistory,
  getUnitCards,
  getUnitCounterparties,
  getUnitCustomer,
  getUnitPayments,
  getUnitStatements,
  getUnitTransactions,
} from "../features/unit/unitSlice";
import {
  useStytchMemberSession,
  useStytchB2BClient,
  useStytchMember,
} from "@stytch/react/dist/b2b";
import { getLinkToken } from "../features/plaid/plaidSlice";
import classNames from "classnames";

const Dashboard = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const parseName = (name) => {
    const parts = name.split(" ");
    const firstName = parts[0];
    const lastName = parts[parts.length - 1];
    return { firstName, lastName };
  };

  const { firstName, lastName } = parseName(user.name);

  useEffect(() => {
    dispatch(getOrganization(user.organization)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(getUnitCustomer(res.payload.customerId));
        dispatch(getUnitAccounts(res.payload.customerId));
        dispatch(getUnitCards(res.payload.customerId));
        dispatch(
          getUnitCounterparties({
            "filter[customerId]": res.payload.customerId,
          })
        );
        dispatch(getUnitStatements(res.payload.customerId));
        // dispatch(getUnitBalanceHistory(res.payload.customerId));
        dispatch(
          getUnitTransactions({
            "filter[customerId]": res.payload.customerId,
            // "page[limit]": 10,
          })
        );
        dispatch(
          getUnitPayments({
            "filter[customerId]": res.payload.customerId,
            // "page[limit]": 10,
          })
        );
        dispatch(getLinkToken());
      }
    });
  }, [dispatch, user]);

  const [isFullScreenOpen, setIsFullScreenOpen] = useState(false);

  const openFullScreen = () => {
    setIsFullScreenOpen(true);
  };

  return (
    <>
      <header className="relative isolate">
        <div
          className="absolute inset-0 -z-10 overflow-hidden"
          aria-hidden="true"
        >
          <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
            <div
              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#80d9ff] to-[#9089FC]"
              style={{
                clipPath:
                  "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
              }}
            />
          </div>
          <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
        </div>

        <div className="mx-auto max-w-8xl px-4 py-10 sm:px-6 lg:px-12">
          <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
            <div className="flex items-center gap-x-6">
              {/* <img
                src="https://tailwindui.com/img/logos/48x48/tuple.svg"
                alt=""
                className="h-16 w-16 flex-none rounded-full ring-1 ring-gray-900/10"
              /> */}
              <CustomAvatar
                color="neutral"
                size="xlarge"
                firstName="Ignite"
                lastName="Trivia"
              />
              <h1>
                <div className="text-sm font-medium leading-6 text-gray-500">
                  Welcome back,
                </div>
                <div className="text-xl font-semibold leading-6 text-gray-900">
                  {user.name}
                </div>
                <div className="text-sm font-medium leading-6 text-gray-500">
                  {new Date().toLocaleString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }) + ""}
                </div>
              </h1>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Dashboard;
