import React from "react";

export const CustomCard = ({ headerComponent, children, footerComponent }) => {
  let subComponentList = Object.keys(CustomCard);

  let subComponents = subComponentList.map((key) => {
    return React.Children.map(children, (child) =>
      child.type.name === key ? child : null
    );
  });

  return (
    <>
      <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow">
        {headerComponent && (
          <>
            <div className="px-4 py-5 sm:px-6">{headerComponent}</div>
          </>
        )}
        <div className="px-4 py-5 sm:p-6">{children}</div>
        {footerComponent && (
          <>
            <div className="px-4 py-4 sm:px-6">{footerComponent}</div>
          </>
        )}
      </div>
    </>
  );
};

const Header = ({ children }) => {
  return (
    <>
      <div className="px-4 py-5 sm:px-6">{children}</div>
    </>
  );
};
CustomCard.Header = Header;

const Footer = ({ children }) => {
  return (
    <>
      <div className="px-4 py-5 sm:px-6">{children}</div>
    </>
  );
};
CustomCard.Footer = Footer;
