import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomTable,
  EmptyState,
  PageHeader,
  SimpleTable,
} from "../components";
import { Breadcrumb } from "flowbite-react";
import {
  ArrowDownCircleIcon,
  EyeIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { createColumnHelper } from "@tanstack/react-table";

import {
  getUnitPdfStatement,
  getUnitStatemments,
} from "../features/unit/unitSlice";

import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const Statements = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper();

  const { statements } = useSelector((state) => state.unit);

  // useEffect(() => {
  //   dispatch(getUnitStatemments());
  // }, [dispatch]);

  const viewStatement = (statementId) => {
    console.log("view statement", statementId);
    navigate(`/statements/${statementId}`);
  };

  const downloadPdf = (id) => {
    console.log("download pdf");
    dispatch(getUnitPdfStatement(id)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        console.log(res);
        const pdfBlob = new Blob([res.payload], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);

        const link = document.createElement("a");

        link.href = url;
        link.download = "statement.pdf";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setTimeout(() => URL.revokeObjectURL(url), 100);
      }
    });
  };

  const columns = React.useMemo(
    () => [
      // columnHelper.accessor("id", {
      //   header: () => "ID",
      //   cell: (info) => info.getValue(),
      // }),
      columnHelper.accessor("attributes.period", {
        header: "Statement Period",
        // cell: (props) => {
        //   const period = props.getValue();
        //   const startDate = format(new Date(period), "MMM d, yyyy");
        //   // const endDate = format(new Date(period.end), "MMM d, yyyy");
        //   return `${startDate}`;
        // },
      }),
      columnHelper.accessor("id", {
        header: () => (
          <>
            <span className="sr-only">Actions</span>
          </>
        ),
        cell: (props) => (
          <>
            <div className="flex flex-row">
              <EyeIcon
                // onClick={(event) => handleEditClick(event, props.row.original)}
                className="h-5 w-5 text-neutral-600 hover:text-neutral-900 mr-2"
                onClick={() => viewStatement(`${props.getValue()}`)}
              />
              <ArrowDownCircleIcon
                onClick={() => downloadPdf(`${props.getValue()}`)}
                className="h-5 w-5 text-neutral-600 hover:text-red-600"
              />
            </div>
          </>
        ),
        maxSize: 16,
      }),
    ],
    []
  );

  return (
    <>
      <div className="mx-auto max-w-8xl px-4 py-8 sm:px-6 lg:px-12">
        <Breadcrumbs />
        <header>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold tracking-wide leading-6 text-gray-900">
                Statements
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the users in your account including their name,
                title, email and role.
              </p>
            </div>
          </div>
        </header>

        {statements && statements.length > 0 ? (
          <>
            <SimpleTable columns={columns} data={statements} />
          </>
        ) : (
          <>
            <EmptyState
              title={"No Statements"}
              description={
                "You have not created any cards set up for your Account yet. Click the button below to create your first card! Need to reate a reusable component for custom SVG Icons"
              }
            />
          </>
        )}
      </div>
    </>
  );
};

const Breadcrumbs = () => {
  return (
    <>
      <Breadcrumb className="mb-6">
        <Breadcrumb.Item href="/">
          <div className="flex items-center gap-x-3">
            <HomeIcon className="h-5 w-5" />
            <span className="dark:text-white">Home</span>
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Statements</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default Statements;
