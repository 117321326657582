import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";
import { Badge, Breadcrumb, Button, Label, Modal } from "flowbite-react";
import {
  CustomTable,
  CustomTextInput,
  CustomTextArea,
  CustomListbox,
  PageHeader,
  RowActions,
  EmptyState,
  CustomDatepicker,
} from "../components";
import {
  ArrowUpTrayIcon,
  ChevronRightIcon,
  HomeIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { createTask, getTasks } from "../features/task/taskSlice";
import FormatDate from "../utils/FormatDate";

import Datepicker from "tailwind-datepicker-react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tasks = () => {
  const dispatch = useDispatch();

  const addTask = () => {
    console.log("Add Task");
  };

  useEffect(() => {
    dispatch(getTasks());
  }, []);

  const { tasks } = useSelector((state) => state.task);

  return (
    <>
      <PageHeader
        title={"Tasks"}
        actionComponent={
          <>
            <AddTaskModal />
          </>
        }
        // secondaryButton={{}}
        // linkButton={{}}
      />
      <div className="mt-4">
        {/* Page Contents */}
        {tasks && tasks.length > 0 ? (
          <>
            <div className="overflow-hidden bg-white shadow sm:rounded-md">
              <ul className="divide-y divide-gray-200">
                {tasks.map((task) => (
                  <li key={task.id}>
                    <TaskItem task={task} />
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : (
          <>
            <div className="relative block w-full bg-white rounded-lg border-2 border-gray-300 pb-16 -pt-16">
              <EmptyState
                title={"No Tasks"}
                description={
                  "Invite team members by selecting Invite People, they will receive an email with instructions."
                }
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const TaskItem = ({ task }) => {
  const checkbox = useRef();
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskCompleted, setTaskCompleted] = useState(false);

  const handleCheckboxChange = () => {
    setTaskCompleted(!taskCompleted);
    console.log("checkbox", checkbox.current.checked);
  };

  return (
    <>
      <a href="#" className="block hover:bg-gray-50">
        <div className="flex flex-1 items-center px-4 py-4 sm:px-6">
          <div className="grow sm:items-center grid grid-cols-12 gap-2 ">
            {/* Task Name & Checkbox */}
            <div className="col-span-5">
              <div className="flex flex-row items-center">
                <div className="flex h-5 items-center">
                  <input
                    id="task"
                    aria-describedby="task"
                    name="task"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    ref={checkbox}
                    checked={taskCompleted}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div className="truncate ml-4">
                  <div className="text-sm">
                    <p
                      className={classNames(
                        "truncate font-medium text-neutral-600 ",
                        taskCompleted && "line-through"
                      )}
                    >
                      {task.name}
                    </p>
                    {/* {task.dueDate && (
                    <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                      Due on <time dateTime={task.dueDate}>{task.dueDate}</time>
                    </p>
                  )} */}
                  </div>
                </div>
              </div>
            </div>
            {/* Linked Project */}
            <div className="col-span-2 ">
              <p className="text-sm text-gray-900">{task.project.name}</p>
            </div>
            {/* Linked Client */}
            <div className="col-span-2 ">
              <p className="text-sm text-gray-900">{task.project.name}</p>
            </div>
            {/* Due Date */}
            <div className="col-span-2  text-center">
              <p className="text-sm text-gray-900">
                {task.dueDate ? FormatDate(task.dueDate) : "No due date"}
              </p>
            </div>
            {/* Menu Icon */}
            <div className="col-span-1">
              <EditTaskModal task={task} setSelectedTask={setSelectedTask} />
              {/* <div className="flex ml-5 flex-shrink-0  justify-end">
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div> */}
            </div>
          </div>
        </div>
      </a>
    </>
  );
};

const AddTaskModal = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const { projects } = useSelector((state) => state.project);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      project: "",
      dueDate: "",
    },
  });

  const onSubmit = (data) => {
    console.log(data);

    const request = {
      name: data.name,
      description: data.description,
      projectId: data.project.id,
      dueDate: data.dueDate.toISOString(),
    };

    dispatch(createTask(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setIsOpen(false);
      }
    });
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>
        <div className="flex items-center gap-x-3">
          <PlusIcon className="h-4 w-4" /> Add task
        </div>
      </Button>
      <Modal onClose={() => setIsOpen(false)} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Add new task</strong>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div className="col-span-2">
              <CustomTextInput
                control={control}
                type="text"
                id="name"
                name="name"
                label="Task Name"
                placeholderTx="Meet with client"
              />
            </div>
            <div>
              <CustomListbox
                label={"Project"}
                items={projects}
                attribute={"name"}
                name={"project"}
                setValue={setValue}
              />
            </div>
            <div>
              <CustomDatepicker
                label={"Due Date"}
                control={control}
                name={"dueDate"}
              />
            </div>
            <div className="col-span-2">
              <CustomTextArea
                control={control}
                type="text"
                id="description"
                name="description"
                label="Task Description"
                placeholderTx="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit."
                rows={4}
              />
            </div>
            {/* <CustomSelect
              control={control}
              name="testSelect"
              label="Test Select"
              options={["Option1", "Option2", "Option3"]}
            /> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit(onSubmit)}>Add task</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const EditTaskModal = ({ task, selectedTask, setSelectedTask }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const { projects } = useSelector((state) => state.project);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: task ? task.name : "",
      description: task ? task.description : "",
      project: "",
      dueDate: task ? task?.DueDate : null,
    },
  });

  const onSubmit = (data) => {
    console.log(data);

    const request = {
      name: data.name,
      description: data.description,
      projectId: data.project.id,
      dueDate: data.dueDate.toISOString(),
    };

    dispatch(createTask(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setIsOpen(false);
      }
    });
  };

  return (
    <>
      <button onClick={() => setIsOpen(true)}>
        <div className="flex ml-5 flex-shrink-0  justify-end">
          <ChevronRightIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </button>
      <Modal onClose={() => setIsOpen(false)} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Add new task</strong>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div className="col-span-2">
              <CustomTextInput
                control={control}
                type="text"
                id="name"
                name="name"
                label="Task Name"
                placeholderTx="Meet with client"
              />
            </div>
            <div>
              <CustomListbox
                label={"Project"}
                items={projects}
                attribute={"name"}
                name={"project"}
                setValue={setValue}
              />
            </div>
            <div>
              <CustomDatepicker
                label={"Due Date"}
                control={control}
                name={"dueDate"}
              />
            </div>
            <div className="col-span-2">
              <CustomTextArea
                control={control}
                type="text"
                id="description"
                name="description"
                label="Task Description"
                placeholderTx="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit."
                rows={4}
              />
            </div>
            {/* <CustomSelect
              control={control}
              name="testSelect"
              label="Test Select"
              options={["Option1", "Option2", "Option3"]}
            /> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit(onSubmit)}>Add task</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Tasks;
