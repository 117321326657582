import React from "react";
import { Link } from "react-router-dom";

export const TextLink = ({ to, text, onClick, className, ...props }) => {
  return (
    <>
      <Link
        to={to}
        relative="path"
        onClick={onClick}
        className={`${className} text-blue-600 hover:text-blue-900 focus:outline-none focus:underline transition duration-150 ease-in-out`}
      >
        {text}
      </Link>
    </>
  );
};
