import react, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";

export function isBrowser() {
  return typeof window !== "undefined";
}

export function isSmallScreen() {
  return isBrowser() && window.innerWidth < 768;
}

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const location = isBrowser() ? window.location.pathname : "/";

  const [isOpen, setOpen] = useState(
    isBrowser()
      ? window.localStorage.getItem("isSidebarOpen") === "true"
      : false
  );

  // Save latest state to localStorage
  useEffect(() => {
    window.localStorage.setItem("isSidebarOpen", isOpen.toString());
  }, [isOpen]);

  // Close Sidebar on page change on mobile
  useEffect(() => {
    if (isSmallScreen()) {
      setOpen(false);
    }
  }, [location]);

  // Close Sidebar on mobile tap inside main content
  useEffect(() => {
    function handleMobileTapInsideMain(event) {
      const main = document.querySelector("main");
      const isClickInsideMain = main?.contains(event.target);

      if (isSmallScreen() && isClickInsideMain) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleMobileTapInsideMain);
    return () => {
      document.removeEventListener("mousedown", handleMobileTapInsideMain);
    };
  }, []);

  const value = useMemo(
    () => ({
      isSidebarOpen: isOpen,
      isPageWithSidebar: true,
      setOpenOnSmallScreens: setOpen,
    }),
    [isOpen]
  );

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  return useContext(SidebarContext);
};
