import api from "../../features";

// Get All Clients
const getClients = async () => {
  const response = await api.get("/clients");
  return response.data;
};

// Create Client
const createClient = async (client) => {
  const response = await api.post("/clients", client);
  return response.data;
};

// Update Client
const updateClient = async (client) => {
  const response = await api.patch(`/clients/${client.id}`, client);
  return response.data;
};

// Delete Client
const deleteClient = async (id) => {
  const response = await api.delete(`/clients/${id}`);
  return response.data;
};

const clientService = {
  getClients,
  createClient,
  updateClient,
  deleteClient,
};

export default clientService;
