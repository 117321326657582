import React, { useState, createContext, useContext, useMemo } from "react";
import { firestore } from "../config/firebase";
import { onSnapshot, collection } from "firebase/firestore";

const FirestoreContext = createContext();

export const FirestoreProvider = ({ children }) => {
  const [docs, setDocs] = useState([]);

  const getFirestore = (collectionName) => {
    const collectionRef = collection(firestore, collectionName);
    const unsub = onSnapshot(collectionRef, (snapshot) => {
      let documents = [];
      snapshot.forEach((doc) => {
        documents.push({ ...doc.data(), id: doc.id });
      });
      setDocs(documents);
    });

    return () => unsub();
  };

  const value = useMemo(
    () => ({
      docs,
      getFirestore,
    }),
    [docs]
  );

  return (
    <>
      <FirestoreContext.Provider value={value}>
        {children}
      </FirestoreContext.Provider>
    </>
  );
};

export const useFirestore = () => {
  return useContext(FirestoreContext);
};
