import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DevTool } from "@hookform/devtools";

import { createColumnHelper } from "@tanstack/react-table";
import { Breadcrumb, Button, Modal } from "flowbite-react";
import {
  CustomNumberInput,
  CustomSelect,
  CustomTextArea,
  CustomTextInput,
  DeleteModal,
  EmptyState,
  FormTextInput,
  PageHeader,
  SimpleTable,
  TextLink,
} from "../components";
import {
  HomeIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  createService,
  deleteService,
  getServices,
  updateService,
} from "../features/service/serviceSlice";
import FormatCurrency from "../utils/FormatCurrency";
import StringToNumber from "../utils/StringToNumber";
import GetDirtyValues from "../utils/GetDirtyValues";

const Services = () => {
  // Bring in dispatch and columnHelper Hooks
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();

  // Use the useState hook to manage the initial values for the form
  const [initialValues, setInitialValues] = useState({});

  // Set initial state for Create, Edit, & Delete Modals
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  // Setup state for Serivce
  const [service, setService] = useState({});

  // Setup for storing Service ID
  const [serviceId, setServiceId] = useState("");
  // Setup Edit Form Data
  const [editFormData, setEditFormData] = useState({
    name: "",
    description: "",
    unitAmount: "",
    unitLabel: "",
  });

  // Get the services from the store
  const { services } = useSelector((state) => state.service);

  // Bring in Form
  const {
    handleSubmit,
    reset,
    control,
    register,
    setValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({});

  // Function for handling clicks on Create button
  const handleCreateClick = () => {
    if (service) {
      setInitialValues({});
      setIsCreateOpen(true);
    }
  };

  // Function for handling clicks on Edit button
  const handleEditClick = (event, service) => {
    event.preventDefault();
    if (service) {
      setServiceId(service.id);
      setValue("name", service.name);
      setValue("description", service.description);
      setValue("unitAmount", service.default_price?.unit_amount);
      setValue("unitLabel", service.unit_label);
      setIsEditOpen(true);
    }
  };

  // Function for handling clicks on Edit button
  const handleEditSubmit = (data) => {
    const updatedFields = GetDirtyValues(dirtyFields, data);

    const body = {
      serviceId: serviceId,
      request: {},
    };

    if (updatedFields.name !== undefined) {
      body.request.name = updatedFields.name;
    }

    if (updatedFields.description !== undefined) {
      body.request.description = updatedFields.description;
    }

    if (updatedFields.unitAmount !== undefined) {
      body.request.default_price = {
        unit_amount: StringToNumber(updatedFields.unitAmount),
      };
    }

    if (updatedFields.unitLabel !== undefined) {
      body.request.unit_label = updatedFields.unitLabel;
    }

    dispatch(updateService(body)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        handleEditClose();
      }
    });
  };

  // Function for handling close button on Edit Modal
  const handleEditClose = () => {
    setServiceId("");
    setInitialValues({
      name: "",
      description: "",
      unitAmount: "",
      unitLabel: "",
    });
    reset();
    setIsEditOpen(false);
  };

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  const handleDeleteClick = (serviceId) => {
    setService(services.find((service) => service.id === serviceId));
    setIsDeleteOpen(() => !isDeleteOpen);
  };

  const handleDeleteClose = () => {
    setService({});
    setIsDeleteOpen(() => !isDeleteOpen);
  };

  const handleDeleteSubmit = () => {
    console.log(service);

    dispatch(deleteService(service.id)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        handleDeleteClose();
      }
    });
  };

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => "Service",
        cell: (props) => (
          <span className="text-gray-700">{props.getValue()}</span>
        ),
      }),
      columnHelper.accessor("description", {
        header: "Description",
      }),
      columnHelper.accessor((row) => `${row.default_price}`, {
        id: "default_price",
        header: () => "Price",
        cell: (props) => (
          <span>
            {FormatCurrency(
              props.row.original.default_price?.unit_amount / 100
            ) || "$0.00"}
          </span>
        ),
      }),

      columnHelper.accessor("id", {
        header: () => (
          <>
            <span className="sr-only">Actions</span>
          </>
        ),
        cell: (props) => (
          <>
            <div className="flex flex-row">
              <PencilSquareIcon
                onClick={(event) => handleEditClick(event, props.row.original)}
                className="h-5 w-5 text-neutral-600 hover:text-neutral-900 mr-2"
              />
              <TrashIcon
                onClick={() => handleDeleteClick(`${props.getValue()}`)}
                className="h-5 w-5 text-neutral-600 hover:text-red-600"
              />
            </div>
          </>
        ),
        maxSize: 16,
      }),
    ],
    []
  );

  return (
    <>
      <div className="mx-auto max-w-8xl px-4 py-8 sm:px-6 lg:px-12">
        <Breadcrumbs />
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold tracking-wide leading-6 text-gray-900">
              Services
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your account including their name,
              title, email and role.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <AddServiceModal />
          </div>
        </div>
        {services && services.length > 0 ? (
          <>
            <SimpleTable
              columns={columns}
              data={services}
              showPagination={false}
            />
            {/* <CustomTable columns={columns} data={services} /> */}
          </>
        ) : (
          <>
            <EmptyState
              title={"No Services"}
              description={
                "You have not created any cards set up for your Account yet. Click the button below to create your first card! Need to reate a reusable component for custom SVG Icons"
              }
            />
          </>
        )}
      </div>
      <EditServiceModal
        control={control}
        handleSubmit={handleSubmit}
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        onSubmit={handleEditSubmit}
        onClose={handleEditClose}
      />
      <DeleteModal
        title={"Confirm Delete"}
        description={"Are you sure you want to delete this service?"}
        open={isDeleteOpen}
        setOpen={setIsDeleteOpen}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteSubmit}
      />
    </>
  );
};

const AddServiceModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const useYupValidationResolver = (validationSchema) =>
    useCallback(
      async (data) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );

  const validationSchema = yup.object({
    name: yup.string().required("Required"),
  });

  const resolver = useYupValidationResolver(validationSchema);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: resolver,
    defaultValues: {
      name: "",
      description: "",
      unitAmount: "",
      unitLabel: "",
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    const request = {
      name: data.name,
      description: data.description,
      metadata: {
        organization_id: "63af617207e8611dda047f16",
        account_id: "acct_1MhQhvFLPxDRgaVU",
      },
      default_price_data: {
        currency: "usd",
        unit_amount: StringToNumber(data.unitAmount),
      },
      unit_label: data.unitLabel,
    };

    console.log(request);

    dispatch(createService(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setIsOpen(false);
        reset();
      }
    });
  };

  const onClose = () => {
    setIsOpen(false);
    reset();
  };

  return (
    <>
      <button
        type="button"
        className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        onClick={() => setIsOpen(true)}
      >
        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        Add service
      </button>
      <Modal onClose={onClose} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Add new service</strong>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="col-span-2">
              <CustomTextInput
                control={control}
                type="text"
                id="name"
                name="name"
                label="Service"
                placeholderTx="Labor, Parts, etc."
              />
            </div>
            <div className="col-span-2">
              <CustomTextArea
                control={control}
                type="text"
                id="description"
                name="description"
                label="Description"
                placeholderTx="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit."
                rows={3}
              />
            </div>
            <CustomNumberInput
              control={control}
              name="unitAmount"
              label="Price"
            />
            <CustomSelect
              control={control}
              name="unitLabel"
              label="Type"
              placeholderTx="Select a type"
              options={[
                { value: "Flat", label: "Flat" },
                { value: "Per Item", label: "Per Item" },
                { value: "Per Hour", label: "Per Hour" },
                { value: "Per Day", label: "Per Day" },
                { value: "Per Week", label: "Per Week" },
                { value: "Per Month", label: "Per Month" },
              ]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit(onSubmit)}>Add service</Button>
        </Modal.Footer>
        <DevTool control={control} placement="top-left" />
      </Modal>
    </>
  );
};

const EditServiceModal = ({
  control,
  handleSubmit,
  isOpen,
  onSubmit,
  onClose,
}) => {
  const {} = useForm({
    mode: "onChange",
  });

  return (
    <>
      <Modal onClose={onClose} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Edit Service</strong>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="col-span-2">
              <CustomTextInput
                control={control}
                type="text"
                id="name"
                name="name"
                label="Service"
                placeholderTx="Labor, Parts, etc."
              />
            </div>
            <div className="col-span-2">
              <CustomTextArea
                control={control}
                type="text"
                id="description"
                name="description"
                label="Description"
                placeholderTx="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit."
                rows={3}
              />
            </div>
            <div className="col-span-2">
              <CustomTextInput
                control={control}
                type="text"
                id="unitAmount"
                name="unitAmount"
                label="Price (USD)"
                placeholderTx="$0.00"
              />
            </div>
            <CustomSelect
              control={control}
              name="unitType"
              label="Type"
              options={[
                { value: "flat", label: "Flat" },
                { value: "item", label: "Per Item" },
                { value: "hour", label: "Per Hour" },
                { value: "day", label: "Per Day" },
                { value: "week", label: "Per Week" },
                { value: "month", label: "Per Month" },
              ]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit(onSubmit)}>Add service</Button>
        </Modal.Footer>
        <DevTool control={control} placement="top-left" />
      </Modal>
    </>
  );
};

const Breadcrumbs = () => {
  return (
    <>
      <Breadcrumb className="mb-6">
        <Breadcrumb.Item href="/">
          <div className="flex items-center gap-x-3">
            <HomeIcon className="h-5 w-5" />
            <span className="dark:text-white">Home</span>
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Services</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default Services;
