import React from "react";
import { Button, Table } from "flowbite-react";
import { TextLink } from "..";
import clsx from "clsx";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  DocumentTextIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

export const SimpleTable = ({
  data,
  columns,
  showSearch = true,
  showPagination = true,
  headless = false,
}) => {
  const [sorting, setSorting] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [grouping, setGrouping] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    state: {
      sorting,
      grouping,
      globalFilter,
      columnVisibility,
      rowSelection,
    },
    // defaultColumn,
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  });
  return (
    <>
      <div className={headless ? "mt-2 flow-root" : "mt-8 flow-root"}>
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table
              className={
                headless ? "min-w-full" : "min-w-full divide-y divide-gray-300"
              }
            >
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        scope="col"
                        className="group px-3 py-3 text-left text-sm font-semibold text-gray-900 tracking-wider sm:pl-0"
                        {...{
                          onClick: header.column.getToggleSortingHandler(),
                          colSpan: header.colSpan,
                        }}
                      >
                        <div className="group inline-flex">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          {{
                            asc: (
                              <span className="ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                <ChevronUpIcon
                                  className="ml-2 h-4 w-4 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                  aria-hidden="true"
                                />
                              </span>
                            ),
                            desc: (
                              <span className="ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                <ChevronDownIcon
                                  className="ml-2 h-4 w-4 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                  aria-hidden="true"
                                />
                              </span>
                            ),
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="py-4 sm:pl-6 lg:pl-8 sm:pr-6 lg:pr-8"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500"
                        {...{
                          key: cell.id,
                          style: {
                            width: cell.column.getSize(),
                          },
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>

              <tfoot></tfoot>
            </table>
            {/* Pagination */}
            {showPagination && (
              <>
                <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
                  <div className="-mt-px flex w-0 flex-1">
                    <button
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}
                      className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      <ArrowLongLeftIcon
                        className="mr-3 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      Previous
                    </button>
                  </div>
                  <div className="hidden md:-mt-px md:flex">
                    <a
                      href="#"
                      className="inline-flex items-center border-t-2 border-blue-500 px-4 pt-4 text-sm font-medium text-blue-600"
                      aria-current="page"
                    >
                      1
                    </a>
                    {/* Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" */}
                    <a
                      href="#"
                      className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      2
                    </a>
                    <a
                      href="#"
                      className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      3
                    </a>
                  </div>
                  <div className="-mt-px flex w-0 flex-1 justify-end">
                    <button
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}
                      className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      Next
                      <ArrowLongRightIcon
                        className="ml-3 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </nav>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export function TransactionActions({ row, selectTransaction }) {
  // console.log(row);
  return (
    <>
      <div className="flex flex-row gap-2">
        <DocumentTextIcon
          className={classNames(
            "h-4 w-4 text-gray-400",
            row.attributes.tags?.memo && "text-green-600 "
          )}
          aria-hidden="true"
        />
        <PaperClipIcon
          className={classNames(
            "h-4 w-4 text-gray-400",
            row.attributes.tags?.receiptId && "text-green-600 "
          )}
          aria-hidden="true"
        />
        <button onClick={() => selectTransaction(row)}>
          <ChevronRightIcon
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>
    </>
  );
}
