import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { EmptyState, PageHeader } from "../components";
import { Breadcrumb } from "flowbite-react";
import {
  ChevronRightIcon,
  EllipsisVerticalIcon,
  HomeIcon,
  LockClosedIcon,
  QueueListIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import FormatCurrency from "../utils/FormatCurrency";
import clsx from "clsx";
import miniVirtual from "../assets/images/card-mini-virtual.png";
import miniPhysical from "../assets/images/card-mini-physical.png";
import virtualCard from "../assets/images/placeholder-virtual-card.png";
import physicalCard from "../assets/images/placeholder-physical-card.png";
import { Disclosure, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";

const secondaryNavigation = [
  { name: "View all", href: "#", current: true },
  { name: "Debit cards", href: "#", current: false },
  { name: "Virtual cards", href: "#", current: false },
];

const Cards = () => {
  const dispatch = useDispatch();
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  const allCards = useSelector((state) => state.unit.cards);

  const physicalCards = cards.filter(
    (card) => card.type === "businessDebitCard"
  );

  const virtualCards = cards.filter(
    (card) => card.type === "businessVirtualDebitCard"
  );

  useEffect(() => {
    setCards(allCards);
    setSelectedCard(cards[0]);
  }, []);

  const users = useSelector(
    (state) => state.organization.organization.platformUsers
  );

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const viewAllCards = () => {
    setCards(allCards);
  };

  const viewPhysicalCards = () => {
    setCards(physicalCards);
  };

  const viewVirtualCards = () => {
    setCards(virtualCards);
  };

  const selectCard = (card) => {
    setSelectedCard(card);
  };

  const totalMonthlySpend = (card) => {
    return Object.values(card.limits.attributes.monthlyTotals).reduce(
      (sum, value) => sum + value,
      0
    );
  };

  const { accounts } = useSelector((state) => state.unit);

  const getAccount = (accountId) => {
    const account = accounts.find((account) => account.id === accountId);

    const accountType =
      account.type === "depositAccount" ? "Checking" : "Credit";

    const accountNumber = account.attributes.accountNumber.slice(-4);

    return `${accountType}•${accountNumber}`;
  };

  return (
    <>
      <div className="mx-auto max-w-8xl px-4 py-8 sm:px-6 lg:px-12">
        <Breadcrumbs />
        <header>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold tracking-wide leading-6 text-gray-900">
                Cards
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the users in your account including their name,
                title, email and role.
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Create card
              </button>
            </div>
          </div>
        </header>

        {cards && cards.length > 0 ? (
          <>
            {/* Filters */}
            <div className="pt-8">
              <div className="mx-auto flex flex-wrap items-center gap-6 sm:flex-nowrap ">
                <h1 className="text-base font-medium tracking-wide leading-7 text-gray-900">
                  Your cards
                </h1>
                <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                  {secondaryNavigation.map((item) => (
                    <button
                      key={item.name}
                      href={item.href}
                      className={
                        item.current ? "text-blue-600" : "text-gray-700"
                      }
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            {/* <div className="mb-5">
              <ul className="flex flex-wrap">
                <li className="m-1">
                  <button
                    className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-blue-500 text-white duration-150 ease-in-out"
                    onClick={() => viewAllCards()}
                  >
                    View All
                  </button>
                </li>
                <li className="m-1">
                  <button
                    className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out"
                    onClick={() => viewPhysicalCards()}
                  >
                    Physical Cards
                  </button>
                </li>
                <li className="m-1">
                  <button
                    className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out"
                    onClick={() => viewVirtualCards()}
                  >
                    Virtual Cards
                  </button>
                </li>
              </ul>
            </div> */}
            {/* Card List */}
            <Disclosure>
              <div className="mt-6 py-2 grid grid-cols-1 gap-6 lg:grid-flow-col lg:grid-cols-8 overflow-hidden scrollbar-hide">
                {/* Cards */}
                <div className="lg:col-span-full lg:col-start-1">
                  <div className="overflow-hidden rounded-lg border border-gray-200 bg-white shadow">
                    <div className="px-6 py-3">
                      <div className="flex flex-row">
                        <div className="w-1/4">
                          <p className="text-left text-sm font-semibold text-gray-900 tracking-wider">
                            Cardholder
                          </p>
                        </div>
                        <div className="flex flex-row items-center justify-between w-3/4">
                          <div className="w-2/12">
                            <p className="text-left text-sm font-semibold text-gray-900 tracking-wider">
                              Card
                            </p>
                          </div>
                          <div className="w-3/12">
                            <p className="text-left text-sm font-semibold text-gray-900 tracking-wider">
                              Monthly Spend
                            </p>
                          </div>
                          <div className="w-2/12">
                            <p className="text-left text-sm font-semibold text-gray-900 tracking-wider">
                              Card Type
                            </p>
                          </div>
                          <div className="w-2/12">
                            <p className="text-left text-sm font-semibold text-gray-900 tracking-wider">
                              Account
                            </p>
                          </div>
                          <div className="w-2/12">
                            <p className="text-center text-sm font-semibold text-gray-900 tracking-wider">
                              Status
                            </p>
                          </div>
                          <div className="w-1/12">
                            <p className="sr-only">Actions</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="border-t divide-y divide-gray-200">
                      {cards.map((card) => (
                        <Disclosure.Button
                          as={"li"}
                          key={card.id}
                          className="px-6 py-4 hover:bg-gray-50"
                          onClick={() => selectCard(card)}
                        >
                          <div className="relative flex items-center">
                            <div className="w-1/4">
                              <p className="text-sm font-medium text-gray-900">
                                {card.attributes.fullName.first}{" "}
                                {card.attributes.fullName.last}
                              </p>
                            </div>
                            <div className="flex flex-row items-center w-3/4">
                              <div className="w-2/12">
                                <div className="flex flex-row items-center">
                                  <img
                                    className="h-6 w-auto"
                                    src={
                                      card.type === "businessDebitCard"
                                        ? miniPhysical
                                        : miniVirtual
                                    }
                                    alt=""
                                  />
                                  <p className="truncate text-sm text-gray-500 ml-1">
                                    • {card.attributes.last4Digits}
                                  </p>
                                </div>
                              </div>
                              <div className="w-3/12">
                                <p className="truncate text-sm text-gray-500">
                                  {FormatCurrency(
                                    totalMonthlySpend(card) / 100
                                  )}{" "}
                                  /{" "}
                                  {FormatCurrency(
                                    card.limits.attributes.limits
                                      .monthlyPurchase / 100
                                  )}
                                </p>
                              </div>
                              <div className="w-2/12">
                                <p className="truncate text-sm text-gray-500">
                                  {card.type === "businessDebitCard"
                                    ? "Physical"
                                    : "Virtual"}
                                </p>
                              </div>
                              <div className="w-2/12">
                                <p className="truncate text-sm text-gray-500">
                                  {getAccount(
                                    card.relationships.account.data.id
                                  )}
                                </p>
                              </div>
                              <div className="w-2/12">
                                <p className="truncate text-sm text-gray-500 text-center">
                                  <StatusPill value={card.attributes.status} />
                                </p>
                              </div>

                              <div className="w-1/12">
                                <div className="flex ml-5 flex-shrink-0  justify-end">
                                  <ChevronRightIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Disclosure.Button>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* Card Detail */}
                <Transition
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Disclosure.Panel>
                    {({ close }) => (
                      <>
                        {selectedCard && (
                          <>
                            <div className="max-w-sm">
                              <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                                <div className="px-4 py-5 sm:px-6">
                                  <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                                    <div className="ml-4 mt-2">
                                      <h3 className="text-base font-medium leading-6 text-gray-900">
                                        {selectedCard.attributes.fullName.first}
                                        's{" "}
                                        {selectedCard.type ===
                                        "businessDebitCard"
                                          ? "Debit"
                                          : "Virtual"}{" "}
                                        Card
                                      </h3>
                                      <p className="text-sm text-gray-700">
                                        {selectedCard.attributes.fullName.first}{" "}
                                        {selectedCard.attributes.fullName.last}
                                      </p>
                                    </div>
                                    <div className="ml-4 mt-2 flex flex-row flex-shrink-0">
                                      <XMarkIcon
                                        onClick={() => {
                                          close();
                                          setSelectedCard(null);
                                        }}
                                        className="h-5 w-5 text-neutral-600 hover:text-neutral-900"
                                      />
                                    </div>
                                  </div>
                                  {/* Spend Tracker */}
                                  <div>
                                    <h4 className="sr-only">Progress</h4>
                                    <div className="mt-2" aria-hidden="true">
                                      <div className="overflow-hidden rounded-full bg-gray-200">
                                        <div
                                          className="h-2 rounded-full bg-green-500"
                                          style={{ width: `37.5%` }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-4 rounded-lg">
                                    <img
                                      className="h-44 w-auto"
                                      src={
                                        selectedCard.type ===
                                        "businessDebitCard"
                                          ? physicalCard
                                          : virtualCard
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <div className="flex flex-wrap sm:space-y-0 justify-between">
                                      <button
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 rounded-md bg-transparent py-1.5 px-2.5 text-sm font-semibold text-neutral-700 hover:shadow-sm hover:bg-neutral-100 
                                      focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-200"
                                      >
                                        <LockClosedIcon
                                          className="-ml-0.5 h-4 w-4"
                                          aria-hidden="true"
                                        />
                                        Lock
                                      </button>
                                      <button
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 rounded-md bg-transparent py-1.5 px-2.5 text-sm font-semibold text-neutral-700 hover:shadow-sm hover:bg-neutral-100 
                                      focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-200"
                                      >
                                        <QueueListIcon
                                          className="-ml-0.5 h-4 w-4"
                                          aria-hidden="true"
                                        />
                                        Transactions
                                      </button>
                                      <button
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 rounded-md bg-transparent py-1.5 px-2.5 text-sm font-semibold text-neutral-700 hover:shadow-sm hover:bg-neutral-100 
                                      focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-200"
                                      >
                                        <EllipsisVerticalIcon
                                          className="-ml-0.5 h-4 w-4"
                                          aria-hidden="true"
                                        />
                                        More
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="px-4 py-5 sm:p-6 space-y-8 sm:space-y-6">
                                  {/* Card Details */}
                                  <div>
                                    <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                                      Billing address
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                                      {selectedCard.attributes.address.street}{" "}
                                      {selectedCard.attributes.address.street2}
                                    </dd>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                                      {selectedCard.attributes.address.city},{" "}
                                      {selectedCard.attributes.address.state}{" "}
                                      {
                                        selectedCard.attributes.address
                                          .postalCode
                                      }
                                    </dd>
                                  </div>
                                </div>
                                <div className="px-4 py-5 sm:p-6 space-y-6 sm:space-y-4">
                                  {/* Card Limits */}
                                  <div className="flex items-center justify-between">
                                    <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                                      Daily spend limit
                                    </dt>
                                    <dd className="mt-1 text-sm font-medium text-gray-800 sm:col-span-2">
                                      {FormatCurrency(
                                        selectedCard.limits.attributes.limits
                                          .dailyPurchase / 100
                                      )}
                                    </dd>
                                  </div>
                                  <div className="flex items-center justify-between">
                                    <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                                      Daily withdrawal
                                    </dt>
                                    <dd className="mt-1 text-sm font-medium text-gray-800 sm:col-span-2">
                                      $500.00
                                    </dd>
                                  </div>
                                  <div className="flex justify-between py-1 text-sm font-medium">
                                    <dt className="text-gray-500">Account</dt>
                                    <dd className="text-gray-800">
                                      {getAccount(
                                        selectedCard.relationships.account.data
                                          .id
                                      )}
                                    </dd>
                                  </div>
                                  <div className="flex justify-between py-1 text-sm font-medium">
                                    <dt className="text-gray-500">Card Type</dt>
                                    <dd className="text-gray-800">
                                      {selectedCard.type === "businessDebitCard"
                                        ? "Physical Debit"
                                        : "Virtual Debit"}
                                    </dd>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </Disclosure.Panel>
                </Transition>
              </div>
            </Disclosure>
          </>
        ) : (
          <></>
        )}

        {cards && cards.length > 0 ? (
          <>
            {/* <div className="mt-8 grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-8">
            <div className="lg:col-span-6 lg:col-start-1">
              <ul className="space-y-2">
                {cards.map((card) => (
                  <li key={card.id} onClick={() => selectCard(card)}>
                    <Card card={card} />
                  </li>
                ))}
              </ul>
            </div>

            <div className="lg:col-span-2">
              <div className="mb-4 rounded-lg bg-white p-2 shadow dark:bg-gray-800 sm:p-4 overflow-auto overflow-y-auto">
                <div className="flex flex-wrap items-center justify-between sm:flex-nowrap pb-4">
                  {selectedCard && (
                    <>
                      <SelectedCard card={selectedCard} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div> */}
          </>
        ) : (
          <>
            <EmptyState
              title={"No Cards"}
              description={
                "You have not created any cards set up for your Account yet. Click the button below to create your first card!"
              }
            />
          </>
        )}
      </div>
    </>
  );
};

const SelectedCard = ({ card }) => {
  const { organization } = useSelector((state) => state.organization);

  const { accounts } = useSelector((state) => state.unit);

  const account = accounts.find(
    (account) => account.id === card.relationships.account.data.id
  );

  const getUserName = (userId) => {
    const user = organization.platformUsers.find((user) => user.id === userId);
    return user ? user.name : "";
  };

  const totalMonthlySpend = Object.values(
    card.limits.attributes.monthlyTotals
  ).reduce((sum, value) => sum + value, 0);

  return (
    <>
      <div className="flex flex-col min-w-full">
        {/* Summary */}
        <div>
          <h3 className="text-base leading-6 font-medium text-gray-700">
            {card.attributes.fullName.first}'s{" "}
            {card.type === "businessDebitCard" ? "Debit" : "Virtual"} Card
          </h3>
        </div>
        {/* Card Graphic */}
        <DebitCard card={card} />
        {/* Card Actions */}
        <div></div>
        {/* Card Address */}

        <div>
          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
            Location
          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
            {card.attributes.address.street} {card.attributes.address.street2}
          </dd>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
            {card.attributes.address.city}, {card.attributes.address.state}{" "}
            {card.attributes.address.postalCode}
          </dd>
        </div>
        {/* Card Limits */}
        <div className="mt-3 mb-3">
          <div className="flex items-center justify-between">
            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
              Daily spend limit
            </dt>
            <dd className="mt-1 text-sm font-medium text-gray-800 sm:col-span-2">
              {FormatCurrency(
                card.limits.attributes.limits.dailyPurchase / 100
              )}
            </dd>
          </div>
          <div className="flex items-center justify-between mt-2">
            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
              Daily withdrawal limit
            </dt>
            <dd className="mt-1 text-sm font-medium text-gray-800 sm:col-span-2">
              {FormatCurrency(
                card.limits.attributes.limits.dailyWithdrawal / 100
              )}
            </dd>
          </div>
        </div>
        {/* Card Details */}
        <div>
          <dl className="mt-2">
            <div className="flex justify-between py-1 text-sm font-medium">
              <dt className="text-gray-500">Account</dt>
              <dd className="text-gray-800">
                {account.type === "depositAccount" ? "Checking" : "Credit"} •{" "}
                {account.attributes.accountNumber.slice(-4)}
              </dd>
            </div>
            <div className="flex justify-between py-1 text-sm font-medium">
              <dt className="text-gray-500">Card Type</dt>
              <dd className="text-gray-800">
                {card.type === "businessDebitCard"
                  ? "Physical Debit"
                  : "Virtual Debit"}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

export const DebitCard = ({ card }) => {
  return (
    <>
      <div>
        {/* Heading Component */}
        {/* Footer Component */}
      </div>
    </>
  );
};

export function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={clsx(
        "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium text-gray-800 capitalize",
        status === "active" && "bg-green-100 text-green-700",
        status === "inactive" && "bg-gray-100 text-gray-700",
        status === "frozen" && "bg-yellow-100 text-yellow-700"
      )}
    >
      {status}
    </span>
  );
}

const Card = ({ card }) => {
  const { organization } = useSelector((state) => state.organization);

  const { accounts } = useSelector((state) => state.unit);

  const account = accounts.find(
    (account) => account.id === card.relationships.account.data.id
  );

  const getUserName = (userId) => {
    const user = organization.platformUsers.find((user) => user.id === userId);
    return user ? user.name : "";
  };

  const totalMonthlySpend = Object.values(
    card.limits.attributes.monthlyTotals
  ).reduce((sum, value) => sum + value, 0);

  return (
    <>
      <div className="relative flex items-center space-x-3 rounded-lg  bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:border-gray-400 ">
        <div className="w-1/4">
          <a href="#" className="focus:outline-none">
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-sm font-medium text-gray-900">
              {card.attributes.fullName.first} {card.attributes.fullName.last}
            </p>
          </a>
        </div>
        <div className="flex flex-row items-center justify-between w-9/12">
          <div className="flex flex-row items-center">
            <img
              className="h-6 w-auto"
              src={
                card.type === "businessDebitCard" ? miniPhysical : miniVirtual
              }
              alt=""
            />
            <p className="truncate text-sm text-gray-500 ml-1">
              • {card.attributes.last4Digits}
            </p>
          </div>

          <div>
            <p className="truncate text-sm text-gray-500 text-left">
              {FormatCurrency(totalMonthlySpend / 100)} /{" "}
              {FormatCurrency(
                card.limits.attributes.limits.monthlyPurchase / 100
              )}
            </p>
          </div>
          <div>
            <p className="truncate text-sm text-gray-500">
              {card.type === "businessDebitCard" ? "Physical" : "Virtual"}
            </p>
          </div>
          <div>
            <p className="truncate text-sm text-gray-500">
              {account.type === "depositAccount" ? "Checking" : "Credit"} •{" "}
              {account.attributes.accountNumber.slice(-4)}
            </p>
          </div>
          <div>
            <StatusPill value={card.attributes.status} />
          </div>
        </div>
      </div>
    </>
  );
};

const Breadcrumbs = () => {
  return (
    <>
      <Breadcrumb className="mb-6">
        <Breadcrumb.Item href="/">
          <div className="flex items-center gap-x-3">
            <HomeIcon className="h-5 w-5" />
            <span className="dark:text-white">Home</span>
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Cards</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default Cards;
