import api from "..";

// Get All Tasks
const getTasks = async () => {
  const response = await api.get("/tasks", {
    params: {
      populate: "createdBy,assignedTo,project",
    },
  });
  return response.data;
};

// Create Task
const createTask = async (task) => {
  const response = await api.post("/tasks", task);
  return response.data;
};

// Update Task
const updateTask = async (task) => {
  console.log("taskTask.updateTask", task);
  const response = await api.patch(`/tasks/${task.id}`, task);
  return response.data;
};

// Delete Task
const deleteTask = async (id) => {
  const response = await api.delete(`/tasks/${id}`);
  return response.data;
};

const taskService = {
  getTasks,
  createTask,
  updateTask,
  deleteTask,
};

export default taskService;
