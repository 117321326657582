import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomListbox,
  CustomTextArea,
  CustomTextInput,
  EmptyState,
  PageHeader,
  RowActions,
  CustomTable,
  SimpleTable,
} from "../components";
import { Badge, Breadcrumb, Button, Modal } from "flowbite-react";
import {
  ArrowUpCircleIcon,
  ArrowUpTrayIcon,
  PlusIcon,
  PlusSmallIcon,
} from "@heroicons/react/20/solid";
import { HomeIcon } from "@heroicons/react/24/outline";
import { createInvoice, getInvoices } from "../features/invoice/invoiceSlice";
import { createColumnHelper } from "@tanstack/react-table";
import FormatDate from "../utils/FormatDate";
import { useForm } from "react-hook-form";
import FormatCurrency from "../utils/FormatCurrency";
import FromUnixTime from "../utils/FromUnixTime";
import FormatProperCase from "../utils/FormatProperCase";
import { getInvoiceItems } from "../features/invoiceItem/invoiceItemSlice";
import { Link } from "react-router-dom";

const secondaryNavigation = [
  { name: "Drafts", href: "#", current: true },
  { name: "Open", href: "#", current: false },
  { name: "Overdue", href: "#", current: false },
  { name: "Paid", href: "#", current: false },
];

const stats = [
  {
    name: "Revenue",
    value: "$405,091.00",
    change: "+4.75%",
    changeType: "positive",
  },
  {
    name: "Overdue invoices",
    value: "$12,787.00",
    change: "+54.02%",
    changeType: "negative",
  },
  {
    name: "Outstanding invoices",
    value: "$245,988.00",
    change: "-1.39%",
    changeType: "positive",
  },
  {
    name: "Expenses",
    value: "$30,156.00",
    change: "+10.18%",
    changeType: "negative",
  },
];

const statuses = {
  draft: "text-gray-600  bg-gray-50 ring-gray-500/10",
  open: "text-green-700 bg-green-50 ring-green-600/20",
  withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
  overdue: "text-red-700 bg-red-50 ring-red-600/10",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Invoices = () => {
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();

  useEffect(() => {
    dispatch(getInvoices());
    dispatch(getInvoiceItems());
  }, [dispatch]);

  const { invoices } = useSelector((state) => state.invoice);

  // const columns = React.useMemo(
  //   () => [
  //     columnHelper.accessor("name", {
  //       header: () => "Invoice",
  //       cell: (info) => info.getValue(),
  //     }),
  //     columnHelper.accessor("project.name", {
  //       header: "Project",
  //       cell: (info) => info.getValue(),
  //     }),
  //     columnHelper.accessor("client.fullName", {
  //       header: "Client",
  //       cell: (info) => info.getValue(),
  //     }),
  //     // columnHelper.accessor("startDate", {
  //     //   header: "Start Date",
  //     //   cell: (props) => <span>{FormatDate(props.getValue())}</span>,
  //     // }),
  //     columnHelper.accessor("totalAmount", {
  //       header: "Total",
  //       cell: (props) => <span>{props.getValue() || "$0.00"}</span>,
  //     }),
  //     columnHelper.accessor("paidAmount", {
  //       header: "Paid",
  //       cell: (props) => <span>{props.getValue() || "$0.00"}</span>,
  //     }),
  //     columnHelper.accessor("status", {
  //       header: "Status",
  //       cell: (props) => (
  //         <>
  //           <div className="flex">
  //             <Badge color="success">{props.getValue().toUpperCase()}</Badge>
  //           </div>
  //         </>
  //       ),
  //       size: 86,
  //     }),
  //     columnHelper.display({
  //       id: "actions",
  //       cell: (props) => <RowActions row={props.row} />,
  //       size: 48,
  //     }),
  //   ],
  //   []
  // );

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("number", {
        header: () => "Invoice",
        cell: (info) => info.getValue(),
      }),
      // columnHelper.accessor("project.name", {
      //   header: "Project",
      //   cell: (info) => info.getValue(),
      // }),
      columnHelper.accessor("customer_name", {
        header: "Client",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("due_date", {
        header: "Due Date",
        cell: (props) => (
          <span>{FormatDate(FromUnixTime(props.getValue())) || "N/A"}</span>
        ),
      }),
      columnHelper.accessor("total", {
        header: "Total",
        cell: (props) => (
          <span>{FormatCurrency(props.getValue()) || "$0.00"}</span>
        ),
      }),
      columnHelper.accessor("amount_paid", {
        header: "Paid",
        cell: (props) => (
          <span>{FormatCurrency(props.getValue()) || "$0.00"}</span>
        ),
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (props) => (
          <>
            <div className="flex">
              <Badge color="success">{props.getValue().toUpperCase()}</Badge>
            </div>
          </>
        ),
        size: 86,
      }),
      columnHelper.display({
        id: "actions",
        cell: (props) => <RowActions row={props.row} />,
        size: 48,
      }),
    ],
    []
  );

  const columnsNew = React.useMemo(
    () => [
      columnHelper.display({
        id: "value",
        cell: (props) => (
          <>
            <div className="relative py-5 pr-6">
              <div className="flex gap-x-6">
                <ArrowUpCircleIcon
                  className="hidden h-6 w-5 flex-none text-gray-400 sm:block"
                  aria-hidden="true"
                />
                <div className="flex-auto">
                  <div className="flex items-start gap-x-3">
                    <div className="text-sm font-medium leading-6 text-gray-900">
                      {FormatCurrency(props.row.original.total) || "$0.00"}
                    </div>
                    <div
                      className={classNames(
                        statuses[props.row.original.status],
                        "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                      )}
                    >
                      {FormatProperCase(props.row.original.status)}
                    </div>
                  </div>
                  {props.row.original.due_date ? (
                    <div className="mt-1 text-xs leading-5 text-gray-500">
                      Due on{" "}
                      {FormatDate(FromUnixTime(props.row.original.due_date))}
                    </div>
                  ) : (
                    <div className="mt-1 text-xs leading-5 text-gray-500">
                      No due date
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ),
      }),
      columnHelper.display({
        id: "details",
        cell: (props) => (
          <>
            <div className="hidden py-5 pr-6 sm:table-cell">
              <div className="text-sm leading-6 text-gray-900">
                {props.row.original.customer_name}
              </div>
              <div className="mt-1 text-xs leading-5 text-gray-500">
                {props.row.original.customer_email}
              </div>
            </div>
          </>
        ),
      }),
      columnHelper.display({
        id: "actions",
        cell: (props) => (
          <>
            <div className="py-5 text-right">
              <div className="flex justify-end">
                <Link
                  to={`${props.row.original.id}`}
                  relative="path"
                  className={`text-blue-600 hover:text-blue-900 focus:outline-none focus:underline transition duration-150 ease-in-out`}
                >
                  View<span className="hidden sm:inline"> invoice</span>
                  <span className="sr-only">
                    , invoice #{props.row.original.number},{" "}
                    {props.row.original.customer_name}
                  </span>
                </Link>
              </div>
              <div className="mt-1 text-xs leading-5 text-gray-500">
                Invoice{" "}
                <span className="text-gray-900">
                  #{props.row.original.number}
                </span>
              </div>
            </div>
          </>
        ),
      }),
    ],
    []
  );

  return (
    <>
      <div className="mx-auto max-w-8xl px-4 py-8 sm:px-6 lg:px-12">
        <Breadcrumbs />
        <header className="pb-4 sm:pb-6">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold tracking-wide leading-6 text-gray-900">
                Invoices
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the users in your account including their name,
                title, email and role.
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <AddInvoiceModal />
            </div>
          </div>
        </header>

        {/* Stats */}
        <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
          <dl className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
            {stats.map((stat, statIdx) => (
              <div
                key={stat.name}
                className={classNames(
                  statIdx % 2 === 1
                    ? "sm:border-l"
                    : statIdx === 2
                    ? "lg:border-l"
                    : "",
                  "flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8"
                )}
              >
                <dt className="text-sm font-medium leading-6 text-gray-500">
                  {stat.name}
                </dt>
                <dd
                  className={classNames(
                    stat.changeType === "negative"
                      ? "text-rose-600"
                      : "text-gray-700",
                    "text-xs font-medium"
                  )}
                >
                  {stat.change}
                </dd>
                <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>

        <div className="pt-8">
          <div className="mx-auto flex flex-wrap items-center gap-6 sm:flex-nowrap ">
            <h1 className="text-base font-medium tracking-wide leading-7 text-gray-900">
              Your invoices
            </h1>
            <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
              {secondaryNavigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={item.current ? "text-blue-600" : "text-gray-700"}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Table */}

        {invoices && invoices.length > 0 ? (
          <>
            <div className="-my-8">
              <SimpleTable
                headless={true}
                columns={columnsNew}
                data={invoices}
                showPagination={false}
              />
            </div>
            {/* <CustomTable columns={columns} data={invoices} /> */}
          </>
        ) : (
          <>
            <EmptyState
              title={"No Invoices"}
              description={
                "You have not created any cards set up for your Account yet. Click the button below to create your first card! Need to reate a reusable component for custom SVG Icons"
              }
            />
          </>
        )}
      </div>
    </>
  );
};

const AddInvoiceModal = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const { clients } = useSelector((state) => state.client);
  const { projects } = useSelector((state) => state.project);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      project: {},
      client: {},
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(createInvoice(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setIsOpen(() => !isOpen);
      }
    });
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="ml-auto flex items-center gap-x-1 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
      >
        <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
        New invoice
      </button>
      <Modal onClose={() => setIsOpen(false)} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Add new invoice</strong>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="col-span-2">
              <CustomTextInput
                control={control}
                type="text"
                id="name"
                name="name"
                label="Invoice Name"
                placeholderTx="Invoice #1002"
              />
            </div>
            <div>
              <CustomListbox
                label={"Client"}
                items={clients}
                attribute={"fullName"}
                name={"client"}
                setValue={setValue}
              />
            </div>
            <div>
              <CustomListbox
                label={"Project"}
                items={projects}
                attribute={"name"}
                name={"project"}
                setValue={setValue}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit(onSubmit)}>Add invoice</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Breadcrumbs = () => {
  return (
    <>
      <Breadcrumb className="mb-6">
        <Breadcrumb.Item href="/">
          <div className="flex items-center gap-x-3">
            <HomeIcon className="h-5 w-5" />
            <span className="dark:text-white">Home</span>
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Invoices</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default Invoices;
