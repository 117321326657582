import api from "../../features";

// Get All Services
const getServices = async () => {
  const accountId = "acct_1MhQhvFLPxDRgaVU";
  const response = await api.get(`stripe/products/${accountId}`);
  return response.data;
};

// Get All Services
const queryServices = async (query) => {
  const accountId = "acct_1MhQhvFLPxDRgaVU";
  const response = await api.get(`stripe/products/${accountId}`, query);
  return response.data;
};

// Create Service
const createService = async (service) => {
  const accountId = "acct_1MhQhvFLPxDRgaVU";
  const response = await api.post(`stripe/products/${accountId}`, service);
  return response.data;
};

// Update Service
const updateService = async (serviceId, request) => {
  const accountId = "acct_1MhQhvFLPxDRgaVU";
  const response = await api.patch(
    `stripe/products/${accountId}/${serviceId}`,
    request
  );
  return response.data;
};

// Delete Service
// Note: Stripe does not allow deleting products, so we are just updating the product to inactive
const deleteService = async (id) => {
  const accountId = "acct_1MhQhvFLPxDRgaVU";
  const response = await api.delete(`stripe/products/${accountId}/${id}`);
  console.log(response);
  return response.data;
};

const serviceService = {
  getServices,
  queryServices,
  createService,
  updateService,
  deleteService,
};

export default serviceService;
