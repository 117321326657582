import React, { useEffect, useState, Fragment, useRef } from "react";
import { format } from "date-fns";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Tab, Transition, Listbox, Menu } from "@headlessui/react";
import {
  CustomAvatar,
  PageHeader,
  InlineTextInput,
  TextLink,
  CustomCard,
  CustomDatepicker,
  CustomTextArea,
  CustomTextInput,
  Dropzone,
  UploadImage,
  ImageGrid,
} from "../components";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  FolderIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { Breadcrumb, Button } from "flowbite-react";
import {
  CalendarIcon,
  EllipsisVerticalIcon,
  HandThumbUpIcon,
  PencilSquareIcon as PencilSquareIconMini,
  PlusIcon as PlusIconMini,
  UserIcon,
} from "@heroicons/react/20/solid";
import { createTask } from "../features/task/taskSlice";
import { updateProject } from "../features/project/projectSlice";
import FormatCurrency from "../utils/FormatCurrency";
import FormatProperCase from "../utils/FormatProperCase";
import { v4 } from "uuid";

const tabs = [
  { id: 1, name: "Overview", href: "#", current: true },
  { id: 2, name: "Budget", href: "#", current: false },
  { id: 3, name: "Invoices", href: "#", current: false },
  { id: 4, name: "Documents", href: "#", current: false },
  { id: 5, name: "Photos", href: "#", current: false },
  { id: 6, name: "Settings", href: "#", current: false },
  // { id: 6, name: "Team Members", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Project = () => {
  let { projectId } = useParams();

  const dispatch = useDispatch();

  const project = useSelector((state) =>
    state.project.projects.find((project) => project.id === projectId)
  );

  const client = useSelector((state) =>
    state.client.clients.find((client) => client.id === project.client.id)
  );

  const tasks = [...project.tasks];

  const sortedTasks = tasks.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  const [projectName, setProjectName] = useState(project.name);

  const [selected, setSelected] = useState(tabs[0]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [file, setFile] = useState(null);

  const uploadImage = (file) => {
    console.log("uploading file");

    dispatch(uploadImage(file));
  };

  return (
    <>
      <header>
        <div className="mx-auto max-w-8xl px-4 py-10 sm:px-6 lg:px-8">
          <Breadcrumbs />
          <div className="mx-auto flex pt-4 max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
            <div className="flex items-center gap-x-6">
              <img
                src="https://tailwindui.com/img/logos/48x48/tuple.svg"
                alt=""
                className="h-16 w-16 flex-none rounded-full ring-1 ring-gray-900/10"
              />
              <h1>
                <div className="text-sm leading-6 text-gray-500">
                  123 Maine Ave. New York, NY 12345
                </div>
                <div className="mt-1 text-base font-semibold leading-6 text-gray-900">
                  {projectName}
                </div>
              </h1>
            </div>
            <div className="flex items-center gap-x-4 sm:gap-x-6">
              <button
                type="button"
                className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block"
              >
                Copy URL
              </button>
              <a
                href="#"
                className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block"
              >
                Edit
              </a>
              <a
                href="#"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Send
              </a>

              <Menu as="div" className="relative sm:hidden">
                <Menu.Button className="-m-3 block p-3">
                  <span className="sr-only">More</span>
                  <EllipsisVerticalIcon
                    className="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(
                            active ? "bg-gray-50" : "",
                            "block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900"
                          )}
                        >
                          Copy URL
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? "bg-gray-50" : "",
                            "block px-3 py-1 text-sm leading-6 text-gray-900"
                          )}
                        >
                          Edit
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          {/* Secondary navigation */}
        </div>
      </header>

      {/* <PageHeader
        component={
          <>
            <div className="flex items-center">
              <div>
                <h1>
                  <InlineTextInput
                    size={"xxlarge"}
                    value={projectName}
                    setValue={setProjectName}
                  />
                </h1>
                <div className="mt-0.5 ml-3">
                  <div className="flex flex-row px-1 py-0.5">
                    <div className="flex self-center">
                      <p className="text-sm font-medium text-neutral-500">
                        123 Maine Ave. New York, NY 12345
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-0.5 ml-3">
                  <Link
                    to={`../clients/${client?.id}`}
                    className="flex flex-row px-1 py-0.5"
                  >
                    <div className="flex-shrink-0 mr-2">
                      <div className="relative">
                        <CustomAvatar
                          size="xsmall"
                          firstName={client?.firstName}
                          lastName={client?.lastName}
                        />
                      </div>
                    </div>
                    <div className="flex self-center">
                      <p className="text-sm font-medium text-neutral-500">
                        {client?.firstName} {client?.lastName}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </>
        }
        primaryButton={{ text: "Invite", onClick: () => console.log("Click") }}
        bottomBorder={false}
      /> */}
      {/* Tab Navigation */}
      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={setSelectedIndex}
        defaultIndex={0}
      >
        <div className="flex-1 -mt-6">
          <div className="relative md:px-4 xl:px-2">
            <div className="px-4 sm:px-6 md:px-0">
              <div className="py-6">
                {/* Tabs */}
                {/* Mobile Version - Listbox */}
                <Listbox
                  value={selected}
                  onChange={setSelected}
                  // onChange={(index) => {
                  //   console.log("Changed selected tab to:", index);
                  // }}
                >
                  {({ open }) => (
                    <>
                      <div className="lg:hidden">
                        <Listbox.Label
                          htmlFor="selected-tab"
                          className="sr-only"
                        >
                          Select a tab
                        </Listbox.Label>
                        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
                          <span className="block truncate">
                            {selected.name}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {tabs.map((tab) => (
                              <Listbox.Option
                                key={tab.id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-indigo-600"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={tab}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate"
                                      )}
                                    >
                                      {tab.name}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
                {/* Desktop Version - Tabs */}

                <div className="hidden lg:block">
                  <div className="border-b border-gray-200">
                    <Tab.List className="-mb-px flex space-x-8">
                      {tabs.map((tab) => (
                        <Tab key={tab.id} as={Fragment}>
                          {({ selected }) => (
                            <a
                              // key={tab.name}
                              href={tab.href}
                              className={classNames(
                                selected
                                  ? "border-blue-500 text-blue-600"
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                              )}
                            >
                              {tab.name}
                            </a>
                          )}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tab Contents */}
        <Tab.Panels>
          <Tab.Panel
            as={"div"}
            className="mx-auto mt-4 grid grid-cols-1 gap-6 sm:px-2 lg:grid-flow-col-dense lg:grid-cols-3"
          >
            <div className="space-y-6 lg:col-span-2 lg:col-start-1">
              <Tasks projectId={project.id} tasks={sortedTasks} />
              <Notes projectId={project.id} notes={project.notes} />
            </div>
            <div className="space-y-6 lg:col-span-1 lg:col-start-3">
              <Timeline projectId={project.id} notes={project.notes} />
            </div>
          </Tab.Panel>
          <Tab.Panel
            as={"div"}
            className="mx-auto mt-4 grid grid-cols-1 gap-6 sm:px-2 lg:grid-flow-col-dense lg:grid-cols-3"
          >
            <div className="p-1 space-y-6 lg:col-span-2 lg:col-start-1">
              <Budget projectId={project.id} tasks={sortedTasks} />
            </div>
          </Tab.Panel>
          <Tab.Panel
            as={"div"}
            className="mx-auto mt-4 grid grid-cols-1 gap-6 sm:px-2 lg:grid-flow-col-dense lg:grid-cols-6"
          >
            <div className="p-1 space-y-6 lg:col-span-4 lg:col-start-1">
              <Invoices />
            </div>
            <div className="p-1 space-y-6 lg:col-span-2 lg:col-start-5">
              <InvoiceSummary />
            </div>
          </Tab.Panel>
          <Tab.Panel
            as={"div"}
            className="mx-auto mt-4 grid grid-cols-1 gap-6 sm:px-2 lg:grid-flow-col-dense lg:grid-cols-3"
          >
            Tab 4
          </Tab.Panel>
          <Tab.Panel
            as={"div"}
            className="mx-auto mt-4 grid grid-cols-1 gap-6 sm:px-2 lg:grid-flow-col-dense lg:grid-cols-3"
          >
            <div className="lg:col-span-3">
              <Dropzone directory={`images/${project.id}`} />
              {/* <UploadImage directory={`images/${projectId}`} /> */}
              <div className="mt-6 w-full border-t border-gray-300" />
              <ImageGrid directory={`images/${project.id}`} />
            </div>
          </Tab.Panel>
          <Tab.Panel
            as={"div"}
            className="mx-auto mt-4 grid grid-cols-1 gap-6 sm:px-2 lg:grid-flow-col-dense lg:grid-cols-6"
          >
            <div className="p-1 space-y-6 lg:col-span-3 lg:col-start-1">
              <Settings projectId={project.id} project={project} />
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};

const Breadcrumbs = () => {
  return (
    <>
      <Breadcrumb className="mb-2">
        <Breadcrumb.Item href="/">
          <div className="flex items-center gap-x-3">
            <HomeIcon className="h-5 w-5" />
            <span className="dark:text-white">Home</span>
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/clients">Projects</Breadcrumb.Item>
        <Breadcrumb.Item>Project Detail</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

const InvoiceSummary = () => {
  const stats = [
    { name: "Outstanding", value: "$0.00" },
    { name: "Overdue", value: "$0.00" },
    { name: "Pending", value: "$0.00" },
    { name: "Paid", value: "$0.00" },
  ];

  const items = [
    {
      id: 1,
      name: "Invoice #1001",
      status: "paid",
      sentOn: "2023-01-17T23:44:48.335Z",
      dueDate: "12/12/2021",
      amount: 50000,
    },
    {
      id: 2,
      name: "Invoice #2003",
      status: "overdue",
      sentOn: "2023-01-17T23:44:48.335Z",
      dueDate: "12/12/2021",
      amount: 50000,
    },
    {
      id: 3,
      name: "Invoice #2078",
      status: "drafted",
      sentOn: null,
      dueDate: "12/12/2021",
      amount: 50000,
    },
    {
      id: 4,
      name: "Invoice #3005",
      status: "scheduled",
      sentOn: null,
      dueDate: null,
      amount: 50000,
    },
    // More items...
  ];

  const result = items.reduce((acc, item) => {
    if (!acc[item.status]) {
      acc[item.status] = 0;
    }
    acc[item.status] += item.amount;
    return acc;
  }, {});

  // console.log(result);

  return (
    <>
      <CustomCard>
        <div>
          <dl className="space-y-3">
            {/* {stats.map((item) => (
              <div key={item.name} className="overflow-hidden">
                <dt className="truncate text-sm font-medium text-gray-500">
                  {item.name}
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {item.value}
                </dd>
              </div>
            ))} */}
            {Object.entries(result).map(([status, amount]) => (
              <div key={status} className="overflow-hidden">
                <dt className="truncate text-sm font-medium text-gray-500">
                  {FormatProperCase(status)}
                </dt>
                <dd
                  className={classNames(`
                "mt-1 text-2xl font-medium tracking-tight"
                ${status === "paid" && " text-green-600"}
                ${status === "overdue" && " text-red-600"}
                ${status === "pending" && " text-yellow-600"}
                ${status === "scheduled" && " text-slate-600"}
                ${status === "draft" && " text-gray-600"}

              `)}
                  // className="mt-1 text-2xl font-semibold tracking-tight text-gray-700"
                >
                  {FormatCurrency(amount)}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </CustomCard>
    </>
  );
};

const Invoices = () => {
  const items = [
    {
      id: 1,
      name: "Invoice #1001",
      status: "paid",
      sentOn: "2023-01-17T23:44:48.335Z",
      dueDate: "12/12/2021",
      amount: 50000,
    },
    {
      id: 2,
      name: "Invoice #2003",
      status: "overdue",
      sentOn: "2023-01-17T23:44:48.335Z",
      dueDate: "12/12/2021",
      amount: 50000,
    },
    {
      id: 3,
      name: "Invoice #2078",
      status: "draft",
      sentOn: null,
      dueDate: "12/12/2021",
      amount: 50000,
    },
    {
      id: 4,
      name: "Invoice #3005",
      status: "scheduled",
      sentOn: null,
      dueDate: null,
      amount: 50000,
    },
    // More items...
  ];

  const result = items.reduce((acc, item) => {
    if (!acc[item.status]) {
      acc[item.status] = 0;
    }
    acc[item.status] += item.amount;
    return acc;
  }, {});

  function getMonth(date) {
    const d = new Date(date);
    const month = d.toLocaleString("default", { month: "short" });
    return month;
  }

  function getDate(date) {
    const d = new Date(date);
    const day = d.getDate();
    return day;
  }

  return (
    <>
      <CustomCard>
        <ul className="divide-y divide-gray-200">
          {items.map((item) => (
            <li key={item.id} className="px-6 py-4">
              {/* Your content */}
              <div className="flex items-center">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="flex flex-row items-center">
                    <div className="flex flex-col text-neutral-600 font-medium text-center mr-3">
                      {item.sentOn ? (
                        <>
                          <p className="text-xs">
                            {getMonth(item.sentOn).toUpperCase()}
                          </p>
                          <p className="text-lg">{getDate(item.sentOn)}</p>
                        </>
                      ) : (
                        <p className="text-lg">--</p>
                      )}
                    </div>
                    <div className="truncate">
                      <div className="flex text-sm">
                        <p className="truncate font-medium text-slate-800">
                          {item.name}
                        </p>
                        {item.dueDate && (
                          <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                            due on {item.dueDate}
                          </p>
                        )}
                      </div>
                      <div className="mt-1 flex">
                        <div className="flex items-center text-sm text-gray-500">
                          <span
                            className={classNames(`
                            "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium"
                            ${
                              item.status === "paid" &&
                              "bg-green-100 text-green-800"
                            }
                            ${
                              item.status === "overdue" &&
                              "bg-red-100 text-red-800"
                            }
                            ${
                              item.status === "pending" &&
                              "bg-yellow-100 text-yellow-800"
                            }
                            ${
                              item.status === "scheduled" &&
                              "bg-slate-100 text-slate-800"
                            }
                            ${
                              item.status === "draft" &&
                              "bg-gray-100 text-gray-800"
                            }

                          `)}
                          >
                            {/* {item.status.toUpperCase()} */}
                            {item.status.charAt(0).toUpperCase() +
                              item.status.slice(1)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Invoice Amount */}
                  <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                    <div className="flex -space-x-1 overflow-hidden">
                      <div className="text-base font-medium text-gray-600">
                        {FormatCurrency(item.amount)}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Invoice Actions */}
                <Menu as="div" className="ml-5 relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Edit Invoice
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Mark As Paid
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Duplicate
                            </a>
                          )}
                        </Menu.Item>
                        <form method="POST" action="#">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="submit"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block w-full px-4 py-2 text-left text-sm"
                                )}
                              >
                                Delete Invoice
                              </button>
                            )}
                          </Menu.Item>
                        </form>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              {/* Status */}
              {/* Due Date */}
              {/* Invoice Details */}
              {/* Invoice # / Name */}
              {/* Invoice Info - Status & Due Date */}
            </li>
          ))}
        </ul>
      </CustomCard>
    </>
  );
};

const Settings = ({ projectId, project }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: project ? project.name : "",
      description: project ? project.description : "",
      startDate: project ? project?.startDate : null,
      endDate: project ? project?.endDate : null,
    },
  });
  return (
    <>
      <CustomCard>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="col-span-2">
            <CustomTextInput
              control={control}
              type="text"
              id="name"
              name="name"
              label="Project Title"
              placeholderTx="Meet with client"
            />
          </div>

          <div>
            <CustomDatepicker
              label={"Start Date"}
              control={control}
              name={"startDate"}
            />
          </div>
          <div>
            <CustomDatepicker
              label={"End Date"}
              control={control}
              name={"endDate"}
            />
          </div>
          <div className="col-span-2">
            <CustomTextArea
              control={control}
              type="text"
              id="description"
              name="description"
              label="Description"
              placeholderTx="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit."
              rows={4}
            />
          </div>
        </div>
      </CustomCard>
    </>
  );
};

const Notes = ({ projectId, notes }) => {
  const dispatch = useDispatch();
  const [editNote, setEditNote] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      notes: notes,
    },
  });

  const onEdit = () => {
    setEditNote(() => !editNote);
  };

  const onCancel = () => {
    setEditNote(() => !editNote);
  };

  const onSubmit = (data) => {
    dispatch(
      updateProject({
        id: projectId,
        notes: data.notes,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setEditNote(() => !editNote);
        // toast.success("Notes updated successfully", {
        //   position: "bottom-right",
        // });
      }
    });
  };

  return (
    <>
      <section aria-labelledby="project-tasks">
        <div className="bg-white shadow sm:rounded-lg">
          {/* Container Header */}
          <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Notes
              </h2>
              {editNote ? (
                <>
                  <div className="ml-4 flex-shrink-0">
                    <button
                      type="button"
                      disabled={true}
                      className="inline-flex items-center rounded-full border border-white bg-white p-2 text-white hover:bg-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                      onClick={onEdit}
                    >
                      <PencilSquareIconMini
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="ml-4 flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-full border border-neutral-200 bg-white p-2 text-blue-600 shadow-sm hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-100 focus:ring-offset-2"
                      onClick={onEdit}
                    >
                      <PencilSquareIconMini
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Container Contents */}
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            {editNote ? (
              <>
                <div className="-m-0.5 rounded-lg p-0.5">
                  <label htmlFor="comment" className="sr-only">
                    Comment
                  </label>
                  <div>
                    <textarea
                      rows={5}
                      name="notes"
                      id="notes"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring-transparent sm:text-sm"
                      placeholder="Add your comment..."
                      {...register("notes")}
                    />
                  </div>
                </div>
                <div className="mt-2 flex justify-end gap-2">
                  <Button color={"white"} onClick={onCancel}>
                    Cancel
                  </Button>
                  <button
                    type="submit"
                    className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </button>
                </div>
              </>
            ) : (
              <>
                {notes ? (
                  <>
                    <div className="text-left">
                      <p className="mt-1 text-sm text-neutral-900">{notes}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center">
                      <h3 className="mt-2 text-sm font-medium text-gray-900">
                        No notes added yet
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Add notes to this project and keep track of important
                        information!
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const Timeline = ({ projectId, notes }) => {
  const dispatch = useDispatch();
  const [editNote, setEditNote] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      notes: notes,
    },
  });

  const onEdit = () => {
    setEditNote(() => !editNote);
  };

  const onCancel = () => {
    setEditNote(() => !editNote);
  };

  const onSubmit = (data) => {
    dispatch(
      updateProject({
        id: projectId,
        notes: data.notes,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setEditNote(() => !editNote);
        // toast.success("Notes updated successfully", {
        //   position: "bottom-right",
        // });
      }
    });
  };

  const timeline = [
    {
      id: 1,
      content: "Applied to",
      target: "Front End Developer",
      href: "#",
      date: "Sep 20",
      datetime: "2020-09-20",
      icon: UserIcon,
      iconBackground: "bg-gray-400",
    },
    {
      id: 2,
      content: "Advanced to phone screening by",
      target: "Bethany Blake",
      href: "#",
      date: "Sep 22",
      datetime: "2020-09-22",
      icon: HandThumbUpIcon,
      iconBackground: "bg-blue-500",
    },
    {
      id: 3,
      content: "Completed phone screening with",
      target: "Martha Gardner",
      href: "#",
      date: "Sep 28",
      datetime: "2020-09-28",
      icon: CheckIcon,
      iconBackground: "bg-green-500",
    },
    {
      id: 4,
      content: "Advanced to interview by",
      target: "Bethany Blake",
      href: "#",
      date: "Sep 30",
      datetime: "2020-09-30",
      icon: HandThumbUpIcon,
      iconBackground: "bg-blue-500",
    },
    {
      id: 5,
      content: "Completed interview with",
      target: "Katherine Snyder",
      href: "#",
      date: "Oct 4",
      datetime: "2020-10-04",
      icon: CheckIcon,
      iconBackground: "bg-green-500",
    },
  ];

  return (
    <>
      <section aria-labelledby="project-activity">
        <div className="bg-white shadow sm:rounded-lg">
          {/* Container Header */}
          <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Activity
              </h2>
            </div>
          </div>
          {/* Container Contents */}
          <div className="border-t border-gray-200 p-8">
            {timeline.length > 0 ? (
              <>
                <div className="flow-root">
                  <ul className="-mb-8">
                    {timeline.map((event, eventIdx) => (
                      <li key={event.id}>
                        <div className="relative pb-8">
                          {eventIdx !== timeline.length - 1 ? (
                            <span
                              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="relative flex space-x-3">
                            <div>
                              <span
                                className={classNames(
                                  event.iconBackground,
                                  "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                                )}
                              >
                                <event.icon
                                  className="h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                            <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                              <div>
                                <p className="text-sm text-gray-500">
                                  {event.content}{" "}
                                  <a
                                    href={event.href}
                                    className="font-medium text-gray-900"
                                  >
                                    {event.target}
                                  </a>
                                </p>
                              </div>
                              <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                <time dateTime={event.datetime}>
                                  {event.date}
                                </time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            ) : (
              <>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className="text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                      />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No tasks created yet
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started by creating a{" "}
                      <span>
                        <TextLink
                          text="new task"
                          className={"text-sm"}
                          onClick={() => console.log("Create Task!")}
                        />
                        .
                      </span>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const Budget = ({ projectId, tasks }) => {
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(
      createTask({
        projectId: projectId,
        name: "New Task",
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        console.log(res);
      }
    });
  };

  return (
    <>
      <section aria-labelledby="project-tasks">
        <div className="bg-white shadow sm:rounded-lg">
          {/* Container Header */}
          <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Tasks
              </h2>
              <div className="ml-4 flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex items-center rounded-full border border-neutral-200 bg-white p-2 text-blue-600 shadow-sm hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-100 focus:ring-offset-2"
                  onClick={onSubmit}
                >
                  <PlusIconMini className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          {/* Container Contents */}
          <div className="border-t border-gray-200 ">
            {tasks.length > 0 ? (
              <>
                <TaskList tasks={tasks} />
              </>
            ) : (
              <>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className="text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                      />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No tasks created yet
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started by creating a{" "}
                      <span>
                        <TextLink
                          text="new task"
                          className={"text-sm"}
                          onClick={() => console.log("Create Task!")}
                        />
                        .
                      </span>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const Tasks = ({ projectId, tasks }) => {
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(
      createTask({
        projectId: projectId,
        name: "New Task",
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        console.log(res);
      }
    });
  };

  return (
    <>
      <section aria-labelledby="project-tasks">
        <div className="bg-white shadow sm:rounded-lg">
          {/* Container Header */}
          <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Tasks
              </h2>
              <div className="ml-4 flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex items-center rounded-full border border-neutral-200 bg-white p-2 text-blue-600 shadow-sm hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-100 focus:ring-offset-2"
                  onClick={onSubmit}
                >
                  <PlusIconMini className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          {/* Container Contents */}
          <div className="border-t border-gray-200 ">
            {tasks.length > 0 ? (
              <>
                <TaskList tasks={tasks} />
              </>
            ) : (
              <>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div className="text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                      />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No tasks created yet
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started by creating a{" "}
                      <span>
                        <TextLink
                          text="new task"
                          className={"text-sm"}
                          onClick={() => console.log("Create Task!")}
                        />
                        .
                      </span>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export const TaskList = ({ tasks }) => {
  return (
    <>
      <div className="overflow-hidden bg-white shadow sm:rounded-md">
        <ol role="list" className="divide-y divide-gray-200">
          {tasks.map((task) => (
            <li key={task.id}>
              <Task task={task} />
            </li>
          ))}
        </ol>
      </div>
    </>
  );
};

export const Task = ({ task }) => {
  const checkbox = useRef();
  const [taskCompleted, setTaskCompleted] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({});

  const formatDate = (date) => {
    const d = new Date(date);

    const options = {
      month: "short",
      day: "numeric",
      // year: "numeric",
    };

    return d.toLocaleDateString("en-US", options);
  };

  const openTask = () => {
    console.log("Open Task");
    setOpenForm(!openForm);
  };

  const onSubmit = (data) => {
    console.log("data", data);
    // dispatch(createClient(data)).then((res) => {
    //   if (res.meta.requestStatus === "fulfilled") {
    //     // setSlideOverOpen(!slideOverOpen);
    //     setOpenForm(!openForm);
    //   }
    // });
  };

  const onClose = () => {
    setOpenForm(!openForm);
  };

  const handleCheckboxChange = () => {
    setTaskCompleted(!taskCompleted);
    console.log("checkbox", checkbox.current.checked);
  };

  return (
    <>
      <a href="#" className="block hover:bg-gray-50" onClick={() => openTask()}>
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="flex flex-row items-center">
              <div className="flex h-5 items-center">
                <input
                  id="task"
                  aria-describedby="task"
                  name="task"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  ref={checkbox}
                  checked={taskCompleted}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className=" truncate ml-4">
                <div className="flex text-sm">
                  <p
                    className={classNames(
                      "truncate font-medium text-neutral-600 ",
                      taskCompleted && "line-through"
                    )}
                  >
                    {task.name}
                  </p>
                  {task.dueDate && (
                    <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                      Due on{" "}
                      <time dateTime={task.dueDate}>
                        {formatDate(task.dueDate)}
                      </time>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
              <div className="flex -space-x-1 overflow-hidden">
                {task.assignedTo.length > 0 ? (
                  <>
                    {task.assignedTo.map((assignee) => (
                      <img
                        key={assignee.email}
                        className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                        src={assignee.imageUrl}
                        alt={`${assignee.firstName} ${assignee.lastName}`}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    <CustomAvatar
                      size="xsmall"
                      firstName={task.createdBy.firstName}
                      lastName={task.createdBy.lastName}
                      imageUrl={task.createdBy.imageUrl}
                      color="black"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="ml-5 flex-shrink-0">
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </a>
      {/* <TaskModal
        task={task}
        open={openForm}
        setOpen={setOpenForm}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      /> */}
    </>
  );
};

export default Project;
