import React, { Fragment, forwardRef } from "react";
import { Label, TextInput } from "flowbite-react";
import { Controller } from "react-hook-form";

export const CustomNumberInput = forwardRef(
  (
    {
      key,
      type = "number",
      id,
      name,
      label,
      placeholderTx = "0.00",
      helperTx,
      // React Hook form
      control,
      options,
      rules,
      defaultValue,
      errors,
      pattern,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <div className="mt-1">
          <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { value, onChange } }) => {
              return (
                <>
                  <Label htmlFor={name}>{label}</Label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      ref={ref}
                      type={type}
                      name={name}
                      id={id}
                      value={value}
                      onChange={onChange}
                      className="block w-full rounded-lg border-0 py-2 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 bg-gray-50"
                      placeholder={placeholderTx}
                      {...rest}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        USD
                      </span>
                    </div>
                  </div>
                </>
              );
            }}
          />
        </div>
      </>
    );
  }
);

export default CustomNumberInput;
