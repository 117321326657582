// Slices for Initial State, Actions/Functions
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import unitService from "./unitService";
import { toast } from "react-toastify";

// Create Initial State for Users
const initialState = {
  applicationStep: 1,
  application: {
    id: "",
    companyName: "",
    companyLegalName: "",
    companyDescription: "",
    companyWebsite: "",
    industry: "",
    ein: "",
    address: {
      street: "",
      street2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
    phoneNumber: {
      countryCode: "",
      number: "",
    },
    status: "",
  },
  customer: {},
  accounts: [],
  cards: [],
  counterparties: [],
  transactions: [],
  authorizations: [],
  payments: [],
  balanceHistory: [],
  statements: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Unit Health Check
export const getUnitCustomer = createAsyncThunk(
  "unit/customer",
  async (customerId, thunkAPI) => {
    try {
      return await unitService.getUnitCustomer(customerId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Unit Health Check
export const getUnitAccounts = createAsyncThunk(
  "unit/accounts",
  async (customerId, thunkAPI) => {
    try {
      return await unitService.getUnitAccounts(customerId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Unit Health Check
export const getUnitCards = createAsyncThunk(
  "unit/cards",
  async (customerId, thunkAPI) => {
    try {
      return await unitService.getUnitCards(customerId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Unit - Transactions - Query / List
export const getUnitTransactions = createAsyncThunk(
  "unit/transactions",
  async (query, thunkAPI) => {
    try {
      return await unitService.getUnitTransactions(query);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Unit - Payments - Query / List
export const getUnitPayments = createAsyncThunk(
  "unit/payments/list",
  async (query, thunkAPI) => {
    try {
      return await unitService.getUnitPayments(query);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Unit - Create Book Payment
export const createBookPayment = createAsyncThunk(
  "unit/payment/book",
  async (request, thunkAPI) => {
    try {
      return await unitService.createBookPayment(request);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Unit Health Check
export const updateUnitTransaction = createAsyncThunk(
  "unit/transactions/update",
  async (request, thunkAPI) => {
    try {
      return await unitService.updateUnitTransaction(request);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Unit Counterparties
export const getUnitCounterparties = createAsyncThunk(
  "unit/counterparties",
  async (query, thunkAPI) => {
    try {
      return await unitService.getUnitCounterparties(query);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Unit Create Card
export const createUnitCard = createAsyncThunk(
  "unit/cards/create",
  async (request, thunkAPI) => {
    try {
      return await unitService.createUnitCard(request);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Unit Create Counterparty
export const createUnitCounterparty = createAsyncThunk(
  "unit/counterparty/create",
  async (request, thunkAPI) => {
    try {
      return await unitService.createUnitCounterparty(request);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Unit Health Check
export const getUnitBalanceHistory = createAsyncThunk(
  "unit/customer/balance-history",
  async (customerId, thunkAPI) => {
    try {
      return await unitService.getUnitBalanceHistory(customerId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Unit Health Check
export const getUnitStatements = createAsyncThunk(
  "unit/statements",
  async (customerId, thunkAPI) => {
    try {
      return await unitService.getUnitStatements(customerId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUnitPdfStatement = createAsyncThunk(
  "unit/statements/pdf",
  async (statementId, thunkAPI) => {
    try {
      return await unitService.getUnitPdfStatement(statementId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUnitHtmlStatement = createAsyncThunk(
  "unit/statements/html",
  async (statementId, thunkAPI) => {
    try {
      return await unitService.getUnitHtmlStatement(statementId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Slice is collection of Reducers for Redux
export const unitSlice = createSlice({
  name: "unit",
  initialState,
  reducers: {
    // Ability to dispatch Reset function after Register or Login
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnitCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnitCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customer = action.payload.data;
      })
      .addCase(getUnitCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUnitAccounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnitAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.accounts = action.payload.data;
      })
      .addCase(getUnitAccounts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUnitCards.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnitCards.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cards = action.payload;
      })
      .addCase(getUnitCards.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUnitCounterparties.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnitCounterparties.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.counterparties = action.payload;
      })
      .addCase(getUnitCounterparties.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUnitTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnitTransactions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.transactions = action.payload.transactions?.data;
        state.authorizations = action.payload.authorizations?.data;
      })
      .addCase(getUnitTransactions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUnitPayments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnitPayments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.payments = action.payload.data;
      })
      .addCase(getUnitPayments.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createBookPayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createBookPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.payments.push(action.payload.data);
      })
      .addCase(createBookPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateUnitTransaction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUnitTransaction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.transactions[
          state.transactions.findIndex(
            (transaction) => transaction.id === action.payload.data.id
          )
        ] = action.payload.data;
        toast.success("Transaction updated successfully", {
          position: "bottom-right",
        });
      })
      .addCase(updateUnitTransaction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createUnitCard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createUnitCard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cards.push(action.payload.data);
      })
      .addCase(createUnitCard.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createUnitCounterparty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createUnitCounterparty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.counterparties.push(action.payload);
      })
      .addCase(createUnitCounterparty.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUnitBalanceHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnitBalanceHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.balanceHistory = action.payload.data;
      })
      .addCase(getUnitBalanceHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUnitStatements.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnitStatements.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.statements = action.payload.data;
      })
      .addCase(getUnitStatements.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUnitPdfStatement.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnitPdfStatement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(getUnitPdfStatement.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUnitHtmlStatement.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnitHtmlStatement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(getUnitHtmlStatement.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = unitSlice.actions;
export default unitSlice.reducer;
