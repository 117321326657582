import api from "../../features";

// Create Link Token for User
const getLinkToken = async () => {
  const response = await api.post(`/plaid/link-token`);
  // if (response.data) {
  //   localStorage.setItem(
  //     "plaid_link_token",
  //     JSON.stringify(response.data.link_token)
  //   );
  // }
  console.log(response.data);
  return response.data;
};

// Create Link Token for User
const getProcessorToken = async (plaidAccount) => {
  const response = await api.post(`/plaid/processor-token`, plaidAccount);
  console.log(response.data);
  return response.data;
};

// Exchange Public Token, Add Plaid Item/Account for User
const createPlaidItem = async (plaidItemData) => {
  const response = await api.post(`/plaid/items`, plaidItemData);
  return response.data;
};

// Exchange Public Token, Add Plaid Item/Account for User
// const getPlaidItems = async (user) => {
//   const response = await axios.post(API_URL + "item/" + user.id);
//   return response.data;
// };

// Exchange Public Token, Add Plaid Item/Account for User
// const getPlaidItemAccounts = async (itemId) => {
//   const response = await axios.post(API_URL + "item/" + itemId + "/accounts");
//   return response.data;
// };

// Exchange Public Token, Add Plaid Item/Account for User
// const getPlaidItemTransactions = async (accessToken) => {
//   const response = await axios.post(
//     API_URL + "item/" + accessToken + "/transactions"
//   );
//   console.log(response.data);
//   return response.data;
// };

// Exchange Public Token, Add Plaid Item/Account for User
// const deletePlaidItem = async (itemId) => {
//   const response = await axios.delete(API_URL + "item/" + itemId);
//   return response.data;
// };

const plaidService = {
  getLinkToken,
  getProcessorToken,
  createPlaidItem,
  // deletePlaidItem,
  // getPlaidItems,
  // getPlaidItemAccounts,
  // getPlaidItemTransactions,
};

export default plaidService;
