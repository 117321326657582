// usePlaidLinkWrapper.js
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePlaidLink } from "react-plaid-link";
import { createPlaidItem } from "../features/plaid/plaidSlice";

export const usePlaid = () => {
  const dispatch = useDispatch();
  const { linkToken } = useSelector((state) => state.plaid);

  const onSuccess = useCallback(
    async (publicToken, metadata) => {
      const plaidItemData = {
        publicToken: publicToken,
        metadata: metadata,
      };
      dispatch(createPlaidItem(plaidItemData)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          console.log("Plaid Item Created");
        }
      });
    },
    [dispatch]
  );

  const onExit = useCallback(async (error, metadata) => {
    console.log(error, metadata);
  }, []);

  const config = {
    token: linkToken,
    onSuccess,
    onExit,
  };

  const { open, ready, error } = usePlaidLink(config);

  return { open, ready, error };
};
