import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import {
  EmptyState,
  FileUpload,
  SimpleTable,
  TransactionActions,
} from "../components";
import { Breadcrumb } from "flowbite-react";
import { HomeIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";

const Payments = () => {
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();

  const recentPayments = useSelector((state) =>
    state.unit.payments.filter((payment) => payment.type === "achPayment")
  );

  const [selectedPayment, setSelectedPayment] = useState(null);

  const organization = useSelector((state) => state.organization.organization);

  const formatDate = (date) => {
    const d = new Date(date);

    const options = {
      month: "short",
      day: "numeric",
      // year: "numeric",
    };

    return d.toLocaleDateString("en-US", options);
  };

  function currencyFormatter(value) {
    return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  const getUserName = (userId) => {
    const user = organization.platformUsers.find((user) => user.id === userId);
    return user ? user.name : "";
  };

  const selectPayment = (payment) => {
    console.log(payment);
    setSelectedPayment(payment);
  };

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("attributes.createdAt", {
        header: "Date",
        cell: (props) => <span>{formatDate(props.getValue())}</span>,
        maxSize: 36,
      }),
      columnHelper.accessor(
        (row) =>
          `${
            row.attributes.merchant?.name || row.attributes.counterparty?.name
          }`,
        {
          id: "attributes.counterparty.name",
          header: () => "Counterparty",
          cell: (info) => info.getValue(),
        }
      ),
      columnHelper.accessor((row) => `${row.attributes.tags?.userId || null}`, {
        id: "attributes.tags",
        header: () => "User",
        cell: (props) => <span>{getUserName(props.getValue())}</span>,
      }),

      columnHelper.accessor("attributes.amount", {
        header: "Amount",
        cell: (props) => (
          <span>{currencyFormatter(props.getValue() / 100)}</span>
        ),
        maxSize: 72,
      }),
      columnHelper.accessor("attributes.status", {
        header: "Status",
      }),
      columnHelper.display({
        id: "actions",
        cell: (props) => (
          <>
            <TransactionActions
              row={props.row.original}
              selectTransaction={selectPayment}
            />
          </>
        ),
        maxSize: 16,
      }),
    ],
    []
  );

  return (
    <>
      <div className="mx-auto max-w-8xl px-4 py-8 sm:px-6 lg:px-12">
        <Breadcrumbs />
        <header>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold tracking-wide leading-6 text-gray-900">
                Payments
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the users in your account including their name,
                title, email and role.
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                <PaperAirplaneIcon
                  className="-ml-0.5 h-5 w-5"
                  aria-hidden="true"
                />
                Create payment
              </button>
            </div>
          </div>
        </header>
        <div className="mt-8">
          <FileUpload />
        </div>
        <div className="mt-8">
          <div className="mb-6">
            <h2 className="text-base font-medium tracking-wide leading-7 text-gray-700">
              Incomplete invoices
            </h2>
            <div className="mt-2 overflow-hidden rounded-lg bg-gray-50">
              <div className="px-4 py-5 sm:p-8">
                <div className="text-center">
                  <h3 className="text-sm font-medium text-gray-900">
                    No incomplete invoices to review
                  </h3>
                  <p className="mt-2 text-xs text-gray-500">
                    Get started by creating a new project.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mb-6">
            <h2 className="text-base font-medium tracking-wide leading-7 text-gray-700">
              Needs approval
            </h2>
            <div className="mt-2 overflow-hidden rounded-lg bg-gray-50">
              <div className="px-4 py-5 sm:p-8">
                <div className="text-center">
                  <h3 className="text-sm font-medium text-gray-900">
                    No pending approvals
                  </h3>
                  <p className="mt-2 text-xs text-gray-500">
                    Get started by creating a new project.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          <div className="mb-6">
            <h2 className="text-base font-medium tracking-wide leading-7 text-gray-700">
              Scheduled
            </h2>
            <div className="mt-2 overflow-hidden rounded-lg bg-gray-50">
              <div className="px-4 py-5 sm:p-8">
                <div className="text-center">
                  <h3 className="text-sm font-medium text-gray-900">
                    No scheduled payments
                  </h3>
                  <p className="mt-2 text-xs text-gray-500">
                    Get started by creating a new project.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {recentPayments && recentPayments.length > 0 ? (
          <>
            <div className="mt-3 mb-3">
              <h2 className="text-base font-medium tracking-wide leading-7 text-gray-700">
                Recent Payments
              </h2>
              <div className="-mt-6">
                <SimpleTable
                  columns={columns}
                  data={recentPayments}
                  showPagination={false}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <EmptyState
              title={"No Payments"}
              description={
                "You have not created any cards set up for your Account yet. Click the button below to create your first card!"
              }
            />
          </>
        )}
      </div>
    </>
  );
};

const Breadcrumbs = () => {
  return (
    <>
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item href="/">
          <div className="flex items-center gap-x-3">
            <HomeIcon className="h-5 w-5" />
            <span className="dark:text-white">Home</span>
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Payments</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default Payments;
