import api from "..";

// Extract Data from PDF File
const extractInvoiceData = async (invoice) => {
  const response = await api.post("/invoices", invoice);
  return response.data;
};

const fileService = {
  extractInvoiceData,
};

export default fileService;
