import api from "..";

// Health Check
const invoiceHealthCheck = async () => {
  const response = await api.post("invoices/hello");
  return response.data;
};

// Get All Invoices
const getInvoices = async () => {
  const response = await api.get("stripe/invoices/acct_1MhQhvFLPxDRgaVU", {});
  return response.data;
};
// const getInvoices = async () => {
//   const response = await api.get("/invoices", {
//     params: {
//       populate: "client,project",
//     },
//   });
//   return response.data;
// };

// Create Invoice
const createInvoice = async (invoice) => {
  const response = await api.post("/invoices", invoice);
  return response.data;
};

// Update Invoice
const updateInvoice = async (invoice) => {
  console.log("invoiceInvoice.updateInvoice", invoice);
  const response = await api.patch(`/invoices/${invoice.id}`, invoice);
  return response.data;
};

// Delete Invoice
const deleteInvoice = async (id) => {
  const response = await api.delete(`/invoices/${id}`);
  return response.data;
};

const invoiceInvoice = {
  invoiceHealthCheck,
  getInvoices,
  createInvoice,
  updateInvoice,
  deleteInvoice,
};

export default invoiceInvoice;
