import React, { useEffect, useState, Fragment } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classNames from "classnames";
import { CustomNavbar, CustomSidebar, Header, Sidebar } from "../../components";
import { SidebarProvider, useSidebar } from "../../hooks/useSidebar";
import { Navigate, Outlet } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAuth } from "../../hooks/useAuth";

import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  InboxIcon,
  DocumentCheckIcon,
  WrenchScrewdriverIcon,
  ClipboardDocumentCheckIcon,
  InboxStackIcon,
  QueueListIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

const MainContent = ({ children }) => {
  // const { isSidebarOpen } = useSidebar();

  return (
    <>
      <Outlet />
      {/* <main
        className={classNames(
          "overflow-y-auto relative h-full bg-gray-50 dark:bg-gray-900 px-2 py-2",
          isSidebarOpen ? "lg:ml-16" : "lg:ml-64"
        )}
      >
        <Outlet />
      </main> */}
    </>
  );
};

const navigation = [
  { name: "Dashboard", href: "/", icon: HomeIcon },
  { name: "Clients", href: "/clients", icon: UsersIcon },
  { name: "Projects", href: "/projects", icon: FolderIcon },
  // { name: "Calendar", href: "/calendar", icon: CalendarIcon, current: false },
  // { name: "Documents", href: "/documents", icon: DocumentDuplicateIcon, current: false },
  // { name: "Reports", href: "/reports", icon: ChartPieIcon },
];

const finances = [
  { name: "Cash", href: "/accounts", icon: CurrencyDollarIcon },
  { name: "Cards", href: "/cards", icon: CreditCardIcon },
  {
    name: "Transactions",
    href: "/transactions",
    icon: QueueListIcon,
  },
  { name: "Payments", href: "/payments", icon: CurrencyDollarIcon },
  { name: "Statements", href: "/statements", icon: DocumentTextIcon },
];

const tools = [
  { name: "Invoices", href: "/invoices", icon: InboxIcon },
  // { name: "Proposals", href: "/proposals", icon: InboxStackIcon },
  { name: "Vendors", href: "/vendors", icon: TruckIcon },
  {
    name: "Services",
    href: "/services",
    icon: WrenchScrewdriverIcon,
  },
  { name: "Scheduling", href: "/scheduling", icon: CalendarIcon },
];

const userNavigation = [
  { name: "Your profile", href: "#" },
  { name: "Sign out", href: "#" },
];

export const NavbarSidebar = () => {
  const { user } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  if (!user) {
    // const href = "https://www.hellobundle.com/login";
    // window.location.href = href;
    console.log("user not logged in");
    // return <Navigate to="/login" />;
  }

  return (
    <>
      <ToastContainer closeButton={ToastCloseButton} />
      <div>
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          navigation={navigation}
          finances={finances}
          tools={tools}
        />

        <div className="lg:pl-64">
          <Header
            setSidebarOpen={setSidebarOpen}
            userNavigation={userNavigation}
          />

          <main>
            <MainContent />
            {/* <div className="px-4 sm:px-6 lg:px-8">
              <MainContent />
            </div> */}
          </main>
        </div>
      </div>
      {/* <SidebarProvider>
        <CustomNavbar />
        <div className="flex items-start pt-16">
          <CustomSidebar />
          <div className="flex flex-1 flex-col">
            <div className="flex-1">
              <div className="py-6">
                <div className="mx-auto max-w-8xl px-6 sm:px-8 md:px-10 justify-start">
                  <MainContent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SidebarProvider> */}
    </>
  );
};

export const ToastCloseButton = ({ closeToast }) => (
  <>
    <XMarkIcon className="text-neutral-600 h-5 w-5" onClick={closeToast} />
  </>
);
