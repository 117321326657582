import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Avatar, Breadcrumb } from "flowbite-react";
import { HomeIcon } from "@heroicons/react/24/outline";
import { CustomAvatar, PageHeader, TextLink } from "../components";
import {
  EllipsisVerticalIcon,
  PlusIcon as PlusIconMini,
} from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";

const Client = () => {
  let { clientId } = useParams();
  const client = useSelector((state) =>
    state.client.clients.find((client) => client.id === clientId)
  );

  const filteredProjects = useSelector((state) =>
    state.project.projects.filter((project) => project.client === clientId)
  );

  const projects = [...filteredProjects];

  const sortedProjects = projects.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  return (
    <>
      <header className="relative isolate">
        <div
          className="absolute inset-0 -z-10 overflow-hidden"
          aria-hidden="true"
        >
          <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
            <div
              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#80d9ff] to-[#9089FC]"
              style={{
                clipPath:
                  "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
              }}
            />
          </div>
          <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
        </div>

        <div className="mx-auto max-w-8xl px-4 py-10 sm:px-6 lg:px-12">
          <Breadcrumbs />
          <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
            <div className="flex items-center gap-x-6">
              {/* <img
                src="https://tailwindui.com/img/logos/48x48/tuple.svg"
                alt=""
                className="h-16 w-16 flex-none rounded-full ring-1 ring-gray-900/10"
              /> */}
              <CustomAvatar
                color="neutral"
                size="large"
                firstName="Construction"
                lastName="Inc"
              />
              <h1>
                <div className="text-sm leading-6 text-gray-500">
                  {client.firstName} {client.lastName}
                </div>
                <div className="text-lg font-semibold leading-6 text-gray-900">
                  {client.company.name}
                </div>
              </h1>
            </div>
            <div className="flex items-center gap-x-4 sm:gap-x-6">
              <button
                type="button"
                className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block"
              >
                Copy URL
              </button>
              <a
                href="#"
                className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block"
              >
                Edit
              </a>
              <a
                href="#"
                className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                New Project
              </a>

              <Menu as="div" className="relative sm:hidden">
                <Menu.Button className="-m-3 block p-3">
                  <span className="sr-only">More</span>
                  <EllipsisVerticalIcon
                    className="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(
                            active ? "bg-gray-50" : "",
                            "block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900"
                          )}
                        >
                          Copy URL
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? "bg-gray-50" : "",
                            "block px-3 py-1 text-sm leading-6 text-gray-900"
                          )}
                        >
                          Edit
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </header>
      <div className="mx-auto max-w-8xl px-4 py-8 sm:px-6 lg:px-12">
        <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-4 w-full">
          <div className="col-span-2">
            <div className="mb-4 overflow-hidden rounded-lg bg-white ring-1 ring-gray-900/5 shadow-sm dark:bg-gray-800">
              <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div className="ml-4 mt-2">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Projects
                    </h3>
                  </div>
                  <div className="ml-4 mt-2 flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-full border border-neutral-200 bg-white p-1 text-blue-600 shadow-sm hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-100 focus:ring-offset-2"
                      // onClick={onSubmit}
                    >
                      <PlusIconMini className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div>
                {projects.length > 0 ? (
                  <>{/* <TaskList tasks={tasks} /> */}</>
                ) : (
                  <>
                    <div className="px-4 py-5 sm:px-6">
                      <div className="text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            vectorEffect="non-scaling-stroke"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                          />
                        </svg>
                        <h3 className="mt-2 text-sm font-medium text-gray-900">
                          No projects created yet
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Get started by creating a{" "}
                          <span>
                            <TextLink
                              text="new project"
                              className={"text-sm"}
                              onClick={() => console.log("Create Task!")}
                            />
                            .
                          </span>
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="mb-4 overflow-hidden rounded-lg bg-white ring-1 ring-gray-900/5 shadow-sm dark:bg-gray-800">
              <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div className="ml-4 mt-2">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Invoices
                    </h3>
                  </div>
                  <div className="ml-4 mt-2 flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-full border border-neutral-200 bg-white p-1 text-blue-600 shadow-sm hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-100 focus:ring-offset-2"
                      // onClick={onSubmit}
                    >
                      <PlusIconMini className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div>
                {projects.length > 0 ? (
                  <>{/* <TaskList tasks={tasks} /> */}</>
                ) : (
                  <>
                    <div className="px-4 py-5 sm:px-6">
                      <div className="text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            vectorEffect="non-scaling-stroke"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                          />
                        </svg>
                        <h3 className="mt-2 text-sm font-medium text-gray-900">
                          No invoices for this client yet
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Get started by creating an{" "}
                          <span>
                            <TextLink
                              text="invoice"
                              className={"text-sm"}
                              onClick={() => console.log("Create Invoice!")}
                            />
                            .
                          </span>
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-span-full sm:col-span-1">
            {/* Additional Info */}
            <div className="mb-4 rounded-lg bg-white p-2 shadow dark:bg-gray-800 sm:p-4 xl:p-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-2">
                Additional Info
              </h3>

              <dl>
                <dt className="text-sm font-medium text-gray-500 dark:text-white">
                  Company Name
                </dt>
                <dd className="mb-3 mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">Acme, Inc.</span>
                  <span className="ml-4 flex-shrink-0">
                    <button
                      type="button"
                      className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Update
                    </button>
                  </span>
                </dd>
                <dt className="text-sm font-medium text-gray-500 dark:text-white">
                  Email
                </dt>
                <dd className="mb-3 mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">helene@company.com</span>
                  <span className="ml-4 flex-shrink-0">
                    <button
                      type="button"
                      className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Update
                    </button>
                  </span>
                </dd>

                <dt className="text-sm font-medium text-gray-500 dark:text-white">
                  Phone Number
                </dt>

                <dd className="mb-3 mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">+1-234-567-8901</span>
                  <span className="ml-4 flex-shrink-0">
                    <button
                      type="button"
                      className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Update
                    </button>
                  </span>
                </dd>

                <dt className="text-sm font-medium text-gray-500 dark:text-white">
                  Address
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">123 Main Ave. #33</span>
                  <span className="ml-4 flex-shrink-0">
                    <button
                      type="button"
                      className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Update
                    </button>
                  </span>
                </dd>
                <dd className="mb-3 text-sm text-gray-900 dark:text-gray-400">
                  New York, NY 12345
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Breadcrumbs = () => {
  return (
    <>
      <Breadcrumb className="mb-6">
        <Breadcrumb.Item href="/">
          <div className="flex items-center gap-x-3">
            <HomeIcon className="h-5 w-5" />
            <span className="dark:text-white">Home</span>
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/clients">Clients</Breadcrumb.Item>
        <Breadcrumb.Item>Client Detail</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default Client;
