import React, { Fragment, useState } from "react";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import {
  ArrowRightIcon,
  Bars3Icon,
  CalendarIcon,
  ChartBarIcon,
  CheckIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import logo from "../../assets/logoBlue.png";
import GetAccountName from "../../utils/GetAccountName";
import bundleIcon from "../../assets/images/bundle-circle-icon.png";
import bundleIconAlt from "../../assets/images/bundle-circle-icon-alt.png";
import { useDispatch, useSelector } from "react-redux";
import { SelectAccount } from "../atoms/SelectAccount";
import { useForm } from "react-hook-form";
import { current } from "@reduxjs/toolkit";
import { PlaidLink } from "../../components";
import { DevTool } from "@hookform/devtools";
import StringToNumber from "../../utils/StringToNumber";
import { createBookPayment } from "../../features/unit/unitSlice";
import FormatCurrency from "../../utils/FormatCurrency";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// const formSteps = [
//   { name: "Transfer details", step: 1, status: "" },
//   { name: "Review", step: 2, status: "" },
// ];

export const TransferMoney = () => {
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});

  const { organization } = useSelector((state) => state.organization);

  const { user } = useSelector((state) => state.auth);

  const {
    handleSubmit,
    register,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: "",
      transferTo: {},
      transferFrom: {},
    },
  });

  const onPrev = (data) => {
    setFormData({ ...formData, ...data });
    setCurrentStep(currentStep - 1);
  };

  const onNext = (data) => {
    console.log("onNext data", data);
    // setFormData({ ...formData, ...data });
    setCurrentStep(currentStep + 1);
  };

  const onSubmit = (data) => {
    console.log("onSubmit data", data);
    // setFormData({ ...formData, ...data });

    const transferFrom = getValues("transferFrom");
    const transferTo = getValues("transferTo");
    const amount = getValues("amount");
    console.log("transferFrom", transferFrom);

    const request = {
      userId: user.id,
      organizationId: organization.id,
      fromAccountId: transferFrom.id,
      fromAccountType: transferFrom.type,
      toAccountId: transferTo.id,
      toAccountType: transferTo.type,
      amount: StringToNumber(amount),
      description: "Frontend Book Payment",
    };

    console.log("request", request);

    dispatch(createBookPayment(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        console.log(res);
      }
    });
  };

  const formSteps = [
    {
      id: 1,
      step: 1,
      name: "Transfer details",
      component: <TransferForm register={register} setValue={setValue} />,
    },
    {
      id: 2,
      step: 2,
      name: "Review",
      component: (
        <ReviewTransfer
          register={register}
          setValue={setValue}
          transferFrom={getValues("transferFrom")}
          transferTo={getValues("transferTo")}
          amount={getValues("amount")}
        />
      ),
    },
  ];

  return (
    <>
      <div className="h-full">
        <div>
          <FormSidebar formSteps={formSteps} currentStep={currentStep}>
            <div className="overflow-visible sm:mx-auto sm:w-full sm:max-w-xl p4">
              {/* Header */}
              <div className="px-4 pt-7 sm:px-6">
                <h1 className="text-center text-4xl font-normal tracking-wide leading-tight text-neutral-800">
                  Transfer between accounts
                </h1>
              </div>
              {/* Body */}
              <div className="px-4 py-10 sm:px-6">
                {formSteps[currentStep - 1].component}
              </div>
              {/* Footer */}
              <div className="px-4 pb-6 sm:px-6">
                <div className="flex justify-start">
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-2 rounded-full bg-blue-600 py-2.5 px-7 text-sm font-medium tracking-wider text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    // onClick={
                    //   currentStep === formSteps[currentStep - 1]
                    //     ? handleSubmit(onNext)
                    //     : handleSubmit(onSubmit)
                    // }
                    onClick={() => {
                      if (currentStep === formSteps.length) {
                        handleSubmit(onSubmit)();
                      } else {
                        handleSubmit(onNext)();
                      }
                    }}
                  >
                    {/* {currentStep === formSteps[currentStep - 1]
                      ? "Next"
                      : " Submit"} */}
                    {currentStep === formSteps.length ? "Submit" : "Next"}
                    <ChevronRightIcon
                      className="-mr-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </FormSidebar>
        </div>
      </div>
      <DevTool control={control} />
    </>
  );
};

export const TransferForm = ({ register, setValue }) => {
  return (
    <>
      <div className="space-y-8">
        <div>
          <label htmlFor="amount" className="sr-only">
            Amount
          </label>
          <div className="relative mt-2">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-2xl">$</span>
            </div>
            <input
              type="text"
              name="price"
              id="price"
              className="peer block w-full border-0 py-1.5 pl-10 pr-12 text-gray-900  placeholder:text-gray-400 sm:text-5xl focus:ring-0 sm:leading-6"
              placeholder="0.00"
              aria-describedby="price-currency"
              {...register("amount")}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                USD
              </span>
            </div>
            <div
              className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-blue-600"
              aria-hidden="true"
            />
          </div>
        </div>
        <div>
          <SelectAccount
            label={"Transfer from"}
            name={"transferFrom"}
            setValue={setValue}
          />
        </div>
        <div>
          <SelectAccount
            label={"Transfer to"}
            name={"transferTo"}
            setValue={setValue}
          />
        </div>
      </div>
    </>
  );
};

export const ReviewTransfer = ({
  register,
  setValue,
  transferFrom,
  transferTo,
  amount,
}) => {
  const { accounts } = useSelector((state) => state.unit);

  return (
    <>
      <div className="space-y-8">
        <div>
          <label htmlFor="amount" className="sr-only">
            Amount
          </label>
          <div className="relative mt-2">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-2xl">$</span>
            </div>
            <span className="peer block w-full border-0 py-1.5 pl-10 pr-12 text-gray-900  placeholder:text-gray-400 sm:text-5xl focus:ring-0 sm:leading-6">
              {amount}
            </span>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                USD
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="block mb-2 text-sm font-medium tracking-wide text-gray-900 dark:text-white">
            Transfer from:
          </div>
          <div className="relative mt-1">
            <span className="flex items-center">
              <img
                src={
                  transferFrom.attributes.tags?.purpose === "checking"
                    ? bundleIcon
                    : bundleIconAlt
                }
                alt=""
                className="h-9 w-9 flex-shrink-0 rounded-full"
              />
              <div className="min-w-0 flex-1">
                <div className="ml-3 block truncate">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-base">
                    {GetAccountName(accounts, transferFrom.id)}
                  </p>
                  <p className="truncate text-sm text-gray-500">
                    {FormatCurrency(transferFrom.attributes.balance / 100)}
                  </p>
                </div>
              </div>
            </span>
          </div>
        </div>
        <div>
          <div className="block mb-2 text-sm font-medium tracking-wide text-gray-900 dark:text-white">
            Transfer to:
          </div>
          <div className="relative mt-1">
            <span className="flex items-center">
              <img
                src={
                  transferTo.attributes.tags?.purpose === "checking"
                    ? bundleIcon
                    : bundleIconAlt
                }
                alt=""
                className="h-9 w-9 flex-shrink-0 rounded-full"
              />
              <div className="min-w-0 flex-1">
                <div className="ml-3 block truncate">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-base">
                    {GetAccountName(accounts, transferTo.id)}
                  </p>
                  <p className="truncate text-sm text-gray-500">
                    {FormatCurrency(transferTo.attributes.balance / 100)}
                  </p>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export const FormSidebar = ({ children, formSteps, currentStep }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // console.log(formSteps, currentStep);
  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img className="h-8 w-auto" src={logo} alt="Bundle" />
                    </div>
                    <nav
                      className="mt-5 flex-1 space-y-1 bg-white px-2"
                      aria-label="Progress"
                    >
                      <ol className="space-y-6 overflow-hidden">
                        {formSteps.map((step, stepIdx) => (
                          <li
                            key={step.name}
                            className={classNames(
                              stepIdx !== formSteps.length - 1 ? "" : "",
                              "relative"
                            )}
                          >
                            {stepIdx < currentStep ? (
                              <>
                                <a href={step.step} className="group">
                                  <span className="flex items-start">
                                    <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
                                      <CheckCircleIcon
                                        className="h-full w-full text-blue-600 group-hover:text-blue-800"
                                        aria-hidden="true"
                                      />
                                    </span>
                                    <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                      {step.name}
                                    </span>
                                  </span>
                                </a>
                              </>
                            ) : (
                              (stepIdx = currentStep ? (
                                <>
                                  <a
                                    href={step.step}
                                    className="flex items-start"
                                    aria-current="step"
                                  >
                                    <span
                                      className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                                      aria-hidden="true"
                                    >
                                      <span className="absolute h-4 w-4 rounded-full bg-blue-200" />
                                      <span className="relative block h-2 w-2 rounded-full bg-blue-600" />
                                    </span>
                                    <span className="ml-3 text-sm font-medium text-blue-600">
                                      {step.name}
                                    </span>
                                  </a>
                                </>
                              ) : (
                                <a href={step.step} className="group">
                                  <div className="flex items-start">
                                    <div
                                      className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                                      aria-hidden="true"
                                    >
                                      <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                                    </div>
                                    <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                      {step.name}
                                    </p>
                                  </div>
                                </a>
                              ))
                            )}
                          </li>
                        ))}
                      </ol>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col bg-white">
            <div className="flex flex-1 flex-col overflow-y-auto pt-8 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <img className="h-8 w-auto" src={logo} alt="Bundle" />
              </div>
              <div className="py-12 px-4">
                <nav
                  className="mt-5 flex-1 space-y-1 bg-white px-2"
                  aria-label="Progress"
                >
                  <ol className="space-y-6 overflow-hidden">
                    {formSteps.map((step, stepIdx) => (
                      <li
                        key={step.name}
                        className={classNames(
                          stepIdx !== formSteps.length - 1 ? "" : "",
                          "relative"
                        )}
                      >
                        {step.step < currentStep ? (
                          <>
                            <a href={step.step} className="group">
                              <span className="flex items-start">
                                <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
                                  <CheckCircleIcon
                                    className="h-full w-full text-blue-600 group-hover:text-blue-800"
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                  {step.name}
                                </span>
                              </span>
                            </a>
                          </>
                        ) : step.step === currentStep ? (
                          <>
                            <a
                              href={step.step}
                              className="flex items-start"
                              aria-current="step"
                            >
                              <span
                                className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                                aria-hidden="true"
                              >
                                <span className="absolute h-4 w-4 rounded-full bg-blue-200" />
                                <span className="relative block h-2 w-2 rounded-full bg-blue-600" />
                              </span>
                              <span className="ml-3 text-sm font-medium text-blue-600">
                                {step.name}
                              </span>
                            </a>
                          </>
                        ) : (
                          <a href={step.step} className="group">
                            <div className="flex items-start">
                              <div
                                className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                                aria-hidden="true"
                              >
                                <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                              </div>
                              <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                {step.name}
                              </p>
                            </div>
                          </a>
                        )}
                      </li>
                    ))}
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col lg:pl-64">
          <div className="sticky top-0 z-10 bg-white pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">
            <div className="py-6">
              {/* <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-2xl font-medium text-gray-900 text-center">
                  Transfer between your accounts
                </h1>
              </div> */}
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8">
                <div className="overflow-visible sm:mx-auto sm:w-full sm:max-w-3xl">
                  {children}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
