import { Label, TextInput } from "flowbite-react";
import React, { Fragment, forwardRef } from "react";
import { Controller } from "react-hook-form";

export const CustomTextInput = forwardRef(
  (
    {
      key,
      type,
      id,
      name,
      label,
      placeholderTx,
      helperTx,
      // React Hook form
      control,
      options,
      rules,
      defaultValue,
      errors,
      pattern,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <Label htmlFor={name}>{label}</Label>
        <div className="mt-1">
          <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { value, onChange } }) => {
              return (
                <TextInput
                  ref={ref}
                  type={type}
                  id={id}
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={placeholderTx}
                  color={errors && "failure"}
                  helperText={
                    <Fragment>
                      <span>{errors && errors.message}</span>
                    </Fragment>
                  }
                  {...rest}
                />
              );
            }}
          />
        </div>
      </>
    );
  }
);
