import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store, persistor } from "./app/store";
import App from "./App";
import "./index.css";
import { PersistGate } from "redux-persist/integration/react";
// import { StytchB2BProvider } from "@stytch/react/dist/b2b";
// import { StytchB2BHeadlessClient } from "@stytch/vanilla-js/dist/b2b";

import { StytchB2BProvider } from "@stytch/react/b2b";
import { StytchB2BHeadlessClient } from "@stytch/vanilla-js/dist/b2b";

const stytch = new StytchB2BHeadlessClient(
  "public-token-test-2af4189f-0a16-4466-9913-195ba5456b15"
);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StytchB2BProvider stytch={stytch}>
          <App />
        </StytchB2BProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
