import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";
import { Breadcrumb, Button, Modal } from "flowbite-react";
import {
  CustomTextInput,
  CustomTextArea,
  CustomListbox,
  SimpleTable,
  EmptyState,
} from "../components";
import {
  ArrowUpTrayIcon,
  EyeIcon,
  HomeIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { getProjects } from "../features/project/projectSlice";
import FormatDate from "../utils/FormatDate";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import FormatProperCase from "../utils/FormatProperCase";
import { PlusSmallIcon } from "@heroicons/react/20/solid";

const statuses = {
  active: "text-green-400 bg-green-400/10",
  error: "text-rose-400 bg-rose-400/10",
};

const Projects = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper();

  const { projects } = useSelector((state) => state.project);

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  const handleEditClick = (projectId) => {
    console.log("View Project");
    navigate(`/projects/${projectId}`);
  };

  const handleDeleteClick = (projectId) => {
    console.log("Delete Project");
  };

  const columns = React.useMemo(
    () => [
      // TODO: Convert to accessor column to sort on Project Name
      columnHelper.display({
        id: "project",
        header: () => "Project",
        cell: (props) => (
          <>
            <div className="flex items-center">
              <span
                className={classNames(
                  "bg-blue-400",
                  "h-2.5 w-2.5 flex-shrink-0 rounded-full"
                )}
                aria-hidden="true"
              />
              <div className="ml-4">
                <div className="font-medium text-gray-900">
                  {props.row.original.name}
                </div>
                <div className="mt-1 text-gray-500">
                  {props.row.original.client.company.name}
                </div>
              </div>
            </div>
          </>
        ),
      }),
      // TODO: Convert to accessor column to sort on Project Address
      columnHelper.display({
        id: "location",
        header: () => "Location",
        cell: (props) => (
          <>
            <div className="text-sm text-gray-500">123 Main Ave.</div>
            <div className="mt-1 text-sm text-gray-500">New York, NY 12345</div>
          </>
        ),
      }),
      columnHelper.accessor("startDate", {
        header: "Start Date",
        cell: (props) => <span>{FormatDate(props.getValue())}</span>,
      }),
      columnHelper.accessor("amountBudgeted", {
        header: "Budget",
        cell: (props) => <span>{props.getValue() || "$0.00"}</span>,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (props) => (
          <>
            <div className="flex">
              <div
                className={classNames(
                  statuses[props.getValue()],
                  "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                )}
              >
                {FormatProperCase(props.getValue())}
              </div>
            </div>
          </>
        ),
      }),
      columnHelper.accessor("id", {
        header: () => (
          <>
            <span className="sr-only">Actions</span>
          </>
        ),
        cell: (props) => (
          <>
            <div className="flex flex-row">
              <EyeIcon
                onClick={() => handleEditClick(`${props.getValue()}`)}
                className="h-5 w-5 text-neutral-600 hover:text-neutral-900 mr-2"
              />
              <TrashIcon
                onClick={() => handleDeleteClick(`${props.getValue()}`)}
                className="h-5 w-5 text-neutral-600 hover:text-red-600"
              />
            </div>
          </>
        ),
        maxSize: 16,
      }),
    ],
    []
  );

  return (
    <>
      <div className="mx-auto max-w-8xl px-4 py-8 sm:px-6 lg:px-12">
        <Breadcrumbs />
        <header>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold tracking-wide leading-6 text-gray-900">
                Projects
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the users in your account including their name,
                title, email and role.
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <AddProjectModal />
            </div>
          </div>
        </header>
        {/* Table */}
        {projects && projects.length > 0 ? (
          <>
            <SimpleTable
              columns={columns}
              data={projects}
              showPagination={false}
            />
          </>
        ) : (
          <>
            <EmptyState
              title={"No Projects"}
              description={
                "You have not created any cards set up for your Account yet. Click the button below to create your first card! Need to reate a reusable component for custom SVG Icons"
              }
            />
          </>
        )}
      </div>
    </>
  );
};

const AddProjectModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { clients } = useSelector((state) => state.client);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      client: "",
      startDate: "",
      finishDate: "",
      amountBudgeted: "",
      assignedColor: "",
    },
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="ml-auto flex items-center gap-x-1 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
      >
        <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
        New project
      </button>
      <Modal onClose={() => setIsOpen(false)} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <h1 className="text-xl font-semibold tracking-wide text-gray-900">
            New Project
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="col-span-2">
              <CustomTextInput
                control={control}
                type="text"
                id="name"
                name="name"
                label="Project Name"
                placeholderTx="Kitchen Renovation"
              />
            </div>
            <div>
              <CustomListbox
                label={"Client"}
                items={clients}
                attribute={"firstName"}
                name={"client"}
                setValue={setValue}
              />
            </div>
            <div>
              <CustomTextInput
                control={control}
                type="number"
                id="amountBudgeted"
                name="amountBudgeted"
                label="Budget"
                placeholderTx="$10,000"
              />
            </div>
            <div className="col-span-2">
              <CustomTextArea
                control={control}
                type="text"
                id="description"
                name="description"
                label="Project Description"
                placeholderTx="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit."
                rows={4}
              />
            </div>
            <div className="lg:col-span-2">
              <div className="flex w-full items-center justify-center">
                <label className="flex h-32 w-full cursor-pointer flex-col rounded border-2 border-dashed border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-700">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <ArrowUpTrayIcon className="h-6 w-6 text-gray-300" />
                    <p className="py-1 text-sm text-gray-600 dark:text-gray-500">
                      Upload a file or drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                  <input type="file" className="hidden" />
                </label>
              </div>
            </div>
            <div>
              <div className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Color
              </div>
              <div className="flex items-center space-x-2">
                <button
                  type="button"
                  className="w-6 h-6 bg-purple-500 rounded-md"
                ></button>
                <button
                  type="button"
                  className="w-6 h-6 bg-indigo-500 rounded-md"
                ></button>
                <button
                  type="button"
                  className="w-6 h-6 bg-pink-500 rounded-md"
                ></button>
                <button
                  type="button"
                  className="w-6 h-6 bg-teal-400 rounded-md"
                ></button>
                <button
                  type="button"
                  className="w-6 h-6 bg-green-400 rounded-md"
                ></button>
                <button
                  type="button"
                  className="w-6 h-6 bg-yellow-300 rounded-md"
                ></button>
                <button
                  type="button"
                  className="w-6 h-6 bg-orange-400 rounded-md"
                ></button>
                <button
                  type="button"
                  className="w-6 h-6 bg-red-500 rounded-md"
                ></button>
              </div>
            </div>
            {/* <CustomSelect
              control={control}
              name="testSelect"
              label="Test Select"
              options={["Option1", "Option2", "Option3"]}
            /> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit(onSubmit)}>Add project</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Breadcrumbs = () => {
  return (
    <>
      <Breadcrumb className="mb-6">
        <Breadcrumb.Item href="/">
          <div className="flex items-center gap-x-3">
            <HomeIcon className="h-5 w-5" />
            <span className="dark:text-white">Home</span>
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Projects</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default Projects;
