import React from "react";
import { NavbarSidebar, Root } from "./components";
import theme from "./flowbite-theme";
import { Flowbite } from "flowbite-react";
// import { StytchB2BProvider } from "@stytch/react/b2b";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  // RouterProvider,
  // createBrowserRouter,
  // createRoutesFromElements,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import PrivateRoutes from "./utils/PrivateRoute";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import Clients from "./pages/Clients";
import Client from "./pages/Client";
import FinanceDashboard from "./pages/FinanceDashboard";
import Transactions from "./pages/Transactions";
import Tasks from "./pages/Tasks";
import Settings from "./pages/Settings";
import Cards from "./pages/Cards";
import Statements from "./pages/Statements";
import Payments from "./pages/Payments";
import Invoices from "./pages/Invoices";
import Invoice from "./pages/Invoice";
import { AuthProvider } from "./hooks/useAuth";
import Services from "./pages/Services";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import Authenticate from "./pages/Authenticate";
import Statement from "./pages/Statement";
import Vendors from "./pages/Vendors";
import { FirestoreProvider } from "./hooks/useFirestore";
import { FirebaseStorageProvider } from "./hooks/useStorage";
import { SettingsNew } from "./pages/SettingsNew";
import { InvoiceNew } from "./pages/InvoiceNew";

// const stytch = initStytch(
//   "public-token-test-2af4189f-0a16-4466-9913-195ba5456b15"
// );

function App() {
  const cookies = new Cookies();

  useEffect(() => {
    const cookieUser = cookies.get("user");
    const localUser = JSON.parse(localStorage.getItem("user"));
    const cookieAccessToken = cookies.get("accessToken");
    const cookieRefreshToken = cookies.get("refreshToken");
    const localAccessToken = localStorage.getItem("accessToken");
    const localRefreshToken = localStorage.getItem("refreshToken");

    if (cookieUser && !localUser) {
      localStorage.setItem("user", JSON.stringify(cookieUser));
    }

    if (localUser && !cookieUser) {
      cookies.set("user", localUser);
    }

    if (cookieAccessToken && !localAccessToken) {
      localStorage.setItem("accessToken", cookieAccessToken);
    }

    if (cookieRefreshToken && !localRefreshToken) {
      localStorage.setItem("refreshToken", cookieRefreshToken);
    }

    if (localAccessToken && !cookieAccessToken) {
      cookies.set("accessToken", localAccessToken);
    }

    if (localRefreshToken && !cookieRefreshToken) {
      cookies.set("refreshToken", localRefreshToken);
    }
  }, []);

  return (
    <>
      {/* <StytchProvider stytch={stytch}> */}
      <Flowbite theme={{ theme }}>
        <Router>
          <AuthProvider>
            <FirestoreProvider>
              <FirebaseStorageProvider>
                <Routes>
                  <Route element={<NavbarSidebar />}>
                    <Route path="/" element={<Dashboard />} exact />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="projects/:projectId" element={<Project />} />
                    <Route path="/clients" element={<Clients />} />
                    <Route path="clients/:clientId" element={<Client />} />
                    <Route path="/tasks" element={<Tasks />} />
                    <Route path="accounts" element={<FinanceDashboard />} />
                    <Route path="/cards" element={<Cards />} />
                    <Route path="/transactions" element={<Transactions />} />
                    <Route path="/payments" element={<Payments />} />
                    <Route path="/statements" element={<Statements />} />
                    <Route
                      path="statements/:statementId"
                      element={<Statement />}
                    />
                    <Route path="/invoices" element={<Invoices />} />
                    <Route
                      path="invoices/:invoiceId"
                      element={<InvoiceNew />}
                    />
                    <Route path="/services" element={<Services />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/vendors" element={<Vendors />} />
                  </Route>

                  <Route element={<Root />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/authenticate" element={<Authenticate />} />
                  </Route>
                </Routes>
              </FirebaseStorageProvider>
            </FirestoreProvider>
          </AuthProvider>
        </Router>
      </Flowbite>
      {/* </StytchProvider> */}
    </>
  );
}

export default App;
