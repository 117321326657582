// Slices for Initial State, Actions/Functions
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import vendorService from "./vendorService";

// Create Initial State for Vendor
const initialState = {
  vendors: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getVendors = createAsyncThunk(
  "vendors/get",
  async (_, thunkAPI) => {
    try {
      return await vendorService.getVendors();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createVendor = createAsyncThunk(
  "vendors/create",
  async (vendor, thunkAPI) => {
    try {
      return await vendorService.createVendor(vendor);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateVendor = createAsyncThunk(
  "vendors/update",
  async (request, thunkAPI) => {
    try {
      const { vendorId, body } = request;

      return await vendorService.updateVendor(vendorId, body);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteVendor = createAsyncThunk(
  "vendors/delete",
  async (vendorId, thunkAPI) => {
    try {
      return await vendorService.deleteVendor(vendorId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Slice is collection of Reducers for Redux
export const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    // Ability to dispatch Reset function after Register or Login
    reset: (state) => {
      state.vendors = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Vendors
      .addCase(getVendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendors = action.payload.results;
      })
      .addCase(getVendors.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Create Vendor
      .addCase(createVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createVendor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendors.push(action.payload);
      })
      .addCase(createVendor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Update Vendor
      .addCase(updateVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVendor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendors[
          state.vendors.findIndex((vendor) => vendor.id === action.payload.id)
        ] = action.payload;
      })
      .addCase(updateVendor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Delete Vendor
      .addCase(deleteVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteVendor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendors = state.vendors.filter(
          (vendor) => vendor.id !== action.payload.id
        );
      })
      .addCase(deleteVendor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = vendorSlice.actions;
export default vendorSlice.reducer;
