const FormatDate = (date, options) => {
  const d = new Date(date);

  if (!options) {
    options = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
  }

  return d.toLocaleDateString("en-US", options);
};

export default FormatDate;
