import { Label, TextInput, Textarea } from "flowbite-react";
import React, { Fragment, forwardRef } from "react";
import { Controller } from "react-hook-form";

export const CustomSelect = forwardRef(
  (
    {
      key,
      type,
      id,
      name,
      label,
      placeholderTx = "Select an option",
      helperTx,
      rows,
      // React Hook form
      control,
      options,
      rules,
      defaultValue,
      errors,
      pattern,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        {/* <Label htmlFor={name}>{label}</Label> */}
        <div className="mt-1">
          <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { value, onChange, onBlur } }) => {
              return (
                <>
                  <label
                    htmlFor="location"
                    className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {label}
                  </label>
                  <select
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    {...rest}
                  >
                    {/* {options.map((option) => {
                      if (option.value === "placeholder") {
                        return (
                          <option
                            key={option.value}
                            value={option.value}
                            disabled
                          >
                            {option.label}
                          </option>
                        );
                      } else {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        );
                      }
                    })} */}
                    {/* <option value={options[0].value} disabled>
                      {options[0].label}
                    </option>
                    {options.slice(1).map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))} */}
                    <option value="" disabled>
                      {placeholderTx}
                    </option>
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </>
              );
            }}
          />
        </div>
      </>
    );
  }
);
