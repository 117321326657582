import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";
import { Breadcrumb, Button, Modal } from "flowbite-react";
import {
  ChevronRightIcon,
  CloudArrowUpIcon,
  ExclamationTriangleIcon,
  HomeIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { DevTool } from "@hookform/devtools";
import {
  createVendor,
  deleteVendor,
  updateVendor,
} from "../features/vendor/vendorSlice";
import {
  CustomNumberInput,
  CustomSelect,
  CustomTable,
  CustomTextInput,
  DeleteModal,
  EmptyState,
  PageHeader,
  PhoneInput,
} from "../components";
import { Dialog, Disclosure, Transition } from "@headlessui/react";

const Vendors = () => {
  // Bring in dispatch and columnHelper Hooks
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();

  // Get the services from the store
  const { vendors } = useSelector((state) => state.vendor);

  const [nextPayment, setNextPayment] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);

  const [isShowing, setIsShowing] = useState(false);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const handleDeleteClick = (serviceId) => {
    setIsDeleteOpen(() => !isDeleteOpen);
  };

  const handleDeleteClose = () => {
    setIsDeleteOpen(() => !isDeleteOpen);
  };

  const handleDeleteSubmit = () => {
    dispatch(deleteVendor(selectedVendor.id)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        handleDeleteClose();
      }
    });
  };

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => "Service",
        cell: (info) => info.getValue(),
      }),

      columnHelper.accessor("id", {
        header: () => (
          <>
            <span className="sr-only">Actions</span>
          </>
        ),
        cell: (props) => (
          <>
            <div className="flex flex-row">
              <PencilSquareIcon
                // onClick={(event) => handleEditClick(event, props.row.original)}
                className="h-5 w-5 text-neutral-600 hover:text-neutral-900 mr-2"
              />
              <TrashIcon
                // onClick={() => handleDeleteClick(`${props.getValue()}`)}
                className="h-5 w-5 text-neutral-600 hover:text-red-600"
              />
            </div>
          </>
        ),
        maxSize: 16,
      }),
    ],
    []
  );

  const selectVendor = (vendor) => {
    setSelectedVendor(vendor);
    setIsShowing((isShowing) => !isShowing);
  };

  const handleClose = () => {
    setSelectedVendor(null);
    setIsShowing((isShowing) => !isShowing);
  };

  return (
    <>
      <div className="mx-auto max-w-8xl px-4 py-8 sm:px-6 lg:px-12">
        <Breadcrumbs />
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold tracking-wide leading-6 text-gray-900">
              Vendors
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your account including their name,
              title, email and role.
            </p>
          </div>
          <div className="flex flex-row mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <div className="mr-4">
              <button
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:text-gray-400"
              >
                <CloudArrowUpIcon
                  className="-ml-0.5 h-5 w-5"
                  aria-hidden="true"
                />
                Import
              </button>
            </div>
            <AddVendorModal />
          </div>
        </div>
        {/* <PageHeader
          title="Vendors"
          actionComponent={
            <>
              <div className="mr-2">
                <Button color="light">
                  <div className="flex items-center gap-x-2">
                    <CloudArrowUpIcon className="h-5 w-5" /> Import
                  </div>
                </Button>
              </div>
              <AddVendorModal />
            </>
          }
        /> */}
        {vendors && vendors.length > 0 ? (
          <>
            {/* <div>
              <CustomTable columns={columns} data={vendors} />
            </div> */}
            <Disclosure>
              <div className="mt-8 grid grid-cols-1 gap-6 lg:grid-flow-col lg:grid-cols-8">
                {/* Vendors */}
                <div className="lg:col-span-full lg:col-start-1">
                  <div className="overflow-hidden ring-1 ring-gray-300 rounded-md bg-white shadow-sm">
                    <div className="min-w-full divide-y divide-gray-300 px-6 py-3">
                      <div className="flex flex-row">
                        <div className="w-1/3">
                          <p className="text-sm text-gray-900 font-semibold">
                            Vendor
                          </p>
                        </div>
                        <div className="flex flex-row items-center justify-between w-8/12">
                          <div className="w-5/12">
                            <p className="text-left text-sm text-gray-900 font-semibold">
                              Type
                            </p>
                          </div>
                          <div className="w-5/12">
                            <p className="text-left text-sm text-gray-900 font-semibold">
                              Next Payment
                            </p>
                          </div>
                          <div className="w-2/12">
                            <p className="sr-only">Actions</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="border-t divide-y divide-gray-200">
                      {vendors.map((vendor) => (
                        <Disclosure.Button
                          as={"li"}
                          key={vendor.id}
                          className="px-6 py-4 hover:bg-gray-50"
                          onClick={() => selectVendor(vendor)}
                        >
                          <div className="relative flex items-center">
                            <div className="w-1/3">
                              <p className="text-sm font-medium text-gray-900">
                                {vendor.name}
                              </p>
                            </div>
                            <div className="flex flex-row items-center w-8/12">
                              <div className="w-5/12">
                                <p className="truncate text-sm text-gray-500">
                                  {vendor.type ? vendor.type : "-"}
                                </p>
                              </div>
                              <div className="w-5/12">
                                <p className="truncate text-sm text-gray-500">
                                  {vendor.nextPayment
                                    ? vendor.nextPayment
                                    : "-"}
                                </p>
                              </div>

                              <div className="w-2/12">
                                <div className="flex ml-5 flex-shrink-0  justify-end">
                                  <ChevronRightIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Disclosure.Button>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* Vendor Detail */}

                <Transition
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Disclosure.Panel>
                    {({ close }) => (
                      <>
                        {selectedVendor && (
                          <>
                            <div className="max-w-xs">
                              <div className="divide-y divide-gray-200 overflow-hidden border border-gray-200 rounded-lg bg-white shadow-sm">
                                <div className="px-4 py-5 sm:px-6">
                                  <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                                    <div className="ml-4 mt-2">
                                      <h3 className="text-base font-medium leading-6 text-gray-900">
                                        {selectedVendor.name}
                                      </h3>
                                    </div>
                                    <div className="ml-4 mt-2 flex flex-row flex-shrink-0">
                                      <TrashIcon
                                        onClick={() => handleDeleteClick()}
                                        className="h-5 w-5 text-neutral-600  hover:text-red-600 mr-2"
                                      />
                                      <XMarkIcon
                                        onClick={() => {
                                          close();
                                          setSelectedVendor(null);
                                        }}
                                        className="h-5 w-5 text-neutral-600 hover:text-neutral-900"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="px-4 py-5 sm:p-6">
                                  <div className="flex flex-wrap sm:space-y-0 sm:space-x-3">
                                    <button
                                      type="button"
                                      className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                      Edit
                                    </button>
                                    <button
                                      type="button"
                                      className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:flex-1"
                                    >
                                      Send Payment
                                    </button>
                                  </div>
                                </div>
                                <div className="px-4 py-5 sm:p-6 space-y-8 sm:space-y-6">
                                  {/* Contact Details */}
                                  <div>
                                    <h3 className="text-base font-medium leading-6 text-gray-900 mb-2">
                                      Contact details
                                    </h3>
                                    <dl className="space-y-4 sm:space-y-2">
                                      <div>
                                        <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                                          Phone
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                                          <p>
                                            {`${selectedVendor.phone.countryCode}-${selectedVendor.phone.number}`}
                                          </p>
                                        </dd>
                                      </div>
                                      <div>
                                        <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                                          Email
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                                          <p>{`${selectedVendor.email}`}</p>
                                        </dd>
                                      </div>
                                    </dl>
                                  </div>
                                  {/* Payment Accounts */}
                                  <div>
                                    <h3 className="text-base font-medium leading-6 text-gray-900 mb-2">
                                      Payment accounts
                                    </h3>
                                    <div>
                                      <ul className="mt-2 divide-y divide-gray-200 border-gray-200">
                                        {selectedVendor.achPaymentDetails ? (
                                          <>
                                            <li className="flex items-center justify-between py-3">
                                              <div className="flex items-center">
                                                <p className="text-sm text-gray-500 font-medium">
                                                  ACH
                                                </p>
                                              </div>
                                              <div className="flex flex-row items-center">
                                                <p className="text-sm text-gray-700">
                                                  Ending x
                                                  {selectedVendor.achPaymentDetails.accountNumber.slice(
                                                    -4
                                                  )}
                                                </p>
                                                <PencilSquareIcon
                                                  // onClick={() => handleDeleteClick(`${props.getValue()}`)}
                                                  className="ml-2 h-5 w-5 text-neutral-600 hover:text-neutral-900 self-center"
                                                />
                                              </div>
                                            </li>
                                          </>
                                        ) : (
                                          <>
                                            <li className="flex items-center justify-between py-3">
                                              <div className="flex items-center">
                                                <p className="text-sm text-gray-500">
                                                  ACH
                                                </p>
                                              </div>
                                              <AddPaymentAccount
                                                selectedVendor={selectedVendor}
                                              />
                                            </li>
                                          </>
                                        )}

                                        <li className="flex items-center justify-between py-3">
                                          <div className="flex items-center">
                                            <p className="text-sm text-gray-500">
                                              Domestic Wire
                                            </p>
                                          </div>
                                          <button
                                            type="button"
                                            className="ml-6 rounded-md bg-white text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                          >
                                            Add Account
                                            <span className="sr-only">
                                              {" "}
                                              Add ACH Account
                                            </span>
                                          </button>
                                        </li>
                                        <li className="flex items-center justify-between py-3">
                                          <div className="flex items-center">
                                            <p className="text-sm text-gray-500">
                                              International Wire
                                            </p>
                                          </div>
                                          <button
                                            type="button"
                                            className="ml-6 rounded-md bg-white text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                          >
                                            Add Account
                                            <span className="sr-only">
                                              {" "}
                                              Add ACH Account
                                            </span>
                                          </button>
                                        </li>
                                        <li className="flex items-center justify-between py-3">
                                          <div className="flex items-center">
                                            <p className="text-sm text-gray-500">
                                              Check
                                            </p>
                                          </div>
                                          <button
                                            type="button"
                                            className="ml-6 rounded-md bg-white text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                          >
                                            Add Account
                                            <span className="sr-only">
                                              {" "}
                                              Add ACH Account
                                            </span>
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  {/* Upcoming Payment */}
                                  <div>
                                    <h3 className="text-base font-medium leading-6 text-gray-900 mb-2">
                                      Next payment
                                    </h3>
                                    <div>
                                      {nextPayment ? (
                                        <></>
                                      ) : (
                                        <>
                                          <div className="mt-1 text-sm text-gray-700 sm:col-span-2">
                                            <p>
                                              No processing or future payments
                                              associated with this vendor
                                            </p>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </Disclosure.Panel>
                </Transition>
              </div>
            </Disclosure>
          </>
        ) : (
          <>
            <EmptyState
              title={"No Vendors"}
              description={
                "You have not created any cards set up for your Account yet. Click the button below to create your first card! Need to reate a reusable component for custom SVG Icons"
              }
            />
          </>
        )}
      </div>
      <DeleteModal
        title={"Confirm Delete"}
        description={"Are you sure you want to delete this vendor?"}
        open={isDeleteOpen}
        setOpen={setIsDeleteOpen}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteSubmit}
      />
    </>
  );
};

const AddVendorModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: {
        countryCode: "1",
        number: "",
      },
    },
  });

  const onSubmit = (data) => {
    dispatch(createVendor(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setIsOpen(false);
        reset();
      }
    });
  };

  const onClose = () => {
    setIsOpen(false);
    reset();
  };

  return (
    <>
      <button
        type="button"
        className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        onClick={() => setIsOpen(true)}
      >
        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        Add vendor
      </button>

      <Modal onClose={onClose} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Add new service</strong>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="col-span-2">
              <CustomTextInput
                control={control}
                type="text"
                id="name"
                name="name"
                label="Name"
                placeholderTx="Acme, Inc."
              />
            </div>
            <div className="col-span-2">
              <CustomTextInput
                control={control}
                type="email"
                id="email"
                name="email"
                label="Email Address"
                placeholderTx="billing@acme.com"
              />
            </div>
            <div className="col-span-2">
              <div>
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <label htmlFor="country" className="sr-only">
                      Country
                    </label>
                    <select
                      id="country"
                      name="country"
                      autoComplete="country"
                      className="h-full rounded-md border-transparent bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      {...register("phone.countryCode")}
                    >
                      <option value="1">US</option>
                      <option>CA</option>
                      <option>EU</option>
                    </select>
                  </div>
                  <input
                    type="text"
                    name="phone-number"
                    id="phone-number"
                    className="block w-full rounded-md border-gray-300 pl-16 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="(555) 987-6543"
                    {...register("phone.number")}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit(onSubmit)}>Add service</Button>
        </Modal.Footer>
        <DevTool control={control} placement="top-left" />
      </Modal>
    </>
  );
};

const AddPaymentAccount = ({ selectedVendor }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      paymentType: "",
      name: "",
      routingNumber: "",
      accountNumber: "",
      accountType: "",
    },
  });

  const onSubmit = (data) => {
    console.log(data);

    const request = {
      vendorId: selectedVendor.id,
      body: {
        achPaymentDetails: {
          beneficiaryName: data.name,
          accountNumber: data.accountNumber,
          routingNumber: data.routingNumber,
          accountType: data.accountType,
        },
      },
    };

    console.log(request);

    dispatch(updateVendor(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setIsOpen(false);
        reset();
      }
    });
  };

  const onClose = () => {
    setIsOpen(false);
    reset();
  };

  return (
    <>
      <button
        type="button"
        className="ml-6 rounded-md bg-white text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        onClick={() => setIsOpen(true)}
      >
        Add Account
        <span className="sr-only"> Add ACH Account</span>
      </button>

      <Modal onClose={onClose} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Add payment account</strong>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="col-span-2">
              <CustomSelect
                control={control}
                name="paymentType"
                label="Payment Type"
                placeholderTx="Select a type"
                options={[
                  { value: "ACH", label: "ACH" },
                  { value: "Domestic Wire", label: "Domestic Wire" },
                  { value: "International Wire", label: "International Wire" },
                  { value: "Check", label: "Check" },
                ]}
              />
            </div>
            <div className="col-span-2">
              <CustomTextInput
                control={control}
                type="text"
                id="name"
                name="name"
                label="Beneficiary Name"
                placeholderTx="Acme, Inc."
              />
            </div>
            <div className="col-span-2">
              <CustomTextInput
                control={control}
                type="text"
                id="routingNumber"
                name="routingNumber"
                label="Routing Number"
                placeholderTx="012345678"
              />
            </div>
            <div className="col-span-2">
              <CustomTextInput
                control={control}
                type="text"
                id="accountNumber"
                name="accountNumber"
                label="Account Number"
                placeholderTx="98765432198"
              />
            </div>
            <div className="col-span-2">
              <CustomSelect
                control={control}
                name="accountType"
                label="Account Type"
                placeholderTx="Select a type"
                options={[
                  { value: "Business Checking", label: "Business Checking" },
                  { value: "Business Savings", label: "Business Savings" },
                  { value: "Personal Checking", label: "Personal Checking" },
                  { value: "Personal Savings", label: "Personal Savings" },
                ]}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit(onSubmit)}>Save</Button>
        </Modal.Footer>
        <DevTool control={control} placement="top-left" />
      </Modal>
    </>
  );
};

const Breadcrumbs = () => {
  return (
    <>
      <Breadcrumb className="mb-6">
        <Breadcrumb.Item href="/">
          <div className="flex items-center gap-x-3">
            <HomeIcon className="h-5 w-5" />
            <span className="dark:text-white">Home</span>
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Vendors</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default Vendors;
