import React, { useState, Fragment } from "react";
import { Tab } from "@headlessui/react";
import {
  CustomAvatar,
  CustomListbox,
  CustomTextInput,
  EmptyState,
  PageHeader,
} from "../components";
import {
  BellIcon,
  BuildingOfficeIcon,
  ChatBubbleLeftEllipsisIcon,
  CogIcon,
  CreditCardIcon,
  EnvelopeIcon,
  HomeIcon,
  KeyIcon,
  PlusIcon,
  SquaresPlusIcon,
  TrashIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { Breadcrumb, Button, Modal } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Settings = () => {
  const subNavigation = [
    {
      name: "My Account",
      component: <AccountPanel />,
      icon: UserCircleIcon,
      current: true,
    },
    {
      name: "Organization",
      component: <OrganizationPanel />,
      icon: BuildingOfficeIcon,
      current: false,
    },
    {
      name: "Team",
      component: <TeamPanel />,
      icon: UserGroupIcon,
      current: false,
    },
    // {
    //   name: "Password",
    //   component: <FeedbackPanel />,
    //   icon: KeyIcon,
    //   current: false,
    // },
    {
      name: "Notifications",
      component: <NotificationPanel />,
      icon: BellIcon,
      current: false,
    },
    {
      name: "Billing",
      component: <BillingPanel />,
      icon: CreditCardIcon,
      current: false,
    },
  ];
  return (
    <>
      <div className="mx-auto max-w-8xl px-4 py-8 sm:px-6 lg:px-12">
        <Breadcrumbs />
        <header className="pb-4 sm:pb-6">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold tracking-wide leading-6 text-gray-900">
                Settings
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the users in your account including their name,
                title, email and role.
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
          </div>
        </header>

        <div className="mt-4 overflow-hidden rounded-lg bg-white shadow">
          <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
            <Tab.Group>
              <aside className="py-6 lg:col-span-2">
                <Tab.List className="space-y-1">
                  <h3
                    className="px-3 text-sm font-normal text-gray-500 mb-2"
                    id="settings-tabs-label"
                  >
                    Business Settings
                  </h3>
                  <div className="flex-1 space-y-1 pb-4">
                    {subNavigation.map((item, i) => (
                      <Tab key={i} as={Fragment}>
                        {({ selected }) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              selected
                                ? "bg-blue-50 border-blue-500 text-blue-700 hover:bg-blue-50 hover:text-blue-700"
                                : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                              "group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                selected
                                  ? "text-blue-500 group-hover:text-blue-500"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            <span className="truncate">{item.name}</span>
                          </a>
                        )}
                      </Tab>
                    ))}
                  </div>
                  <h3
                    className="px-3 text-sm font-normal text-gray-500 mb-2"
                    id="settings-tabs-label"
                  >
                    Experience
                  </h3>
                  <div>
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        <a
                          // href={item.href}
                          className={classNames(
                            selected
                              ? "bg-blue-50 border-blue-500 text-blue-700 hover:bg-blue-50 hover:text-blue-700"
                              : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                            "group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                          )}
                        >
                          <ChatBubbleLeftEllipsisIcon
                            className={classNames(
                              selected
                                ? "text-blue-500 group-hover:text-blue-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          <span className="truncate">Give Feedback</span>
                        </a>
                      )}
                    </Tab>
                  </div>
                </Tab.List>
              </aside>

              <Tab.Panels className="py-6 px-4 sm:p-6 lg:pb-8 lg:col-span-10">
                {Object.values(subNavigation).map((item, i) => (
                  <Tab.Panel key={i} as={Fragment}>
                    {item.component}
                  </Tab.Panel>
                ))}
                <Tab.Panel>
                  <div className="py-6 px-4 sm:p-6 lg:pb-8">
                    <FeedbackPanel />
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    </>
  );
};

const TeamMember = ({ user, imgUrl, name, email, role }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const parseName = (name) => {
    const parts = name.split(" ");
    const firstName = parts[0];
    const lastName = parts[parts.length - 1];
    return { firstName, lastName };
  };

  const { firstName, lastName } = parseName(name);

  const roles = [
    { id: 1, name: "Owner", value: "owner" },
    { id: 2, name: "Admin", value: "admin" },
    { id: 3, name: "User", value: "user" },
  ];

  const initialValue = roles.find((r) => r.value === role);

  const closeDeleteModal = () => {
    // setSelectedService(null);
    setShowDeleteModal(() => !showDeleteModal);
  };

  const onDelete = () => {
    // dispatch(deleteUser(selectedUser.id)).then((res) => {
    //   if (res.meta.requestStatus === "fulfilled") {
    //     closeDeleteModal();
    //   }
    // });
    setShowDeleteModal(() => !showDeleteModal);
    setSelectedUser(user);
  };

  return (
    <>
      <div className="block">
        <div className="flex items-center px-4 py-4 sm:px-6">
          {/* First Column */}
          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="flex-shrink-0">
              <CustomAvatar
                // imageSource={imgUrl}
                firstName={firstName}
                lastName={lastName}
                size={"normal"}
                color={"black"}
              />
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="truncate text-sm font-medium text-blue-600">
                  {name}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <EnvelopeIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="truncate">{email}</span>
                </p>
              </div>
            </div>
          </div>

          {/* Second Column */}
          <div className="mt-2 sm:flex sm:justify-between sm:items-center">
            <div className="sm:flex">
              <CustomListbox
                items={roles}
                attribute={"name"}
                name={"role"}
                setValue={() => {}}
                initialValue={initialValue}
              />
            </div>
            <div className="ml-5 flex-shrink-0">
              <TrashIcon
                className="h-5 w-5 text-gray-400 hover:text-red-500"
                aria-hidden="true"
                onClick={() => {
                  setSelectedUser(user);
                  setShowDeleteModal(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <DeleteModal
        title={"Remove Member"}
        description={`Are you sure you want to remove ${name} from your organization?`}
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onClose={closeDeleteModal}
        onConfirm={onDelete}
        confirmText={"Remove Member"}
        cancelText={"Keep Member"}
      /> */}
    </>
  );
};

const TeamPanel = () => {
  const [openForm, setOpenForm] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const { organization } = useSelector((state) => state.organization);

  const users = useSelector(
    (state) => state.organization.organization.platformUsers
  );

  const inviteUser = () => {
    console.log("Invite User Form");
    setOpenForm(!openForm);
  };

  const onSubmit = (data) => {
    const request = {
      name: `${data.firstName} ${data.lastName}`,
      email: data.email,
      role: data.role || "user",
      organization: organization.id,
    };
    console.log(request);

    // Dispatch Action - Invite User
  };

  return (
    <>
      <div className="px-4 sm:px-6">
        {/* Panel Header */}
        <div className="pb-4">
          <div className="bg-white py-5">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-4">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Team Members
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit quam
                  corrupti consectetur.
                </p>
              </div>
              <div className="ml-4 mt-4 flex-shrink-0">
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  onClick={inviteUser}
                >
                  Invite People
                </button>
              </div>
            </div>
          </div>
          {/* Current Users List */}
          <div className="overflow-visible bg-white shadow sm:rounded-md">
            <ul
              role="list"
              className="border border-gray-100 divide-y divide-gray-200"
            >
              {users.map((user) => (
                <li key={user.id}>
                  <TeamMember
                    id={user.id}
                    name={user.name}
                    email={user.email}
                    role={user.role}
                    user={user}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* Invites List */}
        <div className="pb-4">
          <div className="bg-white py-5">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Pending Invites
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Lorem ipsum dolor sit amet consectetur adipisicing elit quam
              corrupti consectetur.
            </p>
          </div>
          {/* Pending Invite List */}
          <div>
            <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 pb-16 -pt-16">
              <EmptyState
                title={"No Pending Invites"}
                description={
                  "Invite team members by selecting Invite People, they will receive an email with instructions."
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddUserModal = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const { organization } = useSelector((state) => state.organization);

  const roles = [
    {
      id: "user",
      name: "User",
      description:
        "Users cannot manage other users, manage financial data, or manage organization settings. Users can access all features of the platform.",
    },
    {
      id: "admin",
      name: "Admin",
      description:
        "Administrators can do everything, including managing users and deleting current administrators",
    },
  ];

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      role: "",
    },
  });

  const onSubmit = (data) => {
    console.log(data);

    const request = {
      name: `${data.firstName} ${data.lastName}`,
      email: data.email,
      role: data.role || "user",
      organization: organization.id,
    };
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>
        <div className="flex items-center gap-x-3">
          <PlusIcon className="h-4 w-4" /> Invite new user
        </div>
      </Button>
      <Modal onClose={() => setIsOpen(false)} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Invite new user</strong>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="col-span-2">
              <CustomTextInput
                control={control}
                type="text"
                id="email"
                name="email"
                label="Email Address"
                placeholderTx="user@youremail.com"
              />
            </div>
            <div>
              <CustomTextInput
                control={control}
                type="text"
                id="firstName"
                name="firstName"
                label="Contact First Name"
                placeholderTx="John"
              />
            </div>
            <div>
              <CustomTextInput
                control={control}
                type="text"
                id="lastName"
                name="lastName"
                label="Contact Last Name"
                placeholderTx="Smith"
              />
            </div>
            <div>
              <CustomTextInput
                control={control}
                type="text"
                id="email"
                name="email"
                label="Email"
                placeholderTx="john@acme.com"
              />
            </div>
            <div>
              <CustomTextInput
                control={control}
                type="text"
                id="phoneNumber"
                name="phoneNumber.number"
                label="Phone Number"
                placeholderTx="555-555-5555"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit(onSubmit)}>Invite user</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// const ProfilePanel = () => {
//   return (
//     <>
//       <div>Profile Panel</div>
//     </>
//   );
// };

const AccountPanel = () => {
  const user = {
    name: "Debbie Lewis",
    handle: "deblewis",
    email: "debbielewis@example.com",
    imageUrl:
      "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80",
  };

  return (
    <>
      <form
        className="divide-y divide-gray-200 lg:col-span-9"
        action="#"
        method="POST"
      >
        {/* Profile section */}
        <div className="py-6 px-4 sm:p-6 lg:pb-8">
          <div>
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              Profile
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              This information will be displayed publicly so be careful what you
              share.
            </p>
          </div>

          <div className="mt-6 flex flex-col lg:flex-row">
            <div className="flex-grow space-y-6">
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Username
                </label>
                <div className="mt-2 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                    workcation.com/
                  </span>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    autoComplete="username"
                    className="block w-full min-w-0 flex-grow rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                    defaultValue={user.handle}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  About
                </label>
                <div className="mt-2">
                  <textarea
                    id="about"
                    name="about"
                    rows={3}
                    className="mt-1 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:py-1.5 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Brief description for your profile. URLs are hyperlinked.
                </p>
              </div>
            </div>

            <div className="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-shrink-0 lg:flex-grow-0">
              <p
                className="text-sm font-medium leading-6 text-gray-900"
                aria-hidden="true"
              >
                Photo
              </p>
              <div className="mt-2 lg:hidden">
                <div className="flex items-center">
                  <div
                    className="inline-block h-12 w-12 flex-shrink-0 overflow-hidden rounded-full"
                    aria-hidden="true"
                  >
                    <img
                      className="h-full w-full rounded-full"
                      src={user.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="relative ml-5">
                    <input
                      id="mobile-user-photo"
                      name="user-photo"
                      type="file"
                      className="peer absolute h-full w-full rounded-md opacity-0"
                    />
                    <label
                      htmlFor="mobile-user-photo"
                      className="pointer-events-none block rounded-md py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 peer-hover:ring-gray-400 peer-focus:ring-2 peer-focus:ring-sky-500"
                    >
                      <span>Change</span>
                      <span className="sr-only"> user photo</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="relative hidden overflow-hidden rounded-full lg:block">
                <img
                  className="relative h-40 w-40 rounded-full"
                  src={user.imageUrl}
                  alt=""
                />
                <label
                  htmlFor="desktop-user-photo"
                  className="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-75 text-sm font-medium text-white opacity-0 focus-within:opacity-100 hover:opacity-100"
                >
                  <span>Change</span>
                  <span className="sr-only"> user photo</span>
                  <input
                    type="file"
                    id="desktop-user-photo"
                    name="user-photo"
                    className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                  />
                </label>
              </div>
            </div>
          </div>

          <div className="mt-6 grid grid-cols-12 gap-6">
            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                First name
              </label>
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Last name
              </label>
              <input
                type="text"
                name="last-name"
                id="last-name"
                autoComplete="family-name"
                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="col-span-12">
              <label
                htmlFor="url"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                URL
              </label>
              <input
                type="text"
                name="url"
                id="url"
                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

const BillingPanel = () => {
  return (
    <>
      <div>Profile Panel</div>
    </>
  );
};

const OrganizationPanel = () => {
  return (
    <>
      <div>Organization Panel</div>
    </>
  );
};

const NotificationPanel = () => {
  return (
    <>
      <div>Profile Panel</div>
    </>
  );
};

const FeedbackPanel = () => {
  return (
    <>
      <div>Profile Panel</div>
    </>
  );
};

const Breadcrumbs = () => {
  return (
    <>
      <Breadcrumb className="mb-6">
        <Breadcrumb.Item href="/">
          <div className="flex items-center gap-x-3">
            <HomeIcon className="h-5 w-5" />
            <span className="dark:text-white">Home</span>
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Settings</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default Settings;
