import api from "../../features";

// Get All Vendors
const getVendors = async () => {
  const response = await api.get("/vendors");
  return response.data;
};

// Create Vendor
const createVendor = async (vendor) => {
  const response = await api.post("/vendors", vendor);
  return response.data;
};

// Update Vendor
const updateVendor = async (vendorId, updateRequest) => {
  console.log("updateVendor", vendorId, updateRequest);

  const response = await api.patch(`/vendors/${vendorId}`, updateRequest);
  return response.data;
};

// Delete Vendor
const deleteVendor = async (vendorId) => {
  const response = await api.delete(`/vendors/${vendorId}`);
  return response.data;
};

const vendorService = {
  getVendors,
  createVendor,
  updateVendor,
  deleteVendor,
};

export default vendorService;
