import React from "react";
import { Button, Table } from "flowbite-react";
import { TextLink } from "../../components";
import clsx from "clsx";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  DocumentTextIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const CustomTable = ({
  data,
  columns,
  showSearch = true,
  showPagination = true,
}) => {
  const [sorting, setSorting] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [grouping, setGrouping] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    state: {
      sorting,
      grouping,
      globalFilter,
      columnVisibility,
      rowSelection,
    },
    // defaultColumn,
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  });

  return (
    <>
      <div className="mt-8">
        {showSearch && (
          <>
            <div className="flex justify-start gap-x-2">
              <DebouncedInput
                value={globalFilter ?? ""}
                onChange={(value) => setGlobalFilter(String(value))}
                placeholder="Search records..."
              />
            </div>
          </>
        )}

        <div className="pt-8 flex flex-col">
          <div className="overflow-x-auto rounded-lg ring-1 ring-black ring-opacity-5">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow sm:rounded-lg">
                <Table
                  className="min-w-full divide-y divide-gray-200 dark:divide-gray-600"
                  {...{
                    style: {
                      width: table.getCenterTotalSize(),
                    },
                  }}
                >
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Table.Head
                      key={headerGroup.id}
                      className="bg-gray-50 dark:bg-gray-700"
                    >
                      {headerGroup.headers.map((header) => (
                        <Table.HeadCell key={header.id}>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "group inline-flex cursor-pointer select-none"
                                : "group inline-flex",
                              onClick: header.column.getToggleSortingHandler(),
                              colSpan: header.colSpan,
                              style: {
                                width: header.getSize(),
                              },
                            }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                            {{
                              asc: (
                                <span className="ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                  <ChevronUpIcon
                                    className="ml-2 h-4 w-4 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              ),
                              desc: (
                                <span className="ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                  <ChevronDownIcon
                                    className="ml-2 h-4 w-4 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                </span>
                              ),
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        </Table.HeadCell>
                      ))}
                    </Table.Head>
                  ))}

                  <Table.Body className="divide-y">
                    {table.getRowModel().rows.map((row) => (
                      <Table.Row
                        key={row.id}
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      >
                        {row.getVisibleCells().map((cell) => (
                          <Table.Cell
                            {...{
                              key: cell.id,
                              style: {
                                width: cell.column.getSize(),
                              },
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                            <pre>
                              {JSON.stringify(
                                cell.column.columnDef.cell,
                                null,
                                2
                              )}
                            </pre>
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Body>
                  {/* <tfoot>
                  {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id}>
                      {footerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.footer,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </tfoot> */}
                </Table>
              </div>
            </div>
          </div>
        </div>
        {/* Pagination */}
        {showPagination && (
          <>
            <div className="py-3 flex items-center justify-between">
              <div className="flex-1 flex justify-between sm:hidden">
                <Button
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  Previous
                </Button>
                <Button
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  Next
                </Button>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:gap-x-2">
                  <span className="text-sm text-gray-700">
                    Page{" "}
                    <span className="font-medium">
                      {table.getState().pagination.pageIndex + 1}
                    </span>{" "}
                    of{" "}
                    <span className="font-medium">{table.getPageCount()}</span>
                  </span>
                  <label>
                    <span className="sr-only">Items Per Page</span>
                    <select
                      className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      value={table.getState().pagination.pageSize}
                      onChange={(e) => {
                        table.setPageSize(Number(e.target.value));
                      }}
                    >
                      {[5, 10, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div>
                  <nav
                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                  >
                    <PageButton
                      className="rounded-l-md"
                      onClick={() => table.setPageIndex(0)}
                      disabled={!table.getCanPreviousPage()}
                    >
                      <span className="sr-only">First</span>
                      <ChevronDoubleLeftIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </PageButton>
                    <PageButton
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </PageButton>
                    <PageButton
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </PageButton>
                    <PageButton
                      className="rounded-r-md"
                      onClick={() =>
                        table.setPageIndex(table.getPageCount() - 1)
                      }
                      disabled={!table.getCanNextPage()}
                    >
                      <span className="sr-only">Last</span>
                      <ChevronDoubleRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </PageButton>
                  </nav>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre>
      <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
    </>
  );
};

export const defaultColumn = ({
  getValue,
  row: { index },
  column: { id },
  table,
  cell,
}) => {
  const initialValue = getValue();

  console.log("defaultColumn", { initialValue, cell });
  return (
    <>
      <div className="bg-red-400 pr-6">{initialValue}</div>
    </>
  );
};

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <div className="mt-1 flex rounded-md shadow-sm">
      <div className="relative flex flex-grow items-stretch focus-within:z-10">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          name="search"
          id="search"
          className="block w-full rounded-none rounded-l-md border-gray-300 pl-10 pr-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          {...props}
        />
      </div>
      <button
        type="button"
        className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
      >
        <FunnelIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        <span>Filter</span>
      </button>
    </div>
  );
}

export function PageButton({ children, className, ...rest }) {
  return (
    <button
      type="button"
      className={clsx(
        "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
}

// export function TransactionActions({ row, selectTransaction }) {
//   // console.log(row);
//   return (
//     <>
//       <div className="flex flex-row gap-2">
//         <DocumentTextIcon
//           className={classNames(
//             "h-4 w-4 text-gray-400",
//             row.attributes.tags?.memo && "text-green-600 "
//           )}
//           aria-hidden="true"
//         />
//         <PaperClipIcon
//           className={classNames(
//             "h-4 w-4 text-gray-400",
//             row.attributes.tags?.receiptId && "text-green-600 "
//           )}
//           aria-hidden="true"
//         />
//         <button onClick={() => selectTransaction(row)}>
//           <ChevronRightIcon
//             className="h-4 w-4 text-gray-400"
//             aria-hidden="true"
//           />
//         </button>
//       </div>
//     </>
//   );
// }

export function RowActions({ row }) {
  return (
    <>
      <TextLink text={"Edit"} to={`${row.original.id}`} />
      {/* <span>{row.original.assignedColor}</span> */}
    </>
  );
}
