const prod = {
  url: {
    API_URL: "https://verp-bundle-app.herokuapp.com/v1",
  },
};
const dev = {
  url: {
    API_URL: "http://localhost:3000/v1",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
