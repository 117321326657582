import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { createColumnHelper } from "@tanstack/react-table";
import { Breadcrumb, Button, Modal } from "flowbite-react";
import { CustomTextInput, EmptyState, SimpleTable } from "../components";
import { EyeIcon, HomeIcon, TrashIcon } from "@heroicons/react/24/outline";
import { getClients } from "../features/client/clientSlice";
import classNames from "classnames";
import { PlusSmallIcon } from "@heroicons/react/20/solid";
import FormatProperCase from "../utils/FormatProperCase";
import { useNavigate } from "react-router-dom";

const statuses = {
  Completed: "text-green-400 bg-green-400/10",
  Error: "text-rose-400 bg-rose-400/10",
};

const Clients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper();

  const { clients } = useSelector((state) => state.client);

  useEffect(() => {
    dispatch(getClients());
  }, [dispatch]);

  const handleEditClick = (clientId) => {
    console.log("Edit Client");
    navigate(`/clients/${clientId}`);
  };

  const handleDeleteClick = (serviceId) => {
    console.log("Delete Client");
  };

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("company.name", {
        header: () => "Client",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
        id: "fullName",
        header: () => "Contact Name",
      }),
      columnHelper.accessor("email", {
        header: "Email",
      }),
      columnHelper.accessor(
        (row) => `${row.phoneNumber.countryCode}-${row.phoneNumber.number}`,
        {
          id: "phoneNumber",
          header: () => "Phone",
        }
      ),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (props) => (
          <>
            <div className="flex">
              <div
                className={classNames(
                  statuses["Completed"],
                  "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                )}
              >
                {/* {FormatProperCase(props.getValue())} */}
                Active
              </div>
            </div>
          </>
        ),
      }),
      columnHelper.accessor("id", {
        header: () => (
          <>
            <span className="sr-only">Actions</span>
          </>
        ),
        cell: (props) => (
          <>
            <div className="flex flex-row">
              <EyeIcon
                onClick={() => handleEditClick(`${props.getValue()}`)}
                className="h-5 w-5 text-neutral-600 hover:text-neutral-900 mr-2"
              />
              <TrashIcon
                onClick={() => handleDeleteClick(`${props.getValue()}`)}
                className="h-5 w-5 text-neutral-600 hover:text-red-600"
              />
            </div>
          </>
        ),
        maxSize: 16,
      }),
      // columnHelper.group({
      //   header: "Contact Name",
      //   columns: [
      //     columnHelper.accessor("firstName", {
      //       cell: (info) => info.getValue(),
      //     }),
      //     columnHelper.accessor("lastName", {
      //       cell: (info) => info.getValue(),
      //     }),
      //   ],
      // }),
    ],
    []
  );

  return (
    <>
      <div className="mx-auto max-w-8xl px-4 py-8 sm:px-6 lg:px-12">
        <Breadcrumbs />
        <header>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold tracking-wide leading-6 text-gray-900">
                Clients
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the users in your account including their name,
                title, email and role.
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <AddClientModal />
            </div>
          </div>
        </header>

        {/* Table */}
        {clients && clients.length > 0 ? (
          <>
            <SimpleTable
              columns={columns}
              data={clients}
              showPagination={false}
            />
          </>
        ) : (
          <>
            <EmptyState
              title={"No Clients"}
              description={
                "You have not created any cards set up for your Account yet. Click the button below to create your first card! Need to reate a reusable component for custom SVG Icons"
              }
            />
          </>
        )}
      </div>
    </>
  );
};

const AddClientModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      company: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: {
        countryCode: "1",
        number: "",
      },
    },
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="ml-auto flex items-center gap-x-1 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
      >
        <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
        Add client
      </button>
      <Modal onClose={() => setIsOpen(false)} show={isOpen}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Add new client</strong>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="col-span-2">
              <CustomTextInput
                control={control}
                type="text"
                id="company"
                name="company"
                label="Client"
                placeholderTx="Acme, Inc."
              />
            </div>
            <div>
              <CustomTextInput
                control={control}
                type="text"
                id="firstName"
                name="firstName"
                label="Contact First Name"
                placeholderTx="John"
              />
            </div>
            <div>
              <CustomTextInput
                control={control}
                type="text"
                id="lastName"
                name="lastName"
                label="Contact Last Name"
                placeholderTx="Smith"
              />
            </div>
            <div>
              <CustomTextInput
                control={control}
                type="text"
                id="email"
                name="email"
                label="Email"
                placeholderTx="john@acme.com"
              />
            </div>
            <div>
              <CustomTextInput
                control={control}
                type="text"
                id="phoneNumber"
                name="phoneNumber.number"
                label="Phone Number"
                placeholderTx="555-555-5555"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit(onSubmit)}>Add client</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Breadcrumbs = () => {
  return (
    <>
      <Breadcrumb className="mb-6">
        <Breadcrumb.Item href="/">
          <div className="flex items-center gap-x-3">
            <HomeIcon className="h-5 w-5" />
            <span className="dark:text-white">Home</span>
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Clients</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default Clients;
