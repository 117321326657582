import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { ArrowUpTrayIcon, DocumentTextIcon } from "@heroicons/react/24/outline";

export const FileUpload = () => {
  const dispatch = useDispatch();

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "http://localhost:3000/v1/openai/upload/pdf",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response);
    } catch (error) {
      console.error("Error uploading invoice:", error);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div
        className="flex w-full items-center justify-center"
        {...getRootProps()}
      >
        <label className="flex h-32 w-full cursor-pointer flex-col rounded border-2 border-blue-100 bg-blue-50 hover:bg-blue-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-700">
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <DocumentTextIcon className="h-6 w-6 text-gray-500" />
            <p className="py-1 text-sm font-medium text-gray-500 dark:text-gray-500">
              {isDragActive ? "Upload invoices" : "Drag & drop invoices"}
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              We can grab your recipien's details automatically
            </p>
          </div>
          <input type="file" className="hidden" {...getInputProps()} />
        </label>
      </div>
    </>
  );
};
