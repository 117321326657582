import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const avatarInitialClasses = {
  base: "inline-flex items-center justify-center rounded-full ring-1 ",
  size: {
    xsmall: { container: "h-6 w-6", text: "text-xs" },
    small: { container: "h-8 w-8", text: "text-sm" },
    normal: { container: "h-10 w-10", text: "text-base" },
    large: { container: "h-12 w-12", text: "text-lg" },
    xlarge: { container: "h-16 w-16", text: "text-2xl" },
    xxlarge: { container: "h-24 w-24", text: "text-4xl" },
    xxxlarge: { container: "h-32 w-32", text: "text-6xl" },
  },
  color: {
    gray: "bg-gray-500 text-white",
    red: "bg-red-500 text-white",
    yellow: "bg-yellow-500 text-neutral-900",
    green: "bg-green-500 text-white",
    blue: "bg-blue-500 text-white",
    indigo: "bg-indigo-500 text-white",
    purple: "bg-purple-500 text-white",
    orange: "bg-orange-500 text-white",
    neutral: "bg-neutral-50 text-neutral-800 ring-gray-900/10",
    black: "bg-neutral-800 text-white",
  },
};

const avatarImageClasses = {
  base: "inline-block rounded-full",
  size: {
    xsmall: "h-6 w-6",
    small: "h-8 w-8",
    normal: "h-10 w-10",
    large: "h-12 w-12",
    xlarge: "h-16 w-16",
    xxlarge: "h-24 w-24",
    xxlarge: "h-32 w-32",
  },
};

function getInitials(firstName, lastName) {
  return (firstName && firstName[0]) + (lastName && lastName[0]);
}

// function getInitials(firstName, lastName) {
//   return firstName[0] + lastName[0];
// }

export const CustomAvatar = ({
  size = "normal",
  color = "neutral",
  imageSource,
  firstName = "",
  lastName = "",
  ...props
}) => {
  // If first name and last name are available
  // const initials = getInitials(firstName, lastName);
  // If only full name is available
  // const nameParts = fullName.split(" ");
  // const firstName = nameParts[0]; // firstName = "John"
  // const lastName = nameParts[1]; // lastName

  return (
    <>
      {imageSource ? (
        <>
          <img
            className={classNames(`
              ${avatarImageClasses.base}
              ${avatarImageClasses.size[size]}
              
            `)}
            src={imageSource}
            alt=""
          />
        </>
      ) : (
        <>
          <span
            className={classNames(`
              ${avatarInitialClasses.base}
              ${avatarInitialClasses.size[size].container}
              ${avatarInitialClasses.color[color]}
            `)}
          >
            <span
              className={classNames(`
              ${avatarInitialClasses.size[size].text}
              ${avatarInitialClasses.color[color]}
              font-medium leading-none 
            `)}
            >
              {getInitials(firstName, lastName)}
            </span>
          </span>
        </>
      )}
    </>
  );
};
