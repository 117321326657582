import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import plaidService from "./plaidService";

// Get Link Token from LocalStorage
// const link_token = JSON.parse(localStorage.getItem("plaid_link_token"));

// Create Initial State for Plaid
const initialState = {
  linkToken: null,
  items: [],
  // institutions: null,
  accounts: [],
  transactions: [],
  totalIncome: 0,
  totalExpenses: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Add Plaid Account for User
export const getLinkToken = createAsyncThunk(
  "plaid/link-token",
  async (_, thunkAPI) => {
    try {
      return await plaidService.getLinkToken();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add Plaid Account for User
export const createPlaidItem = createAsyncThunk(
  "plaid/item/add",
  async (plaidItemData, thunkAPI) => {
    try {
      return await plaidService.createPlaidItem(plaidItemData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Exchange Plaid Access Token for Plaid Processor Token
export const getProcessorToken = createAsyncThunk(
  "plaid/processor-token",
  async (plaidAccount, thunkAPI) => {
    try {
      return await plaidService.getProcessorToken(plaidAccount);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Plaid Items for User
export const getPlaidItems = createAsyncThunk(
  "plaid/item/:userId",
  async (user, thunkAPI) => {
    try {
      return await plaidService.getPlaidItems(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Plaid Accounts for User
export const getPlaidItemAccounts = createAsyncThunk(
  "plaid/item/:itemId/accounts",
  async (item, thunkAPI) => {
    try {
      const accessToken = item.accessToken;
      return await plaidService.getPlaidItemAccounts(accessToken);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Plaid Accounts for User
export const getPlaidItemTransactions = createAsyncThunk(
  "plaid/item/:accessToken/transactions",
  async (item, thunkAPI) => {
    try {
      const accessToken = item.accessToken;
      return await plaidService.getPlaidItemTransactions(accessToken);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Remove Plaid Account for User
export const deletePlaidItem = createAsyncThunk(
  "plaid/item/remove",
  async (itemId, thunkAPI) => {
    try {
      return await plaidService.deletePlaidItem(itemId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create Slice Collection for Redux
export const plaidSlice = createSlice({
  name: "plaid",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Generate Link Token for User
      .addCase(getLinkToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLinkToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.linkToken = action.payload.link_token;
      })
      .addCase(getLinkToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Exchange Public Token for Access Token, Add Plaid Item
      .addCase(createPlaidItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPlaidItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items.push(action.payload);
      })
      .addCase(createPlaidItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Exchange Public Token for Access Token, Add Plaid Item
      .addCase(getProcessorToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProcessorToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.items.push(action.payload);
      })
      .addCase(getProcessorToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Get Plaid Items for User
      .addCase(getPlaidItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPlaidItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items = action.payload;
      })
      .addCase(getPlaidItems.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Get Plaid Accounts for User by Item
      .addCase(getPlaidItemAccounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPlaidItemAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.accounts = state.accounts.concat(action.payload.accounts);

        const existingAccount = state.accounts.find(
          (account) =>
            account.account_id === action.payload.accounts[0].account_id
        );

        state.accounts = existingAccount
          ? state.accounts
          : state.accounts.concat(action.payload.accounts);
      })
      .addCase(getPlaidItemAccounts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Get Plaid Transactions for User by Item
      .addCase(getPlaidItemTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPlaidItemTransactions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Check if Transaction already exists
        const existingTransaction = state.transactions.find(
          (transaction) =>
            transaction.transaction_id ===
            action.payload.transactions[0].transaction_id
        );
        // Update Transaction State for New Transactions
        state.transactions = existingTransaction
          ? state.transactions
          : state.transactions.concat(action.payload.transactions);
        // Calculate Sum of Income for Plaid Transactions, Negative Values are Income
        state.totalIncome = state.transactions
          .filter((x) => x.amount <= 0)
          .reduce(
            (total, currentValue) => (total = total + currentValue.amount),
            0
          );
        // Calculate Sum of Expenses for Plaid Transactions, Positive Values are Expenses
        state.totalExpenses = state.transactions
          .filter((x) => x.amount >= 0)
          .reduce(
            (total, currentValue) => (total = total + currentValue.amount),
            0
          );
      })
      .addCase(getPlaidItemTransactions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Generate Link Token for User
      .addCase(deletePlaidItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePlaidItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items = state.items.filter(
          (item) => item.itemId != action.payload.itemId
        );
      })
      .addCase(deletePlaidItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = plaidSlice.actions;
export default plaidSlice.reducer;
