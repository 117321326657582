import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";

import {
  CustomTable,
  CustomTextArea,
  PageHeader,
  SimpleTable,
  TransactionActions,
} from "../components";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { HomeIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { updateUnitTransaction } from "../features/unit/unitSlice";
import FormatCurrency from "../utils/FormatCurrency";
import FormatDate from "../utils/FormatDate";
import FormatIsoDate from "../utils/FormatIsoDate";
import IdToUserName from "../utils/IdToUserName";
import GetAccountName from "../utils/GetAccountName";
import { Breadcrumb } from "flowbite-react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Transactions = () => {
  return (
    <>
      <div className="mx-auto max-w-8xl px-4 py-8 sm:px-6 lg:px-12">
        <Breadcrumbs />
        <header>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold tracking-wide leading-6 text-gray-900">
                Transactions
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the users in your account including their name,
                title, email and role.
              </p>
            </div>
          </div>
        </header>
        <TransactionsList />
      </div>
    </>
  );
};

const TransactionsList = () => {
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    // defaultValues: {
    //   memo: "",
    // },
  });

  const { accounts, transactions } = useSelector((state) => state.unit);

  const getAccount = (accountId) => {
    const account = accounts.find((account) => account.id === accountId);

    const accountType =
      account.type === "depositAccount" ? "Checking" : "Credit";

    const accountNumber = account.attributes.accountNumber.slice(-4);

    return `${accountType}•${accountNumber}`;
  };

  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const filteredTransactions = transactions.filter((transaction) => {
    return (
      transaction.type === "purchaseTransaction" ||
      transaction.type === "cardTransaction"
    );
  });

  const getMerchant = (row) => {
    switch (row.type) {
      case "purchaseTransaction":
        return row.attributes.merchant.name;
      case "cardTransaction":
        return row.attributes.merchant.name;
      case "receivedAchTransaction":
        return row.attributes.companyName;
      case "originatedAchTransaction":
        return row.attributes.counterparty.name;
      case "bookTransaction":
        return row.attributes.counterparty.name;
      default:
        return "Unknown";
    }
  };

  const selectTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setValue(
      "memo",
      transaction.attributes.tags?.memo ? transaction.attributes.tags.memo : ""
    );
  };

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("attributes.createdAt", {
        header: "Date",
        cell: (props) => (
          <span>
            {FormatDate(props.getValue(), {
              month: "short",
              day: "numeric",
            })}
          </span>
        ),
        maxSize: 36,
      }),
      columnHelper.accessor((row) => `${getMerchant(row)}`, {
        id: "attributes.merchant.name",
        header: () => "Merchant",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => `${row.attributes.tags?.userId || null}`, {
        id: "attributes.tags",
        header: () => "User",
        cell: (props) => <span>{IdToUserName(props.getValue())}</span>,
      }),
      columnHelper.accessor(
        (row) => `${row.attributes.merchant?.category || " "}`,
        {
          id: "attributes.merchant.category",
          header: () => "Category",
          cell: (props) => (
            <>
              <span>{props.getValue()}</span>
            </>
          ),
        }
      ),
      columnHelper.accessor("attributes.amount", {
        header: "Date",
        cell: (props) => <span>{FormatCurrency(props.getValue() / 100)}</span>,
        maxSize: 72,
      }),
      columnHelper.display({
        id: "actions",
        cell: (props) => (
          <>
            <TransactionActions
              row={props.row.original}
              selectTransaction={selectTransaction}
            />
          </>
        ),
        maxSize: 16,
      }),
    ],
    []
  );

  const onSubmit = (data) => {
    const requestBody = {
      transactionId: selectedTransaction.id,
      accountId: selectedTransaction.relationships.account.data.id,
      tags: { memo: data.memo.trim() == "" ? null : data?.memo },
    };

    console.log("requestBody", requestBody);
    dispatch(updateUnitTransaction(requestBody)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        console.log(res);
      }
    });
  };

  // useEffect(() => {
  //   setSelectedTransaction(transactions[0]);
  //   setValue(
  //     "memo",
  //     transactions[0].attributes.tags?.memo
  //       ? transactions[0].attributes.tags.memo
  //       : ""
  //   );
  // }, []);

  return (
    <>
      {/* Transactions List */}
      <div className="grid grid-cols-1 gap-6 lg:grid-flow-col lg:grid-cols-8">
        <div className="lg:col-span-full lg:col-start-1">
          <SimpleTable columns={columns} data={transactions} />
        </div>
        {selectedTransaction && (
          <>
            <div className="mt-20 max-w-xs">
              <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                {/* Transaction Summary */}
                <div className="px-4 py-5 sm:px-6">
                  <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                    <div className="ml-4 mt-2">
                      <h3 className="text-base font-medium leading-6 text-gray-900">
                        Transaction Name
                      </h3>
                      <p className="text-sm text-gray-700">
                        {FormatCurrency(
                          selectedTransaction.attributes.amount / 100
                        )}
                      </p>
                    </div>
                    <div className="ml-4 mt-2 flex flex-row flex-shrink-0">
                      <XMarkIcon
                        // onClick={() => {
                        //   close();
                        //   setSelectedVendor(null);
                        // }}
                        className="h-5 w-5 text-neutral-600 hover:text-neutral-900"
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-5 sm:p-6 space-y-8 sm:space-y-6">
                  {/* Receipt */}
                  <div className="flex flex-col flex-1">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Add receipt
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-1">
                      <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 p-2">
                        <div className="flex flex-row">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <div className="ml-1 flex flex-col self-center">
                            <div className="flex text-sm text-gray-600">
                              <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md bg-white font-medium text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500"
                              >
                                <span>Click</span>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  className="sr-only"
                                />
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">
                              PDF, PNG, or JPG
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className="mt-2 text-xs text-gray-500">
                        Send receipts to receipts@hellobundle.com and we'll
                        match them automatically or
                      </p>
                    </div>
                  </div>
                  {/* Memo */}
                  <div>
                    <CustomTextArea
                      control={control}
                      id="memo"
                      name="memo"
                      label="Memo"
                      rows={2}
                      onBlur={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
                <div className="px-4 py-5 sm:p-6 space-y-8 sm:space-y-6">
                  {/* Categorization */}
                  <div>
                    <h3 className="text-base font-medium leading-6 text-gray-900 mb-2">
                      Categorization
                    </h3>
                    <dl className="space-y-4 sm:space-y-2">
                      <div>
                        <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                          Category
                        </dt>
                        <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                          <p>
                            {selectedTransaction.attributes.merchant
                              ?.category || "Unknown"}
                          </p>
                        </dd>
                      </div>
                    </dl>
                  </div>
                  {/* Details */}
                  <div>
                    <h3 className="text-base font-medium leading-6 text-gray-900 mb-2">
                      Details
                    </h3>
                    <dl className="space-y-6 sm:space-y-4">
                      <div>
                        <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                          Date
                        </dt>
                        <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                          <p>
                            {FormatIsoDate(
                              selectedTransaction.attributes.createdAt
                            )}
                          </p>
                        </dd>
                      </div>
                      <div>
                        <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                          Amount
                        </dt>
                        <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                          <p>
                            {FormatCurrency(
                              selectedTransaction.attributes.amount / 100
                            )}
                          </p>
                        </dd>
                      </div>
                      {selectedTransaction.attributes.tags?.userId && (
                        <>
                          <div>
                            <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                              User
                            </dt>
                            <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                              <p>
                                {IdToUserName(
                                  selectedTransaction.attributes.tags.userId
                                )}
                              </p>
                            </dd>
                          </div>
                        </>
                      )}
                      {selectedTransaction.type ===
                        "originatedAchTransaction" && (
                        <>
                          {selectedTransaction.relationships?.account.data && (
                            <>
                              <div>
                                <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                                  Account
                                </dt>
                                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                                  <p>
                                    {GetAccountName(
                                      accounts,
                                      selectedTransaction.relationships.account
                                        .data.id
                                    )}
                                  </p>
                                </dd>
                              </div>
                            </>
                          )}
                          {selectedTransaction.attributes.addenda && (
                            <>
                              <div>
                                <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                                  Description
                                </dt>
                                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                                  <p>
                                    {selectedTransaction.attributes.addenda}
                                  </p>
                                </dd>
                              </div>
                            </>
                          )}
                          {selectedTransaction.attributes.counterparty && (
                            <>
                              <div>
                                <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                                  Counterparty
                                </dt>
                                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                                  <p>
                                    {
                                      selectedTransaction.attributes
                                        .counterparty.name
                                    }
                                  </p>
                                </dd>
                              </div>
                            </>
                          )}
                          {selectedTransaction.attributes.counterparty && (
                            <>
                              <div>
                                <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                                  Counterparty account
                                </dt>
                                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                                  <p>
                                    Account Ending x
                                    {selectedTransaction.attributes.counterparty.accountNumber.slice(
                                      -4
                                    )}
                                  </p>
                                </dd>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {selectedTransaction.type ===
                        "receivedAchTransaction" && (
                        <>
                          {selectedTransaction.relationships?.account.data && (
                            <>
                              <div>
                                <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                                  Account
                                </dt>
                                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                                  <p>
                                    {getAccount(
                                      selectedTransaction.relationships.account
                                        .data.id
                                    )}
                                  </p>
                                </dd>
                              </div>
                            </>
                          )}
                          {selectedTransaction.attributes.description && (
                            <>
                              <div>
                                <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                                  Description
                                </dt>
                                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                                  <p>
                                    {selectedTransaction.attributes.description}
                                  </p>
                                </dd>
                              </div>
                            </>
                          )}
                          {selectedTransaction.attributes.companyName && (
                            <>
                              <div>
                                <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                                  Company
                                </dt>
                                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                                  <p>
                                    {selectedTransaction.attributes.companyName}
                                  </p>
                                </dd>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {(selectedTransaction.type === "purchaseTransaction" ||
                        "cardTransaction") && (
                        <>
                          {selectedTransaction.relationships?.account.data && (
                            <>
                              <div>
                                <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                                  Account
                                </dt>
                                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                                  <p>
                                    {getAccount(
                                      selectedTransaction.relationships.account
                                        .data.id
                                    )}
                                  </p>
                                </dd>
                              </div>
                            </>
                          )}
                          {selectedTransaction.attributes?.cardLast4Digits && (
                            <>
                              <div>
                                <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                                  Card number
                                </dt>
                                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                                  <p>
                                    {
                                      selectedTransaction.attributes
                                        .cardLast4Digits
                                    }
                                  </p>
                                </dd>
                              </div>
                            </>
                          )}
                          {selectedTransaction.attributes.merchant?.name && (
                            <>
                              <div>
                                <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                                  Merchant
                                </dt>
                                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                                  <p>
                                    {
                                      selectedTransaction.attributes?.merchant
                                        .name
                                    }
                                  </p>
                                </dd>
                              </div>
                            </>
                          )}
                          {selectedTransaction.attributes.merchant
                            ?.location && (
                            <>
                              <div>
                                <dt className="text-sm text-gray-500 sm:w-40 sm:flex-shrink-0">
                                  Merchant Address
                                </dt>
                                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2">
                                  <p>
                                    {
                                      selectedTransaction.attributes?.merchant
                                        .location
                                    }
                                  </p>
                                </dd>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const stats = [
  {
    name: "Total Deposits",
    stat: "$71,897",
    previousStat: "$70,946",
    change: "12%",
    changeType: "increase",
  },
  {
    name: "Total Transactions",
    stat: "$48,228",
    previousStat: "$49,384",
    change: "2.02%",
    changeType: "increase",
  },
  {
    name: "Avg. Expenses",
    stat: "$43,190",
    previousStat: "$46,659",
    change: "4.05%",
    changeType: "decrease",
  },
];

const Stats = () => {
  return (
    <>
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Last 30 days
        </h3>
        <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
          {stats.map((item) => (
            <div key={item.name} className="px-4 py-5 sm:p-6">
              <dt className="text-base font-normal text-gray-900">
                {item.name}
              </dt>
              <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                  {item.stat}
                  <span className="ml-2 text-sm font-medium text-gray-500">
                    from {item.previousStat}
                  </span>
                </div>

                <div
                  className={classNames(
                    item.changeType === "increase"
                      ? "bg-green-100 text-green-800"
                      : "bg-red-100 text-red-800",
                    "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
                  )}
                >
                  {item.changeType === "increase" ? (
                    <ArrowUpIcon
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowDownIcon
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                      aria-hidden="true"
                    />
                  )}

                  <span className="sr-only">
                    {" "}
                    {item.changeType === "increase"
                      ? "Increased"
                      : "Decreased"}{" "}
                    by{" "}
                  </span>
                  {item.change}
                </div>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </>
  );
};

const Breadcrumbs = () => {
  return (
    <>
      <Breadcrumb className="mb-6">
        <Breadcrumb.Item href="/">
          <div className="flex items-center gap-x-3">
            <HomeIcon className="h-5 w-5" />
            <span className="dark:text-white">Home</span>
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Transactions</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default Transactions;
