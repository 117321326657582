import { configureStore, combineReducers } from "@reduxjs/toolkit";
// Imports for Redux Persistance
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

// Import Reducers
import authReducer from "../features/auth/authSlice";
import organizationReducer from "../features/organization/organizationSlice";
import clientReducer from "../features/client/clientSlice";
import projectRecucer from "../features/project/projectSlice";
import taskReducer from "../features/task/taskSlice";
import invoiceReducer from "../features/invoice/invoiceSlice";
import invoiceItemReducer from "../features/invoiceItem/invoiceItemSlice";
import unitReducer from "../features/unit/unitSlice";
import serviceReducer from "../features/service/serviceSlice";
import vendorReducer from "../features/vendor/vendorSlice";
import fileReducer from "../features/file/fileSlice";
import plaidReducer from "../features/plaid/plaidSlice";

// Create Root Reducer
const rootReducer = combineReducers({
  auth: authReducer,
  organization: organizationReducer,
  client: clientReducer,
  project: projectRecucer,
  task: taskReducer,
  invoice: invoiceReducer,
  invoiceItem: invoiceItemReducer,
  unit: unitReducer,
  service: serviceReducer,
  vendor: vendorReducer,
  file: fileReducer,
  plaid: plaidReducer,
});

// Redux Persistance Config
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["auth"],
};

// Create Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
