// Slices for Initial State, Actions/Functions
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import organizationService from "./organizationService";

// Create Initial State for Organization
const initialState = {
  organization: {},
  beneficialOwners: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getOrganizations = createAsyncThunk(
  "organizations/get",
  async (_, thunkAPI) => {
    try {
      return await organizationService.getOrganizations();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrganization = createAsyncThunk(
  "organizations/{id}/get",
  async (organizationId, thunkAPI) => {
    try {
      return await organizationService.getOrganization(organizationId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createOrganization = createAsyncThunk(
  "organizations/create",
  async (organization, thunkAPI) => {
    try {
      return await organizationService.createOrganization(organization);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateOrganization = createAsyncThunk(
  "organizations/update",
  async (organization, thunkAPI) => {
    try {
      return await organizationService.updateOrganization(organization);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteOrganization = createAsyncThunk(
  "organizations/delete",
  async (id, thunkAPI) => {
    try {
      return await organizationService.deleteOrganization(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createBeneficialOwner = createAsyncThunk(
  "organizations/beneficialOwner/create",
  async (beneficialOwner, thunkAPI) => {
    try {
      return await organizationService.createBeneficialOwner(beneficialOwner);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Slice is collection of Reducers for Redux
export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    // Ability to dispatch Reset function after Register or Login
    reset: (state) => {
      state.organization = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Organizations
      .addCase(getOrganizations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrganizations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload.results;
      })
      .addCase(getOrganizations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Get Organizations
      .addCase(getOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(getOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Create Organization
      .addCase(createOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.organization.push(action.payload);
        state.organization = action.payload.organization;
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Update Organization
      .addCase(updateOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(updateOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Delete Organization
      .addCase(deleteOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = {};
      })
      .addCase(deleteOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Create Beneficial Owner
      .addCase(createBeneficialOwner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createBeneficialOwner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload.organization;
        state.beneficialOwners = action.payload.beneficialOwners;
      })
      .addCase(createBeneficialOwner.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = organizationSlice.actions;
export default organizationSlice.reducer;
