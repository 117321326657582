import api from "..";

// Get All Projects
const getProjects = async () => {
  const response = await api.get("/projects", {
    params: {
      populate: "client,tasks",
    },
  });
  return response.data;
};

// Create Project
const createProject = async (project) => {
  const response = await api.post("/projects", project);
  return response.data;
};

// Update Project
const updateProject = async (project) => {
  console.log("projectProject.updateProject", project);
  const response = await api.patch(`/projects/${project.id}`, project);
  return response.data;
};

// Delete Project
const deleteProject = async (id) => {
  const response = await api.delete(`/projects/${id}`);
  return response.data;
};

const projectService = {
  getProjects,
  createProject,
  updateProject,
  deleteProject,
};

export default projectService;
