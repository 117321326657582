import React, { useState, Fragment, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const CustomListbox = ({
  label,
  items,
  attribute,
  setValue,
  name,
  initialValue,
  placeholderText = "Select an option",
}) => {
  const [selected, setSelected] = useState(items[0]);

  useEffect(() => {
    if (!initialValue) {
      setSelected(items[0]);
      setValue(name, items[0]);
    } else {
      const item = items.filter((item) => item.id === initialValue.id)[0];

      setSelected(item);
      setValue(name, item);
    }
  }, []);

  const onSelect = (item) => {
    setSelected(item);
    setValue(name, item);
  };

  return (
    <>
      <Listbox as="div" value={selected} onChange={(value) => onSelect(value)}>
        {({ open }) => (
          <>
            <Listbox.Label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {label}
            </Listbox.Label>
            <div className="relative mt-1">
              <Listbox.Button className="relative text-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <span className="block truncate">
                  {selected ? selected[attribute] : "Placeholder Text"}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-50 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm min-w-fit">
                  {items.map((item) => (
                    <Listbox.Option
                      key={item.id}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-blue-600" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {item[attribute]}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-blue-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
};

// Usage
// const options = [
//   "flat",
//   "perHour",
//   "perDay",
//   "perItem",
//   "perWeek",
//   "perMonth",
// ];
// const displayValues = [
//   "Flat",
//   "Per Hour",
//   "Per Day",
//   "Per Item",
//   "Per Week",
//   "Per Month",
// ];
// const valueMap = options.reduce((map, option, index) => {
//   map[option] = displayValues[index];
//   return map;
// }, {});
