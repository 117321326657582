import api from "..";

// Get All Organizations
const getOrganizations = async () => {
  const response = await api.get("/organizations", {
    params: {
      populate: "client,tasks",
    },
  });
  return response.data;
};

// Get Organization by ID
const getOrganization = async (id) => {
  const response = await api.get(`/organizations/${id}`);
  return response.data;
};

// Create Organization
const createOrganization = async (organization) => {
  const response = await api.post("/organizations", organization);
  return response.data;
};

// Update Organization
const updateOrganization = async (organization) => {
  const response = await api.patch(
    `/organizations/${organization.id}`,
    organization
  );
  return response.data;
};

// Delete Organization
const deleteOrganization = async (id) => {
  const response = await api.delete(`/organizations/${id}`);
  return response.data;
};

// Create Organization
const createBeneficialOwner = async (beneficialOwner) => {
  const response = await api.post(
    `/organizations/${beneficialOwner.organizationId}/beneficialOwners`,
    beneficialOwner
  );
  return response.data;
};

const organizationService = {
  getOrganizations,
  getOrganization,
  createOrganization,
  updateOrganization,
  deleteOrganization,
  createBeneficialOwner,
};

export default organizationService;
