import React, { useEffect, useState } from "react";
import { useFirestore } from "../../hooks/useFirestore";
import { TrashIcon } from "@heroicons/react/24/outline";
import { firestore, storage } from "../../config/firebase";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  listAll,
} from "firebase/storage";
import { onSnapshot, collection } from "firebase/firestore";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const ImageGrid = ({ directory }) => {
  // const { docs, getFirestore } = useFirestore();

  // useEffect(() => {
  //   getFirestore("images");
  //   console.log("Getting images");
  // }, [getFirestore]);

  const [docs, setDocs] = useState([]);
  const [files, setFiles] = useState([]);

  const filesRef = ref(storage, `${directory}/`);

  const getImages = async (directory) => {
    const collectionRef = collection(firestore, directory);

    onSnapshot(collectionRef, (snapshot) => {
      let documents = [];
      snapshot.forEach((doc) => {
        documents.push({ ...doc.data(), id: doc.id });
      });
      setDocs(documents);
    });
  };

  useEffect(() => {
    console.log("Getting images");
    listAll(filesRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setFiles((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  useEffect(() => {
    setFiles([]);
    return;
  }, []);

  return (
    <>
      {/* Gallery */}
      <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
        <h2 id="gallery-heading" className="sr-only">
          Recently viewed
        </h2>
        {files && (
          <>
            <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              {files.map((url, index) => (
                <li key={index} className="relative">
                  <div
                    className={classNames(
                      index.current
                        ? "ring-2 ring-blue-500 ring-offset-2"
                        : "focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100",
                      "aspect-w-10 aspect-h-7 group block w-full overflow-hidden rounded-lg bg-gray-100"
                    )}
                  >
                    <img
                      src={url}
                      alt=""
                      className={classNames(
                        index.current ? "" : "group-hover:opacity-75",
                        "pointer-events-none object-cover"
                      )}
                    />
                    <button
                      type="button"
                      className="absolute inset-0 focus:outline-none"
                    >
                      {/* <span className="sr-only">View details for {file.name}</span> */}
                    </button>
                  </div>
                  <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                    {/* {file.name} */}
                  </p>
                  <p className="pointer-events-none block text-sm font-medium text-gray-500">
                    {/* {file.size} */}
                  </p>
                </li>
              ))}
            </ul>
          </>
        )}
      </section>
    </>
  );
};
