import api from "..";

// Get All InvoiceItems
const getInvoiceItems = async () => {
  const accountId = "acct_1MhQhvFLPxDRgaVU";
  const response = await api.get(`stripe/invoiceItems/${accountId}`);
  return response.data;
};

// Create InvoiceItem
const createInvoiceItem = async (invoiceItem) => {
  const accountId = "acct_1MhQhvFLPxDRgaVU";
  const response = await api.post(
    `stripe/invoiceItems/${accountId}`,
    invoiceItem
  );
  console.log(response.data);
  return response.data;
};

// Update InvoiceItem
const updateInvoiceItem = async (invoiceItem) => {
  const accountId = "acct_1MhQhvFLPxDRgaVU";
  const response = await api.patch(
    `stripe/invoiceItems/${accountId}/${invoiceItem.id}`,
    invoiceItem
  );
  return response.data;
};

// Delete InvoiceItem
const deleteInvoiceItem = async (invoiceItemId) => {
  const accountId = "acct_1MhQhvFLPxDRgaVU";
  const response = await api.delete(
    `stripe/invoiceItems/${accountId}/${invoiceItemId}`
  );
  return response.data;
};

const invoiceItemService = {
  getInvoiceItems,
  createInvoiceItem,
  updateInvoiceItem,
  deleteInvoiceItem,
};

export default invoiceItemService;
