import React, { useRef, useState, useEffect } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const classes = {
  base: "bg-transparent border-0 hover:bg-white hover:cursor-pointer",
  size: {
    small: "px-2 py-1 text-xs text-neutral-900",
    medium: "px-3 py-2 text-sm font-normal text-neutral-900",
    large: "px-4 py-2 text-base font-medium text-neutral-900",
    xlarge: "px-4 py-2 text-xl font-semibold text-neutral-900",
    xxlarge: "px-4 py-2 text-2xl font-semibold text-neutral-900",
  },
};

export const InlineTextInput = ({
  size = "md",
  name = "",
  value,
  setValue,
  control,
}) => {
  const [editingValue, setEditingValue] = useState(value);

  const inputRef = useRef();

  const onChange = (e) => {
    setEditingValue(e.target.value);
  };

  // Allow the user to press Enter or Escape to save the value
  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.target.blur();
    }
  };

  const onBlur = (event) => {
    // If the value has changed, update the value
    // Otherwise, reset the editing value
    if (event.target.value.trim() === "") {
      setValue(name, value);
    } else {
      // setValue(event.target.value);
      setValue(name, event.target.value);
    }
  };

  return (
    <>
      <input
        type="text"
        aria-label="Field name"
        value={editingValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        className={classNames(`${classes.base} ${classes.size[size]}`)}
        // ref={inputRef}
      />
    </>
  );
};

export default InlineTextInput;
