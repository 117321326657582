import { useSelector } from "react-redux";

const IdToUserName = (userId) => {
  const organization = useSelector((state) => state.organization.organization);

  const user = organization.platformUsers.find((user) => user.id === userId);

  if (user) {
    return user.name;
  } else {
    return "";
  }
};

export default IdToUserName;
