import React, { useState, Fragment, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import GetAccountName from "../../utils/GetAccountName";
import bundleIcon from "../../assets/images/bundle-circle-icon.png";
import bundleIconAlt from "../../assets/images/bundle-circle-icon-alt.png";
import { BuildingLibraryIcon, PlusIcon } from "@heroicons/react/24/outline";
import FormatCurrency from "../../utils/FormatCurrency";
import { usePlaid } from "../../hooks/usePlaid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const SelectAccount = ({ label, name, setValue, ...rest }) => {
  const [selected, setSelected] = useState(null);
  const [accountName, setAccountName] = useState("Select an account");

  const { open, ready, error } = usePlaid();

  const { accounts, counterparties } = useSelector((state) => state.unit);

  const depositAccounts = accounts.filter(
    (account) => account.type === "depositAccount"
  );

  useEffect(() => {
    if (selected) {
      setAccountName(GetAccountName(accounts, selected.id));
    } else {
      setAccountName("Select an account");
    }
  }, [selected]);

  const handleSelect = (account) => {
    if (account === "plaid") {
      setSelected(null);
      open();
      console.log("Plaid Selected");
    } else {
      setSelected(account);
      setValue(name, account);
    }
  };

  const handlePlaidLink = () => {
    setSelected(null);
    open();
  };

  return (
    <>
      <Listbox
        as="div"
        value={selected}
        onChange={(value) => handleSelect(value)}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block mb-2 text-sm font-medium tracking-wide text-gray-900 dark:text-white">
              {label}:
            </Listbox.Label>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-3 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-base sm:leading-6 h-16">
                {selected ? (
                  <>
                    <span className="flex items-center">
                      <img
                        src={
                          selected.attributes.tags?.purpose === "checking"
                            ? bundleIcon
                            : bundleIconAlt
                        }
                        alt=""
                        className="h-9 w-9 flex-shrink-0 rounded-full"
                      />
                      <div className="min-w-0 flex-1">
                        <div className="ml-3 block truncate">
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          <p className="text-base">
                            {GetAccountName(accounts, selected.id)}
                          </p>
                          <p className="truncate text-sm text-gray-500">
                            {FormatCurrency(selected.attributes.balance / 100)}
                          </p>
                        </div>
                      </div>
                    </span>
                  </>
                ) : (
                  <>
                    <span className="ml-1 block truncate text-gray-400 text-base">
                      Select an account
                    </span>
                  </>
                )}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-50 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm min-w-fit">
                  {depositAccounts.map((account) => (
                    <Listbox.Option
                      key={account.id}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "text-gray-900 bg-blue-100"
                            : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={account}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <img
                              src={
                                account.attributes.tags?.purpose === "checking"
                                  ? bundleIcon
                                  : bundleIconAlt
                              }
                              alt=""
                              className="h-9 w-9 flex-shrink-0 rounded-full shadow-sm"
                            />
                            <div className="min-w-0 flex-1">
                              <div
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "ml-3 block truncate"
                                )}
                              >
                                <span
                                  className="absolute inset-0"
                                  aria-hidden="true"
                                />
                                <p className="text-base">
                                  {GetAccountName(accounts, account.id)}
                                </p>
                                <p className="truncate  text-gray-500">
                                  {FormatCurrency(
                                    account.attributes.balance / 100
                                  )}
                                </p>
                              </div>
                            </div>

                            {/* <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {GetAccountName(accounts, account.id)}
                            </span> */}
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-blue-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                  {counterparties.map((counterparty) => (
                    <Listbox.Option
                      key={counterparty.id}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "text-gray-900 bg-blue-100"
                            : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={counterparty}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <div className="rounded-full p-2 bg-white text-blue-800">
                              <BuildingLibraryIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="min-w-0 flex-1">
                              <div
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "ml-3 block truncate"
                                )}
                              >
                                <span
                                  className="absolute inset-0"
                                  aria-hidden="true"
                                />
                                <p className="text-base">
                                  {`${
                                    counterparty.attributes.bank
                                  }•${counterparty.attributes.accountNumber.slice(
                                    -4
                                  )}`}
                                </p>
                                <p className="truncate  text-gray-500">
                                  {counterparty.attributes.accountType}
                                </p>
                              </div>
                            </div>

                            {/* <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {GetAccountName(accounts, account.id)}
                            </span> */}
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-blue-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                  <Listbox.Option
                    className={({ active }) =>
                      classNames(
                        active ? "text-gray-800 bg-blue-100" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={"plaid"}
                    onSelect={handlePlaidLink}
                    disabled={!ready}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <div className="rounded-full p-2 bg-blue-50 text-neutral-900">
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                          </div>
                          <span
                            className={
                              "font-normal ml-3 block truncate text-base"
                            }
                          >
                            Link a Bank account
                          </span>
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
};
