import api from "..";

// Customer & Accounts
const getUnitCustomer = async (customerId) => {
  const response = await api.get(`/unit/customers/${customerId}`);
  return response.data.customer;
};

const getUnitAccounts = async (customerId) => {
  const response = await api.get(`/unit/accounts`, {
    params: {
      "filter[customerId]": customerId,
    },
  });
  return response.data;
};

const getUnitCards = async (customerId) => {
  const response = await api.get(`/unit/cards`, {
    params: {
      "filter[customerId]": customerId,
    },
  });
  return response.data;
};

const getUnitTransactions = async (query) => {
  const response = await api.get(`/unit/transactions`, {
    params: query,
  });

  return response.data;
};

const getUnitPayments = async (query) => {
  const response = await api.get(`/unit/payments`, {
    params: query,
  });

  return response.data;
};

const updateUnitTransaction = async (request) => {
  const response = await api.patch(
    `/unit/transactions/${request.transactionId}`,
    request
  );

  return response.data;
};

const getUnitCounterparties = async (query) => {
  const response = await api.get(`/unit/counterparties`, {
    params: query,
  });

  return response.data;
};

const getUnitBalanceHistory = async (customerId, balanceRequest) => {
  const response = await api.get(`/unit/accounts/balance-history`, {
    params: {
      "filter[customerId]": customerId,
      "filter[since]": "2023-03-20",
      "filter[until]": "2023-04-23",
      "page[limit]": 31,
    },
  });
  return response.data;
};

const createUnitCard = async (request) => {
  const response = await api.post("/unit/cards", request);
  return response.data;
};

const createUnitCounterparty = async (request) => {
  const response = await api.post("/unit/counterparties", request);
  return response.data;
};

const getUnitStatements = async (customerId) => {
  const response = await api.get(`/unit/statements`, {
    params: {
      "filter[customerId]": customerId,
    },
  });
  return response.data;
};

const getUnitPdfStatement = async (statementId) => {
  const response = await api.get(`/unit/statements/${statementId}/pdf`, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf",
    },
  });
  return response.data;
};

const getUnitHtmlStatement = async (statementId) => {
  const response = await api.get(`/unit/statements/${statementId}/html`);
  return response.data;
};

const createBookPayment = async (request) => {
  const response = await api.post("/unit/payments/book-payment", request);

  return response.data;
};

const unitService = {
  getUnitCustomer,
  getUnitAccounts,
  getUnitCards,
  getUnitTransactions,
  getUnitPayments,
  createBookPayment,
  updateUnitTransaction,
  getUnitBalanceHistory,
  createUnitCard,
  createUnitCounterparty,
  getUnitCounterparties,
  getUnitStatements,
  getUnitPdfStatement,
  getUnitHtmlStatement,
};

export default unitService;
