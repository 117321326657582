// Slices for Initial State, Actions/Functions
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import invoiceItemService from "./invoiceItemService";
import { toast } from "react-toastify";

// Create Initial State for InvoiceItem
const initialState = {
  invoiceItems: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getInvoiceItems = createAsyncThunk(
  "invoiceItems/get",
  async (_, thunkAPI) => {
    try {
      return await invoiceItemService.getInvoiceItems();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createInvoiceItem = createAsyncThunk(
  "invoiceItems/create",
  async (invoiceItem, thunkAPI) => {
    try {
      return await invoiceItemService.createInvoiceItem(invoiceItem);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateInvoiceItem = createAsyncThunk(
  "invoiceItems/update",
  async (invoiceItem, thunkAPI) => {
    try {
      return await invoiceItemService.updateInvoiceItem(invoiceItem);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteInvoiceItem = createAsyncThunk(
  "invoiceItems/delete",
  async (id, thunkAPI) => {
    try {
      return await invoiceItemService.deleteInvoiceItem(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Slice is collection of Reducers for Redux
export const invoiceItemSlice = createSlice({
  name: "invoiceItem",
  initialState,
  reducers: {
    // Ability to dispatch Reset function after Register or Login
    reset: (state) => {
      state.invoiceItems = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Get InvoiceItems
      .addCase(getInvoiceItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInvoiceItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoiceItems = action.payload.data;
      })
      .addCase(getInvoiceItems.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Create InvoiceItem
      .addCase(createInvoiceItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createInvoiceItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoiceItems.push(action.payload);
      })
      .addCase(createInvoiceItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Update InvoiceItem
      .addCase(updateInvoiceItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateInvoiceItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoiceItems[
          state.invoiceItems.findIndex(
            (invoiceItem) => invoiceItem.id === action.payload.id
          )
        ] = action.payload;
        toast.success("InvoiceItem updated successfully", {
          position: "bottom-right",
        });
      })
      .addCase(updateInvoiceItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Delete InvoiceItem
      .addCase(deleteInvoiceItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInvoiceItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoiceItems = state.invoiceItems.filter(
          (invoiceItem) => invoiceItem._id !== action.payload.id
        );
      })
      .addCase(deleteInvoiceItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = invoiceItemSlice.actions;
export default invoiceItemSlice.reducer;
