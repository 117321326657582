import React, { useState, useEffect, forwardRef } from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import Datepicker from "tailwind-datepicker-react";
import { Label, TextInput } from "flowbite-react";

export const CustomDatepicker = ({ name, control, label, defaultValue }) => {
  const [startDate, setStartDate] = useState(defaultValue || null);

  return (
    <>
      <div className="mb-1">
        <Label>{label}</Label>
      </div>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <DatepickerInput
            startDate={startDate}
            onChange={(e) => {
              setStartDate(e);
              field.onChange(e);
            }}
          />
        )}
      />
    </>
  );
};

const DatepickerInput = ({ startDate, onChange }) => {
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <>
      <TextInput
        value={value}
        onClick={onClick}
        onChange={onChange}
        icon={CalendarIcon}
        placeholder="Select Date"
        ref={ref}
      />
    </>
  ));

  return (
    <>
      <DatePicker
        selected={startDate}
        // onChange={(date) => setStartDate(date)}
        // selected={selected}
        onChange={onChange}
        placeholderText="Select Date"
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        customInput={<CustomInput />}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex items-center text-center text-gray-900 justify-between px-2 py-2">
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto font-semibold">
              {format(date, "MMMM yyyy")}
            </div>
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        )}
      />
    </>
  );
};
