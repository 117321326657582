const GetDirtyValues = (dirtyFields, allValues) => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      GetDirtyValues(dirtyFields[key], allValues[key]),
    ])
  );
};

export default GetDirtyValues;
