import React, { useState, createContext, useContext, useMemo } from "react";
import { storage, firestore } from "../config/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { collection, serverTimestamp, addDoc } from "firebase/firestore";
import { v4 } from "uuid";

const StorageContext = createContext();

export const StorageProvider = ({ children }) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  const handleImageUpload = (directory, image) => {
    const uploadTask = storage
      .ref(`images/${directory}/${image.name}`)
      .put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error) => {
        setError(error);
      },
      async () => {
        try {
          const downloadUrl = await storage
            .ref(`images/${directory}`)
            .child(image.name)
            .getDownloadURL();

          setUrl(downloadUrl);
        } catch (error) {
          setError(error);
        }
      }
    );
  };

  const fetchImages = async (directory) => {
    const imagesRef = storage.ref(`images/${directory}`);

    try {
      const imageList = await imagesRef.listAll();
      const urlPromises = imageList.items.map((imageRef) =>
        imageRef.getDownloadURL()
      );
      const urls = await Promise.all(urlPromises);

      return urls;
    } catch (error) {
      setError(error);
      return [];
    }
  };

  return (
    <StorageContext.Provider
      value={{ handleImageUpload, fetchImages, progress, url, error }}
    >
      {children}
    </StorageContext.Provider>
  );
};

export const useStorage = () => {
  return useContext(StorageContext);
};

const FirebaseStorageContext = createContext();

export const FirebaseStorageProvider = ({ children }) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  const uploadFile = (file) => {
    // references
    const fileRef = ref(storage, `${file.name + v4()}`);
    const collectionRef = collection(firestore, "images");
    // Fixed with https://stackoverflow.com/questions/70622848/firestore-collection-is-not-a-function

    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        let percentage = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(percentage);
      },
      (error) => {
        setError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then(async (downloadUrl) => {
            setUrl(downloadUrl);
            const createdAt = serverTimestamp();
            await addDoc(collectionRef, {
              url: downloadUrl,
              created_at: createdAt,
            }).then(() => {
              setProgress(0);
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );

    return;
  };

  const value = useMemo(
    () => ({
      progress,
      url,
      error,
      uploadFile,
    }),
    [progress, url, error]
  );

  return (
    <>
      <FirebaseStorageContext.Provider value={value}>
        {children}
      </FirebaseStorageContext.Provider>
    </>
  );
};

// export const useStorage = () => {
//   return useContext(FirebaseStorageContext);
// };
